import { FC } from "react";
import {Card, Stack, Typography, useTheme} from "@mui/material";
import { alpha } from "@mui/system";

interface GeneralInfoCardProps {
    title: string
    value?: string
    suffix?: string
}

export const GeneralInfoCard: FC<GeneralInfoCardProps> = ({title, value, suffix}) => {
    const theme = useTheme();

    return <Card>
        <Stack padding={'15px'}>
            <Typography fontSize={'12px'} color={alpha(theme.palette.text.primary, 0.4)}>
                {title}
            </Typography>
            <Stack direction={'row'} gap={'5px'} alignItems={'end'}>
                {value && <Typography fontSize={'18px'} fontWeight={500}>
                    {value}
                </Typography>}
                {suffix && <Typography fontSize={'14px'} color={alpha(theme.palette.text.primary, 0.4)}>
                    {suffix}
                </Typography>}
            </Stack>
        </Stack>
    </Card>
}