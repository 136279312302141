import React, {FC, useState} from "react";
import {Widget} from "../../commons/widget_layout/Widget";
import {AccountTitle} from "../../commons/account_title/AccountTitle";
import {useUser} from "../../../features/user/useUser";
import {
    ExitIcon,
    GGRIcon,
    MiniButton,
    MoneySurfaceView,
    SettingsIcon,
    WalletIcon
} from "../../../themes/theme_connector";
import {ConfirmationDialog} from "../../../components/commons/ConfirmationDialog";
import {useLanguage} from "../../../features/localisation/useLanguage";
import Grid from "@mui/material/Unstable_Grid2";
import {UserRole} from "../../../features/user/user";
import {convertCentsToCurrencyString} from "../../../features/common_funcs";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import {useGeneralInfo} from "../../../features/general_info/useGeneralInfo";
import {upperCase} from "lodash";

export const UserInfoWidget: FC = () => {
    const { user, userRole } = useUser()
    const [ showLogout, setShowLogout ] = useState<boolean>(false)
    const { languagePack: {pack: {common: lang, generalInfo: generalInfoLang}}} = useLanguage()
    const { logOut } = useUser()
    const { revshare} = useGeneralInfo()

    return <>
        <Widget
            headerTitle={<AccountTitle user={user ?? undefined}/>}
            headerActions={<>
                <MiniButton
                    link={'/profile'}
                    icon={<SettingsIcon/>}
                    buttonVariant={'accented'}
                />
                <MiniButton
                    onClick={() => {
                        setShowLogout(true)
                    }}
                    icon={<ExitIcon/>}
                    buttonVariant={'accented'}
                />
            </>}
        >
            <Grid container spacing={2} direction={{xs: 'column', sm: 'row'}}>
                <Grid xs={12} sm={6} xl={4}>
                    <MoneySurfaceView
                        title={generalInfoLang.myBalanceLabel}
                        icon={WalletIcon}
                        currency={userRole !== null && userRole === UserRole.PLATFORM  ? undefined : upperCase('ars')}
                        value={user && userRole !== null ? userRole === UserRole.PLATFORM ? <AllInclusiveIcon/> : convertCentsToCurrencyString(user.user_balance) : undefined}
                    />
                </Grid>
                {revshare !== null && <Grid xs={12} sm={6} xl={4}>
                    <MoneySurfaceView
                        title={generalInfoLang.counterWidget.revshare}
                        icon={GGRIcon}
                        variant={'outlined'}
                        currency={upperCase('usd')}
                        value={convertCentsToCurrencyString(revshare)}
                    />
                </Grid>}
            </Grid>

        </Widget>

        {showLogout && <ConfirmationDialog close={() => {
            setShowLogout(false)
        }} message={lang.logOutConfirmationMessage} onConfirm={() => {
            logOut()
        }}/>}
    </>
}