import {FC, useEffect} from "react";
import {ContentPage} from "../../components/layout/content_components/ContentPage";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {resetGeneralInfo} from "../../features/general_info/GeneralInfoSlice";
import {useAppDispatch} from "../../store";
import {UserInfoWidget} from "../../components-v2/dashboard/widgets/UserInfoWidget";
import {useUser} from "../../features/user/useUser";
import {GeneralStatisticsWidget} from "../../components-v2/dashboard/widgets/GeneralStatisticsWidget";
import {Box} from "@mui/material";
import { FinancialDailyWidget } from "../../components-v2/dashboard/widgets/FinancialDailyWidget";
import { UsersDailyWidget } from "../../components-v2/dashboard/widgets/UsersDailyWidget";
import Stack from "@mui/material/Stack";

export const DashboardPage: FC = () => {
    const { user } = useUser()
    const { mediumScreen } = useCommonMediaQueries()
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(resetGeneralInfo())
        }
    }, [dispatch]);

    return <ContentPage overflow padding={1}>
        {user && <Stack gap={3}>
            <UserInfoWidget/>
            <GeneralStatisticsWidget/>
            <Box display={'flex'} gap={3} flexDirection={mediumScreen ? 'column' : 'row'}>
                <Box flex={1}>
                    <FinancialDailyWidget height={mediumScreen ? undefined : '100%'}/>
                </Box>

                <Box flex={1}>
                    <UsersDailyWidget height={mediumScreen ? undefined : '100%'}/>
                </Box>
            </Box>
        </Stack>}
    </ContentPage>
}