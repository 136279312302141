import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useUserTransfers } from "./useUserTransfers";
import { getNumberParam } from "../common_funcs";
import { UserTransfersFilter } from "./models/filter";

export const useUsersTransfersToMyselfWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const { isMoneyTransfersToMyselfLoading, getMoneyTransfersToMyself, transfersToMyself, filter } = useUserTransfers()

    useEffect(() => {
        if (!isMoneyTransfersToMyselfLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime 
            const transfersToMyselfNotLoadedYet = transfersToMyself === null

            if (fromTimeHasChanges
                || toTimeHasChanges
                || fromTimeHasChanges
                || transfersToMyselfNotLoadedYet){

                const filterNew: UserTransfersFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    userRole: null,
                    username: null,
                    typeTransaction: null,
                    direct: null
                }

                getMoneyTransfersToMyself(1, 100, filterNew)
            }
        }
    }, [disabled, filter.fromTime, filter.toTime, getMoneyTransfersToMyself, isMoneyTransfersToMyselfLoading, location.search, transfersToMyself])
}