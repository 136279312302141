import { FC } from "react";
import { User } from "../../../features/user/user";
import { Avatar, Typography } from "@mui/material";
import { chooseName } from "../../../features/user/userFuncs";

interface AccountAvatarProps {
    user: User
    size?: number
}

export const AccountAvatar: FC<AccountAvatarProps> = ({ user, size= 32 }) => <Avatar {...stringAvatar(chooseName(user), size)} />

const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

const stringAvatar = (name: string, size: number) => {
    const splittedName = name.split(' ')
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: `${size}px`,
            height: `${size}px`
        },
        children: <Typography fontSize={`${size / 1.5}px`}>{`${splittedName[0][0]}${splittedName.length > 1 ? splittedName[1][0] : ''}`}</Typography>,
    };
}