import { FC, useEffect, useState } from "react";
import { Contractor, ProviderType } from "../../features/providers/models/models";
import { useProviders } from "../../features/providers/useProviders";
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getCurrentDomain } from "../../config";
import { ContractorSelector } from "./ContractorSelector";
import { useAccess } from "../../features/common_funcs/access_hook/useAccess";
import { ProviderAccess } from "../../features/providers/models/access";
import { TableHeightWrapper } from "../layout/content_components/TableHeightWrapper";

export const ProvidersTable: FC = () => {
    const { hasAccess } = useAccess(ProviderAccess)
    const { getProviders, isProvidersLoading, providers } = useProviders()
    const [initLoad, setInitLoad ] = useState<boolean>(true)
    const [ contractorEditProviderID, setContractorEditProviderID ] = useState<number|null>(null)
    const [height, setHeight] = useState<number>(0)
    const currentDomain = getCurrentDomain()

    useEffect(() => {
        if (initLoad && !isProvidersLoading && providers === null && hasAccess) {
            getProviders(ProviderType.Slots)
            setInitLoad(false)
        }
    }, [getProviders, hasAccess, initLoad, isProvidersLoading, providers, setInitLoad])

    const getContractorName = (currentContractorId: number, contractors: Contractor[]) : string => {
        const contractor = contractors.find(contractor => contractor.contractor_id === currentContractorId)
        if(contractor) {
            return contractor.contractor_name
        }
        return ''
    }

    return <TableHeightWrapper currentHeight={height} onHeightChanged={setHeight}>
        <TableContainer sx={{ maxHeight: height }}>
            <Table stickyHeader aria-label="bonus table">
                <TableHead>
                    <TableRow>
                        <TableCell width={'50px'}>{'ID'}</TableCell>
                        <TableCell width={'50px'}>{'Logo'}</TableCell>
                        <TableCell align={'center'}>{'Title'}</TableCell>
                        <TableCell align={'center'}>{'Name'}</TableCell>
                        <TableCell width={'200px'}>{'Contractor'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {providers && providers.map(provider => <TableRow
                        key={provider.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>{provider.id}</TableCell>
                        <TableCell>{provider.logo ? <Avatar src={currentDomain + provider.logo} sx={{
                            backgroundColor: '#efefef',
                            padding: '8px'
                        }}/> : null}</TableCell>
                        <TableCell align={'center'}>{provider.title}</TableCell>
                        <TableCell align={'center'}>{provider.name}</TableCell>
                        <TableCell>{contractorEditProviderID === provider.id ?
                            <ContractorSelector providerID={provider.id}
                                                currentContractorID={provider.current_contractor_id}
                                                contractorsList={provider.contractor}
                                                close={() => setContractorEditProviderID(null)}/> :
                            <div onClick={() => setContractorEditProviderID(provider.id)} className={'cursor-pointer'}>
                                {getContractorName(provider.current_contractor_id, provider.contractor)}
                            </div>}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        </TableHeightWrapper>
}