import React, { FC, useState } from "react";
import { usePlayersAgents } from "../../../features/manage_players_agents/usePlayersAgents";
import { Alert, Box, Stack } from "@mui/material";
import { PlayersNAgentsCard } from "./PlayersNAgentsCard";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { TableHeightWrapper } from "../../layout/content_components/TableHeightWrapper";
import { PaginatorRow } from "../../layout/content_components/PaginatorRow";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { DepositWithdrawMoneyModal } from "../deposit&withdraw/DepositWithdrawMoney";
import { User } from "../../../features/user/user";
import { useUser } from "../../../features/user/useUser";
import { useAgents } from "../../../features/agents/useAgents";
import { CreatePlayerAgentModal } from "../players_&_agents_creation/CreatePlayerAgent";
import { PercentModalEditor } from "../percent_editor/PercentInlineEditor";

export const PlayersNAgentsList: FC = () => {
    const { languagePack: {pack: {playersAgents: playersAgentsLang, createUser: createUserLang}}} = useLanguage()
    const { users, isShowUsersLoading, totalPages, currentPage, pageSize } = usePlayersAgents()
    const { xxSmallScreen } = useCommonMediaQueries()
    const [height, setHeight] = useState<number>(0)
    const [depositRecipient, setDepositRecipient] = useState<User | null>(null)
    const [withdrawSender, setWithdrawSender] = useState<User | null>(null)
    const { user: loggedUser } = useUser()
    const { selectedAgent } = useAgents()
    const [ editUser, setEditUser ] = useState<User | null>(null)
    const [depositRateEditUser, setDepositRateEditUser] = useState<User | null>(null)

    return <>
        <TableHeightWrapper onHeightChanged={setHeight} currentHeight={height}>
            <Box height={height} sx={{
                backgroundColor: 'rgba(0,0,0,0.05)',
                overflowY: 'auto'
            }}>
                <Stack
                    justifyContent={'space-between'}
                    direction={'row'}
                    gap={'5px'}
                    padding={'5px'}
                    flexWrap={'wrap'}
                >
                    {xxSmallScreen && users !== null && users.length > 10 && !isShowUsersLoading && <Box width={'100%'}>
                        <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                    </Box>}
                    {users !== null && !isShowUsersLoading && users.map(
                        user => <Box
                            width={xxSmallScreen ? '100%' : 'calc((100% - 10px) / 2)'}
                            key={`userID-${user.user_id}`}
                        >
                            <PlayersNAgentsCard user={user} onDepositClick={setDepositRecipient} onWithdrawClick={setWithdrawSender}
                                                onEditClick={setEditUser} onDepositRateEditClick={setDepositRateEditUser}
                                                isParent={loggedUser?.user_id === user.user_id || selectedAgent?.user_id === user.user_id}/>
                        </Box>
                    )}
                    {users !== null && users.length === 0 &&
                        <Alert variant="outlined" severity="warning" sx={{ width: '100%' }}>
                            {playersAgentsLang.noUsersFoundMessage}
                        </Alert>}
                    {xxSmallScreen && <Box width={'100%'}>
                        <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                    </Box>}

                </Stack>
            </Box>

        </TableHeightWrapper>

        { depositRecipient && <DepositWithdrawMoneyModal mode={'deposit'} initiator={depositRecipient} close={() => setDepositRecipient(null)}/> }
        { withdrawSender && <DepositWithdrawMoneyModal mode={'withdraw'} initiator={withdrawSender} close={() => setWithdrawSender(null)}/> }
        { editUser && <CreatePlayerAgentModal dialogTitle={editUser.user_role === "agent" ? createUserLang.agentEditingTitle : createUserLang.playerEditingTitle} role={editUser.user_role === "agent" ? "agent" : "player"} close={() => setEditUser(null)} userIdForEdit={editUser.user_id} /> }
        { depositRateEditUser && <PercentModalEditor value={depositRateEditUser.deposit_rate} userId={depositRateEditUser.user_id} forceEditMode close={() => setDepositRateEditUser(null)}/>}
    </>
}