import { SecurityLogFilter, SecurityLogObj } from "./SecurityLogModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SecurityLogState {
    securityLog: SecurityLogObj[] | null
    isSecurityLogLoading: boolean
    pageSize: number
    currentPage: number
    totalRecords: number
    totalPages: number
    filter: SecurityLogFilter
}

const initialState: SecurityLogState = {
    securityLog: null,
    isSecurityLogLoading: false,
    pageSize: 30,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    filter: {
        fromTime: null,
        toTime: null,
        username: null,
        event: null,
        orderBy: null
    }
}

export const SecurityLogSlice = createSlice({
    name: 'securityLog',
    initialState,
    reducers: {
        clearSecurityLog: (state) => {
            state.securityLog = null
            state.isSecurityLogLoading = false
            state.filter = {
                fromTime: null,
                toTime: null,
                username: null,
                event: null,
                orderBy: null
            }
        },
        setSecurityLog: (state, action: PayloadAction<SecurityLogObj[]>) => {
            state.securityLog = action.payload
        },
        setSecurityLogLoading: (state, action: PayloadAction<boolean>) => {
            state.isSecurityLogLoading = action.payload
        },
        setCurrentSecurityLogPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setTotalSecurityLogRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setSecurityLogPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setSecurityLogFilter: (state, action: PayloadAction<SecurityLogFilter>) => {
            state.filter = action.payload
        },
    }
})

export const {
    clearSecurityLog,
    setSecurityLog,
    setSecurityLogLoading,
    setCurrentSecurityLogPage,
    setTotalSecurityLogRecords,
    setSecurityLogPageSize,
    setSecurityLogFilter
} = SecurityLogSlice.actions