import React, { FC, useEffect, useState } from "react";
import { TableHeightWrapper } from "../../layout/content_components/TableHeightWrapper";
import { Alert, Box, Stack } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useNetwin } from "../../../features/netwin/useNetwin";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { NetwinRoyaltyStatisticsCard } from "./NetwinRoyaltyStatisticsCard";
import { useAppDispatch } from "../../../store";
import { resetNetwinRoyalty } from "../../../features/netwin/netwinSlice";
import { GeneralInfoCard } from "../../commons/GeneralInfoCard";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";

export const NetwinRoyaltyStatisticsList: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: {netwinRoyaltyStatisticsTable: lang, netwinUsersTable: langUsers}}} = useLanguage()
    const { xxSmallScreen } = useCommonMediaQueries()
    const [height, setHeight] = useState<number>(0)
    const { isNetwinRoyaltyLoading, netwinRoyalty, royaltySummary, selectedAgentCommission } = useNetwin()

    useEffect(() => {
        return () => {
            dispatch(resetNetwinRoyalty())
        }
    }, [dispatch])

    return <TableHeightWrapper onHeightChanged={setHeight} currentHeight={height}>
        <Box height={height} sx={{
            backgroundColor: 'rgba(0,0,0,0.05)',
            overflowY: 'auto'
        }}>
            <Stack
                justifyContent={'space-between'}
                direction={'row'}
                gap={'5px'}
                padding={'5px'}
                flexWrap={'wrap'}
            >
                <Stack padding={'8px'} gap={'8px'} width={'100%'}>
                    <Box flex={1}>
                        <GeneralInfoCard title={lang.betsHeader}
                                         value={royaltySummary.totalBets ? convertCentsToCurrencyString(royaltySummary.totalBets, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={lang.winsHeader}
                                         value={royaltySummary.totalWins ? convertCentsToCurrencyString(royaltySummary.totalWins, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={lang.netwinHeader}
                                         value={royaltySummary.totalGGR ? convertCentsToCurrencyString(royaltySummary.totalGGR, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={`${langUsers.commissionHeader}${selectedAgentCommission !== null ? ` (${selectedAgentCommission * 100}%)` : ''}`}
                                         value={royaltySummary.totalCommission ? convertCentsToCurrencyString(royaltySummary.totalCommission, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                </Stack>
                {
                    netwinRoyalty !== null && !isNetwinRoyaltyLoading && netwinRoyalty.map(
                        royalty => <Box
                            width={xxSmallScreen ? '100%' : 'calc((100% - 10px) / 2)'}
                            key={`localUUID-${royalty.localUUID}`}
                        >
                            <NetwinRoyaltyStatisticsCard royalty={royalty}/>
                        </Box>
                    )
                }
                {  netwinRoyalty?.length === 0 &&
                    <Alert variant="outlined" severity="warning" sx={{width: '100%'}}>
                        {lang.royaltyStatisticsNotFoundMessage}
                    </Alert>}
            </Stack>
        </Box>
    </TableHeightWrapper>
};