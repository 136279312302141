import { ApiError } from "../apiResponseModels";
import { LangPack } from "./langModel";
import { AxiosError } from "axios";

export interface iUseLanguage {
    switchLanguageFromStore: () => void
    switchLanguage: (lang: Lang) => void
    setLocalizedError: (error: ApiError) => void
    handleNetworkErrors: (error: Error | AxiosError) => void
    languagePack: LangPack
    getLocalizedRoleName: (role: string) => string
}

export enum Lang {
    EN = 'en',
    RU = 'ru',
    DE = 'de',
    FR = 'fr',
    ES = 'es',
    PT = 'pt'
}