import {AccountTitleViewProps} from "../../../../../components-v2/commons/account_title/AccountTitle.interface";
import {FC} from "react";
import {Box, Skeleton, Typography, useTheme} from "@mui/material";
import Stack from "@mui/material/Stack";
import {alpha} from "@mui/system";

export const AccountTitleView: FC<AccountTitleViewProps> = ({avatar, title, role}) => {
    const theme = useTheme()

    return <Box display={'flex'} gap={2} alignItems={'center'}>
        {avatar}
        <Stack gap={1} justifyContent={'center'}>
            {title ? <Typography lineHeight={1} fontWeight={600} fontSize={20}>{title}</Typography> : <Skeleton height={'28px'} width={'100px'}/>}
            {role ? <Typography color={alpha(theme.palette.text.primary, 0.4)} lineHeight={1} fontWeight={700} fontSize={12} variant={'caption'}>{role}</Typography> : <Skeleton height={'12px'} width={'50px'}/>}
        </Stack>
    </Box>;
}