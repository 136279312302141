import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoyaltyFilter, RoyaltyObj } from "./RoyaltyModels";

export interface RoyaltyState {
    royaltyStatistics: RoyaltyObj[] | null
    filter: RoyaltyFilter
    isRoyaltyStatisticsLoading: boolean
}

const initialState: RoyaltyState = {
    royaltyStatistics: null,
    filter: {
        fromTime: null,
        toTime: null,
        orderBy: null
    },
    isRoyaltyStatisticsLoading: false
}

export const RoyaltySlice = createSlice({
    name: 'royalty',
    initialState,
    reducers: {
        clearRoyaltyStatistics: (state) => {
            state.royaltyStatistics = null
            state.isRoyaltyStatisticsLoading = false
            state.filter = {
                fromTime: null,
                toTime: null,
                orderBy: null
            }
        },
        setRoyaltyStatistics: (state, action: PayloadAction<RoyaltyObj[]>) => {
            state.royaltyStatistics = action.payload
        },
        setRoyaltyStatisticsLoading: (state, action: PayloadAction<boolean>) => {
            state.isRoyaltyStatisticsLoading = action.payload
        },
        setRoyaltyStatisticsFilter: (state, action: PayloadAction<RoyaltyFilter>) => {
            state.filter = action.payload
        }
    }
})

export const {
    clearRoyaltyStatistics,
    setRoyaltyStatistics,
    setRoyaltyStatisticsLoading,
    setRoyaltyStatisticsFilter
} = RoyaltySlice.actions