import {ButtonBase, darken, useTheme} from "@mui/material";
import {MiniButtonProps} from "./MiniButton.interface";
import {FC, ReactNode} from "react";
import {grey} from "@mui/material/colors";
import { Link } from "react-router-dom";

export const MiniButton: FC<MiniButtonProps> = ({icon, buttonVariant = 'outlined', iconColor, link, ...props}) => {
    const theme = useTheme()
    iconColor = iconColor || theme.palette.primary.main
    const bgColor = buttonVariant === 'outlined' ? 'transparent' : theme.palette.background.default

    return <LinkWrapper link={link}>
        <ButtonBase
            {...props}
            onClick={link ? undefined : props.onClick}
            sx={{
                width: '44px',
                height: '44px',
                borderRadius: 1,
                backgroundColor:
                bgColor,
                color: iconColor,
                border: buttonVariant === 'outlined' ? `1px solid ${theme.palette.neutral?.main}` : undefined,
                '&:hover': {
                    backgroundColor: buttonVariant === 'accented' ? darken(theme.palette.background.default, 0.05) : undefined,
                    border: buttonVariant === 'outlined' ? `1px solid ${darken(theme.palette.neutral?.main ?? grey[300], 0.2)}` : undefined,
                }
            }}
        >
            {icon}
        </ButtonBase>
    </LinkWrapper>
}

interface LinkWrapperProps {
    link?: string
    children?: ReactNode
}

const LinkWrapper: FC<LinkWrapperProps> = ({link, children}) => {
    return link !== undefined ? <Link to={link}>{children}</Link> : <>{children}</>
}