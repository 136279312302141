import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const esLang: LangPack = {
    lang: Lang.ES,
    langName: "Espanol",
    langShort: "es",
    pack: {
        "common": {
            "noBTN": "No",
            "yesBTN": "Sí",
            "closeBTN": "Cerrar",
            "cancelBTN": "Cancelar",
            "addBTN": "Agregar",
            "editBTN": "Editar",
            applyBTN: "Aplicar",
            resetBTN: "Reiniciar",
            "saveBTN": "Guardar",
            "errorCodeTitle": "Código de error",
            "optionsButton": "Opciones",
            "pageSizeSwitcherLabel": "Filas por página",
            "logOutConfirmationMessage": "¿Estás seguro de que quieres cerrar sesión?",
            "youMustBeLoggedMessage": "¡Debes estar conectado para ver esta sección!",
            "notEnoughRightsMessage": "No tienes suficientes derechos para acceder a esta página"
        },
        "login": {
            "logInBTN": "Iniciar sesión",
            "usernameLabel": "Nombre de usuario",
            "passwordLabel": "Contraseña"
        },
        "internalErrorMessages": {
            "networkError": "Ocurrió un error de red mientras se ejecutaba la solicitud.",
            "error502": "Ocurrió un error de servidor 502 mientras se ejecutaba la solicitud."
        },
        errorMessages: {
            2: "No hay entradas.",
            3: "La dirección de correo electrónico del usuario ya existe.",
            4: "El número de teléfono del usuario ya existe.",
            5: "El usuario ya ha iniciado sesión.",
            6: "Contraseña incorrecta.",
            7: "Resultado de juegos vacío.",
            8: "El ID del resultado no coincide con el ID de la solicitud.",
            9: "Error en la solicitud de resultado.",
            10: "Falta el ID de sesión en la respuesta.",
            11: "El usuario ya existe.",
            12: "Su token no es válido. Inicie sesión nuevamente.",
            13: "No tiene suficientes permisos para realizar esta acción.",
            14: "Falta el ID de usuario para esta acción.",
            15: "Falta el monto de dinero.",
            16: "La acción no existe.",
            17: "Falta el token.",
            18: "Fondos insuficientes.",
            19: "Debe ser el padre de este usuario para realizar esta acción.",
            20: "El usuario no existe.",
            21: "El tipo de valor para este campo es incorrecto.",
            22: "Falta la contraseña.",
            23: "Falta el nombre de usuario.",
            24: "Falta el ID del juego.",
            25: "Falta la nueva contraseña.",
            26: "No se pudo configurar el token.",
            27: "No se pudo eliminar el token.",
            28: "Falta el ID de usuario.",
            29: "El tipo de valor para este campo debe ser datetime en formato ISO.",
            30: "No se pudo actualizar la prohibición del usuario.",
            31: "Este usuario debe estar en su árbol para realizar esta acción.",
            32: "Este usuario está bloqueado y no puede realizar acciones.",
            33: "Falta la moneda.",
            34: "Falta el valor máximo.",
            35: "El tipo de límite debe ser único, diario, semanal o mensual.",
            36: "Falta el tipo de límite.",
            37: "El proveedor no existe.",
            38: "Falta el monto de la condición del nivel.",
            39: "Falta el tipo de condición del nivel.",
            40: "Falta el título del nivel.",
            41: "El reembolso para este nivel ya existe.",
            42: "El reembolso para este nivel no existe.",
            43: "El bono para este código promocional ya existe.",
            44: "El bono no existe.",
            45: "Falta el código promocional.",
            46: "Falta la hora de inicio.",
            47: "Falta la condición de monto del depósito.",
            48: "Falta la apuesta.",
            49: "Falta la cantidad de días de validez del monto.",
            50: "Falta el ID.",
            51: "Falta el tipo de reembolso, debe ser \"apuesta\" o \"depósito\".",
            52: "Falta la tasa de reembolso.",
            53: "Esta moneda no está disponible.",
            54: "Falta el parámetro de bloqueo - verdadero o falso.",
            55: "El grupo bancario para esta moneda no es válido.",
            56: "Falta el nombre del método de pago.",
            57: "Este usuario ya agregó una cuenta para este sistema de pago.",
            58: "Este método de pago no existe.",
            59: "Este método de pago no admite la moneda de este usuario.",
            60: "Este usuario no tiene una cuenta existente para este sistema de pago.",
            61: "El padre de este usuario no tiene una cuenta existente para este sistema de pago.",
            62: "Falta el ID de la transferencia.",
            63: "La transferencia con este ID no existe.",
            64: "Esta transferencia fue solicitada por un padre diferente.",
            65: "Todavía tiene un bono que no ha expirado.",
            66: "Esta acción promocional de bono aún no ha comenzado.",
            67: "Esta acción promocional de bono ya ha finalizado.",
            68: "Este depósito no cumple con los requisitos del bono.",
            69: "Esta transferencia no está pendiente y no puede ser aprobada ni rechazada.",
            70: "Falta el indicador de aprobación (verdadero/falso).",
            71: "Día de la semana incorrecto para este bono.",
            72: "El número de sus depósitos no cumple con los requisitos del bono.",
            73: "Faltan parámetros obligatorios para este método de pago o no coinciden con el formato de prueba.",
            74: "Falta la tasa de depósito.",
            75: "Falta el rol de usuario.",
            76: "No puede crear un usuario con este rol.",
            77: "Falta el parámetro de actividad de diversión.",
            78: "Esta función no está disponible para este sitio web.",
            79: "Falta la cantidad de monedas.",
            80: "Falta el precio del paquete de monedas.",
            81: "Falta el tipo de proveedor.",
            82: "Error en la solicitud de resultado.",
            83: "Falta el nombre del proveedor.",
            84: "Falta el nombre del contratista.",
            85: "El contratista no existe.",
            86: "Falta el paquete de artículos.",
            87: "Faltan los parámetros de prueba.",
            88: "El paquete de artículos no existe.",
            89: "Este usuario no puede crear agentes.",
            90: "Este usuario tiene desactivados los depósitos.",
            91: "Este usuario tiene desactivados los retiros.",
            92: "Faltan tipo(s) de transacción.",
            93: "No se encontraron usuarios.",
            94: "Faltan rangos.",
            95: "No hay proveedor con este rango.",
            96: "No se puede aplicar esta acción a proveedores de diferentes tipos.",
            97: "No hay juego con este rango.",
            98: "No se puede aplicar esta acción a juegos de diferentes proveedores.",
            99: "No se puede aplicar esta acción a juegos de diferentes contratistas.",
            100: "El nombre de usuario solo puede contener letras latinas, números, \".\", \"_\" y tener entre 5 y 18 caracteres.",
            101: "La contraseña del usuario solo puede contener letras latinas, números, los siguientes caracteres especiales \"_\", \".\", \"?\", \"!\" y tener entre 5 y 18 caracteres.",
            102: "La condición de apuesta y/o depósito debe ser mayor que 0.",
            103: "Este juego no existe o está desactivado.",
            104: "Error en la solicitud de Gitslotpark.",
            105: "No se pudo cambiar el saldo de diversión.",
            106: "No se pudo guardar la entrada de transferencia de dinero en la base de datos.",
            107: "Solo puede crear usuarios para usted o sus agentes.",
            108: "Error al crear el token Evolution.",
            109: "Los roles de usuario de padre e hijo no permiten depósitos.",
            110: "Los roles de usuario de padre e hijo no permiten retiros.",
            111: "No se pudo crear el token Ezugi.",
            112: "No se pudo crear el token Relum.",
            113: "El tiempo de espera para la rueda no ha terminado.",
            114: "Paquete gratuito no disponible (todavía).",
            115: "No se pudo crear el token Amigo.",
            116: "Error al crear el token de Tada",
            117: "Error al crear el token de DreamPlay"
        },
        "sideMenu": {
            "logOutBtn": "Cerrar sesión",
            "dashboard": "Tablero",
            "users": "Usuarios",
            "gameHistory": "Historial de juegos",
            "moneyTransfers": "Transferencias de dinero",
            "limitsDivider": "Límites",
            "limitsGeneral": "General",
            "limitsOutcome": "Resultado",
            "bonus": "Bono",
            "paymentSystems": "Mis pagos",
            "buyBalance": "Comprar saldo",
            "affiliateSystem": "Sistema de afiliados",
            "crmSettings": "Configuración de CRM",
            "triggerEvents": "Eventos desencadenantes",
            "websiteSettings": "Configuración del sitio web",
            "securityLog": "Registro de seguridad",
            "royaltyStatistics": "Estadísticas de regalías",
            "finances": "Finanzas",
            "providers": "Proveedores",
            "netwinDivider": "Netwin",
            "netwinUser": "Agentes",
            "netwinGeneralInfo": "Información general",
            "agentTransfers": "Transferencias de agentes",
            "userTransfers": "Transferencias de usuarios",
            depositsAndWithdrawals: 'Depósitos y retiros',
            myFinances: 'Mis finanzas'
        },
        "commonSortingNames": {
            "sortLabel": "Ordenar",
            "usernameAsc": "↑ Nombre de usuario",
            "usernameDesc": "↓ Nombre de usuario",
            "balanceAsc": "↑ Saldo",
            "balanceDesc": "↓ Saldo",
            "providerAsc": "↑ Proveedor",
            "providerDesc": "↓ Proveedor",
            "gameAsc": "↑ Juego",
            "gameDesc": "↓ Juego",
            "betAsc": "↑ Apuesta",
            "betDesc": "↓ Apuesta",
            "winAsc": "↑ Ganancia",
            "winDesc": "↓ Ganancia",
            "dateAsc": "↑ Fecha",
            "dateDesc": "↓ Fecha",
            "netwinAsc": "↑ Netwin",
            "netwinDesc": "↓ Netwin",
            "percentAsc": "↑ Porcentaje",
            "percentDesc": "↓ Porcentaje"
        },
        "userProfileForm": {
            "userProfileTabLabel": "Información del usuario",
            "passwordTabLabel": "Contraseña",
            "emailIsRequiredMess": "El correo electrónico es obligatorio",
            "notValidEmail": "Ingresa una dirección de correo electrónico válida",
            "currentPassFieldLabel": "Contraseña actual",
            "emailFieldLabel": "Correo electrónico",
            "emptyFieldWarningMessage": "Este campo no debe estar vacío",
            "firstnameFieldLabel": "Nombre",
            "lastnameFieldLabel": "Apellido",
            "newPassFieldLabel": "Nueva contraseña",
            "passNotMatchWarningMessage": "La nueva contraseña y la contraseña reescrita no coinciden",
            "phoneFieldLabel": "Teléfono",
            "retypePassFieldLabel": "Reescribir nueva contraseña",
            "saveInfoBTN": "Guardar información",
            "updatePassBTN": "Actualizar contraseña",
            onlyRequiredFieldsLabel: "Solo campos obligatorios"
        },
        "userInfo": {
            "registeredLabel": "Registrado",
            "roleLabel": "Rol",
            "balanceLabel": "Saldo",
            "firstNameLabel": "Nombre",
            "lastnameLabel": "Apellido",
            "phoneLabel": "Teléfono",
            "emailLabel": "Correo electrónico",
            "depositMoneyBtn": "Depositar dinero al usuario",
            "withdrawMoneyBtn": "Retirar dinero del usuario",
            "moneyTransfersBtn": "Transferencias de dinero",
            "gamesHistoryBtn": "Historial de juegos",
            "banUserLabel": "Prohibir usuario",
            "banUserStateBanned": "Prohibido",
            "banUserStateActive": "Activo"
        },
        "createUser": {
            "title": "Crear nuevo usuario",
            "usernameLabel": "Nombre de usuario",
            "createBtn": "Crear",
            "agentCreationTitle": "Crear nuevo agente",
            "playerCreationTitle": "Crear nuevo jugador",
            agentEditingTitle: "Editar agente",
            playerEditingTitle: "Editar jugador",
            "agentSuccessfullyCreatedMessage": "Has creado un agente con éxito.",
            "playerSuccessfullyCreatedMessage": "Has creado un jugador con éxito.",
            "createAgentBTN": "Crear agente",
            "createPlayerBTN": "Crear jugador",
            editAgentBTN: "Guardar agente",
            editPlayerBTN: "Guardar jugador",
            depositEnabledSwitcherLabel: 'Depositar dinero',
            withdrawEnabledSwitcherLabel: 'Retirar dinero',
            onlyPlayersSwitcherLabel: 'Solo jugadores',
        },
        "moneyInput": {
            "maxAmountMessage": "Valor máximo",
            "exceedAmountError": "Se excedió el valor máximo.",
            "moreThanOneDotError": "Has hecho un punto extra.",
            "moreThanTwoDecimals": "Ingresaste más de dos decimales."
        },
        "depositMoneyToUser": {
            "depositLabel": "Depositar",
            "confirmSendingMessage": "¿Estás seguro de que deseas enviar dinero al usuario %username%?",
            "moneySuccessfulSentMessage": "Has enviado con éxito %money% a %username%.",
            "moneySuccessfulSentMessageShort": "Has enviado dinero con éxito.",
            "sendBtn": "Enviar",
            "senderLabel": "Remitente",
            "recipientLabel": "Destinatario"
        },
        "withdrawMoneyFromUser": {
            "withdrawLabel": "Retirar del usuario",
            "confirmWithdrawMessage": "¿Estás seguro de que deseas retirar dinero del usuario %username%?",
            "moneySuccessfulWithdrawedMessage": "Has retirado con éxito %money% de %username%.",
            "moneySuccessfulWithdrawedMessageShort": "Has retirado dinero con éxito.",
            "withdrawBtn": "Retirar",
            "withdrawFromLabel": "Retirar de",
            "toReceiverLabel": "Al receptor",
            withdrawAllBtn: "Retirar todo"
        },
        "moneyTransfersTable": {
            "usernameHeader": "Nombre de usuario",
            "amountTableHeader": "Cantidad",
            "methodTableHeader": "Método",
            "statusTableHeader": "Estado",
            "depositWithdrawTableHeader": "Depositar/Retirar",
            "dateTimeTableHeader": "Fecha/Hora",
            "moneyTransfersNotFoundMessage": "No se encontraron transferencias de dinero"
        },
        "gamesHistory": {
            "userNameTableHeader": "Nombre de usuario",
            "providerTableHeader": "Proveedor",
            "gameTableHeader": "Juego",
            "betAmountTableHeader": "Cantidad de apuesta",
            "winAmountTableHeader": "Cantidad de ganancia",
            "dateTimeTableHeader": "Fecha/Hora",
            "gamesHistoryNotFoundMessage": "No se encontraron registros de historial de juegos"
        },
        "paymentSystems": {
            "noAvailablePaymentMethodsMessage": "No hay más métodos de pago disponibles",
            "paymentMethodsDropdownLabel": "Métodos de pago",
            "yourPaymentAccountsTitle": "Tus cuentas de pago",
            "addPaymentMethodBTN": "Agregar método de pago",
            "paymentAccountSuccessfullyUpdated": "Has actualizado tu cuenta de pago con éxito.",
            "depositRateLabel": "Tasa de depósito",
            "depositRateSavedMessage": "Tasa de depósito guardada con éxito."
        },
        "approveTransaction": {
            "userRequestPayoutTitle": "El usuario %username% solicitó un retiro de %money% a %payment_system%",
            "userRequestPaymentTitle": "El usuario %username% transfiere un pago de %money% a %payment_system% para recargar su saldo.",
            "confirmPayoutMessage": "¿Apruebas la transacción?",
            "declineBTN": "Rechazar",
            "approveBTN": "Aprobar"
        },
        "requestPayment": {
            "choosePaymentAccountTitle": "Elige la cuenta de pago\n para comprar saldo",
            "paymentAmountLabel": "Cantidad de pago",
            "youllReceiveOnBalanceLabel": "Recibirás en el saldo",
            "depositRateHelper": "Tasa de depósito %rate%",
            "backBtn": "Atrás",
            "nextBtn": "Siguiente",
            "iTransferMoneyBtn": "Yo transfiero dinero",
            "transferMoneyToThisAccountMessage": "1. Transfiere dinero a esta cuenta",
            "enterYourProofMessage": "2. Ingresa tu comprobante para verificar tu pago"
        },
        "securityLog": {
            "logID": "ID",
            "userName": "Nombre de usuario",
            "userIP": "IP del usuario",
            "logAction": "Acción del registro",
            "requestJSON": "Solicitud JSON",
            "responseJSON": "Respuesta JSON",
            "timestamp": "Fecha/Hora",
            "securityLogNotFoundMessage": "No se encontraron registros de seguridad"
        },
        "royaltyStatistics": {
            "royaltyStatisticsTitle": "Estadísticas de regalías",
            "providerTableHeader": "Proveedor",
            "sectionTableHeader": "Sección",
            "currencyTableHeader": "Moneda",
            "userLossesTableHeader": "Pérdidas del usuario",
            "userWinsTableHeader": "Ganancias del usuario",
            "ggrTableHeader": "GGR",
            "royaltyStatisticsNotFoundMessage": "No se encontraron registros de estadísticas de regalías"
        },
        "manageUsers": {
            "usersTreeSwitcher": "Árbol de usuarios",
            "createUserBtn": "Crear usuario",
            "activeTableHeader": "Activo",
            "usernameHeader": "Nombre de usuario",
            "firstNameTableHeader": "Nombre",
            "lastnameTableHeader": "Apellido",
            "balanceTableHeader": "Saldo",
            "currencyTableHeader": "Moneda",
            "roleTableHeader": "Rol",
            "emailTableHeader": "Correo electrónico",
            "phoneTableHeader": "Teléfono",
            "operationsTableHeader": "Operaciones",
            "dateTableHeader": "Fecha",
            "commissionTableHeader": "Comisión",
            "actionsTableHeader": "Acciones",
            "usersNotFoundMessage": "No se encontraron usuarios"
        },
        timeFilter: {
            fromTimeDefaultLabel: "Hora desde",
            toTimeDefaultLabel: "Hora hasta",
            fromTimeLabel: "Desde %time%",
            toTimeLabel: "Hasta %time%",
            todayBTN: "Hoy",
            yesterdayBTN: "Ayer",
            weekBTN: "Semana",
            monthBTN: "Mes",

            date: 'Fecha',
            quickDates: {
                today: 'Hoy',
                yesterday: 'Ayer',
                lastWeek: 'Semana pasada',
                thisWeek: 'Esta semana',
                lastMonth: 'Mes pasado',
                thisMonth: 'Este mes',
            }
        },
        "usernameFilter": {
            "usernameFilterLabel": "Nombre de usuario"
        },
        "userRoles": {
            "platform": "plataforma",
            "owner": "propietario",
            "superadmin": "superadministrador",
            "admin": "administrador",
            "cashier": "cajero",
            "player": "jugador",
            "agent": "agente"
        },
        "currencySwitcher": {
            "currencyLabel": "Moneda"
        },
        "bonusConstructor": {
            "createBonusBTN": "Crear bono",
            "bonusConstructorTitle": "Constructor de bonos",
            "promoCodeLabel": "Nombre del código promocional",
            "promoCodeHelper": "nombre del código promocional que se ingresará al solicitar un bono",
            "conditionDepositLabel": "Depósito mínimo",
            "conditionDepositHelper": "depósito mínimo requerido para recibir el bono",
            "wagerLabel": "Factor de apuesta",
            "wagerHelper": "factor de apuesta",
            "daysValidLabel": "Días válidos",
            "daysValidHelper": "cuánto tiempo es válido el bono después de la solicitud en días",
            "bonusAmountLabel": "Cantidad de bono",
            "bonusAmountHelper": "premio en efectivo del bono",
            "startPromoDateLabel": "Fecha de inicio de la promoción",
            "endPromoDateLabel": "Fecha de fin de la promoción",
            "additionalConditionLabel": "Condición adicional",
            "additionalConditionHelper": "condición adicional para recibir un bono",
            "bonusPercentageLabel": "Porcentaje del bono, %",
            "bonusPercentageHelper": "premio del bono como porcentaje del depósito",
            "freeSpinsLabel": "Giros gratis",
            "freeSpinsHelper": "giros gratis del bono",
            "bonusMaxLabel": "Bono máximo",
            "bonusMaxHelper": "límite superior del bono",
            "saveBonusBTN": "Guardar bono",
            "bonusCreateSuccessMessage": "¡Bono guardado con éxito!"
        },
        "bonusList": {
            "promoLabel": "Código promocional",
            "fromTimeLabel": "Desde la hora",
            "toTimeLabel": "Hasta la hora",
            "conditionDepositLabel": "Depósito mínimo",
            "additionConditionLabel": "Condición adicional",
            "wagerLabel": "Factor de apuesta",
            "bonusAmountLabel": "Cantidad de bono",
            "bonusPercentageLabel": "Porcentaje de bono",
            "bonusFreeSpinsLabel": "Giros gratis del bono",
            "bonusMaxLabel": "Bono máximo",
            "daysValidLabel": "Días válidos",
            "deleteBonusConfirmationMessage": "¿Estás seguro de que quieres eliminar este bono?"
        },
        "coinsPackCreator": {
            "coinsPackCreatorTitle": "Creador de paquetes de monedas",
            "savePackBTN": "Guardar paquete",
            "coinsLabel": "Monedas",
            "coinsHelper": "monedas en el paquete",
            "freeCoinsLabel": "Monedas gratis",
            "freeCoinsHelper": "monedas gratis en el paquete",
            "priceLabel": "Precio",
            "priceHelper": "precio del paquete",
            "coinsPackCreateSuccessMessage": "¡Paquete de monedas guardado con éxito!"
        },
        "coinsList": {
            "idHeader": "ID",
            "coinsHeader": "Monedas",
            "priceHeader": "Precio",
            "freeCoinsHeader": "Monedas gratis",
            "deleteCoinsPackConfirmationMessage": "¿Estás seguro de que quieres eliminar este paquete de monedas?",
            "createCoinPackBTN": "Crear paquete de monedas"
        },
        "playersAgents": {
            "allUsersTab": "Todos los usuarios",
            "playersTab": "Jugadores",
            "agentsTab": "Agentes",
            "depositBtn": "Depositar",
            "withdrawBtn": "Retirar",
            gameHistoryBTN: "Historial de juego",
            editUserBTN: "Editar usuario",
            editDepositRate: 'Editar tasa de depósito',
            "noUsersFoundMessage": "No se encontraron usuarios",
            "userCardBalanceLabel": "Saldo",
            totalDeposits: "Total de depósitos",
            totalWithdrawals: "Total de retiros",
            netDeposit: "Neto de depósitos",
            directSwitchLabel: "Solo directo"
        },
        "netwinUsersTable": {
            "nameHeader": "Nombre",
            "betsHeader": "Apuestas",
            "winsHeader": "Ganancias",
            "netwinHeader": "Netwin",
            "commissionHeader": "Comisión",
            "percentageHeader": "Porcentaje",
            "noUsersFoundMessage": "No se encontraron usuarios"
        },
        "netwinRoyaltyStatisticsTable": {
            "providerHeader": "Proveedor",
            "betsHeader": "Apuestas",
            "winsHeader": "Ganancias",
            "netwinHeader": "Netwin",
            "royaltyStatisticsNotFoundMessage": "No se encontraron estadísticas de regalías",
        },
        "agentTransfersTable": {
            "typeHeader": "Tipo",
            "amountHeader": "Cantidad",
            "timeHeader": "Tiempo",
            "initiatorUserID": "ID del iniciador",
            "userIdHeader": "ID de usuario",
            balanceHeader: "Saldo",
            "userBalanceBeforeHeader": "Saldo del usuario antes",
            "userBalanceAfterHeader": "Saldo del usuario después",
            "originUserIdHeader": "ID de usuario de origen",
            "originUserBalanceBeforeHeader": "Saldo del usuario de origen antes",
            "originUserBalanceAfterHeader": "Saldo del usuario de origen después",
            "initiatorNameHeader": "Iniciador",
            "parentNameHeader": "Padre",
            "userNameHeader": "Usuario",
            "transfersNotFoundMessage": "No se encontraron transferencias",
            "allTypeTab": "Todos los tipos",
            "depositsTab": "Depósitos",
            "withdrawalsTab": "Retiros"
        },
        "balanceTooltip": {
            "before": "Antes",
            "after": "Después"
        },
        generalInfo: {
            myBalanceLabel: 'Mi saldo',
            financialDailyWidgetLabel: 'Finanzas',
            betsLabel: 'Apuestas',
            userBetsLabel: 'Apuestas del usuario',
            userLossesLabel: 'Pérdidas del usuario',
            userWinsLabel: 'Ganancias del usuario',
            totalBetsLabel: 'Apuestas',
            totalGGRLabel: 'GGR',
            totalNewUsersLabel: 'Nuevos usuarios',
            totalActiveUsersLabel: 'Usuarios activos',
            usersDailyWidgetLabel: 'Actividad',
            activeUsersLabel: 'Activos',
            newPlayersLabel: 'Nuevos',
            usersWidgetLabel: 'Usuarios',
            counterWidget: {
                revshare: 'Revshare',
                agents: 'Agentes',
                betshops: 'Tiendas de apuestas',
                cashiers: 'Cajeros',
                players: 'Jugadores',
            }
        }
    }
}