import React, { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

interface HeaderWithSubtitleProps {
    title: string | ReactNode
    subtitle: string
}

export const HeaderWithSubtitle: FC<HeaderWithSubtitleProps> = ({ title, subtitle }) => <Stack alignItems={'center'}>
    {title}
    <Typography lineHeight={1} fontSize={'10px'} fontWeight={500} sx={{opacity: '0.7'}} color={'primary'}>
        {subtitle}
    </Typography>
</Stack>