import { createTheme } from '@mui/material/styles';
import "@fontsource/poppins";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import "@fontsource/poppins/100-italic.css"
import "@fontsource/poppins/200-italic.css"
import "@fontsource/poppins/300-italic.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/500-italic.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/800-italic.css"
import "@fontsource/poppins/900-italic.css"

export const theme = createTheme({
    typography: {
        fontFamily: '"Poppins"'
    },
    spacing: 10,
    palette: {
        mode: 'dark',
        background: {
            default: '#172938',
            paper: '#303948',
        },
        primary: {
            light: '#F7E8D3',
            main: '#E1BF7D',
            dark: '#BD8545',
            contrastText: '#18171F'
        },
        secondary: {
            light: '#ee9e58',
            main: '#d0813a',
            dark: '#b96a23',
            contrastText: '#fff'
        },
        customColors: {
            financialChart: {
                userLossesColor: '#ff00ae',
                userWinsColor: '#00c4ff'
            },
            usersDailyChart: {
                activePlayersColor: "#ff8800",
                newPlayersColor: "#36c705"
            }
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                '& html, body, #root': {
                    maxWidth: '100vw',
                    overflow: 'hidden',
                    height: '100dvh',
                },
                body: {
                    margin: 0,
                },
                a: {
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': {
                        color: 'inherit'
                    },
                },
                '.cursor-pointer': {
                    cursor: 'pointer !important'
                }
            }),
        },
    }
});