import React, { FC } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { NetwinUser } from "../../../features/netwin/models/models";

interface NetwinAgentCardProps {
    agent: NetwinUser
    selectedAgentCommission: number | null
}

export const NetwinAgentCard:FC<NetwinAgentCardProps> = ({agent, selectedAgentCommission}) => {
    const { languagePack: {pack: {netwinUsersTable: lang}}} = useLanguage()

    return <Card sx={{ py: '10px', px: '2vw' }}>
        <Stack gap={'8px'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.nameHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {agent.agent_name}
                    </Typography>
                </Stack>
                <Stack alignItems={'center'}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.percentageHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {agent.agent_commission !== undefined ? `${agent.agent_commission * 100}%` : '---'}
                    </Typography>
                </Stack>
                <Stack alignItems={'end'}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.commissionHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {selectedAgentCommission !== null ? convertCentsToCurrencyString((agent.ggr * agent.agent_commission), true) : '---'}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.betsHeader}:
                    </Typography>
                    <Typography color={'secondary'} fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {agent.user_losses ? convertCentsToCurrencyString(agent.user_losses, true) : '--'}
                    </Typography>
                </Stack>

                <Stack>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.winsHeader}:
                    </Typography>
                    <Typography color={'primary'} fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {agent.user_wins ? convertCentsToCurrencyString(agent.user_wins, true) : '--'}
                    </Typography>
                </Stack>

                <Stack>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.netwinHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {convertCentsToCurrencyString(agent.ggr, true)}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    </Card>
}