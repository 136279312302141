import React, { FC, ReactNode, useState } from "react";
import { SideMenu } from "./side_menu/SideMenu";
import { Header } from "./header/Header";
import { Box, Hidden, IconButton, Stack } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface BaseLayoutProps {
    children?: ReactNode;
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const [ isDrawerOpen, setDrawerOpen ] = useState(false);
    const { largeScreen } = useCommonMediaQueries()

    // Function to toggle the state of the drawer (open/close)
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return <Stack
        width={'100vw'}
        height={'100dvh'}
        direction={'row'}
        flexWrap={'nowrap'}
        sx={{
            // background: 'linear-gradient(to bottom, #0D182C 0%, #526368 40%, #97AEA4 100%)',
            // backgroundColor: alpha('#fff', 0.1),
            overflowY: 'hidden',
        }}
    >
        {/* Side menu visible only on large screens and up */}
        <Hidden lgDown>
            <Box
                width={'300px'}
                maxHeight={'100dvh'}
                sx={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(175, 175, 175, 0.08) rgba(256, 256, 256, 0)',
                }}
            >
                <SideMenu/>
            </Box>
        </Hidden>


        {/* Drawer for side menu visible only on screens smaller than large */}
        <Hidden lgUp>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <SideMenu close={() => {
                    setDrawerOpen(false)
                }}/>
            </Drawer>
        </Hidden>

        <Box
            height={'100dvh'}
            flexGrow={1}
            maxWidth={largeScreen ? '100vw' : 'calc(100vw - 330px)'}
            sx={{
                overflowY: 'hidden',
                // backgroundColor: '#425bd3',
            }}
        >
            <Stack
                height={'100%'}
                component={'main'}
                maxWidth={largeScreen ? '100vw' : 'calc(100vw - 330px)'}
                sx={{
                    overflowY: 'hidden',

                }}
            >
                <Stack
                    maxWidth={largeScreen ? '100vw' : 'calc(100vw - 330px)'}
                    direction={'row'}
                    sx={{
                        // backgroundColor: '#3ec917',
                        overflowY: 'hidden',
                    }}
                >
                    <Hidden lgUp>
                        <IconButton
                            sx={{
                                width: '60px',
                                ml: 0
                            }}
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Hidden>
                    <Stack
                        direction={'row'}
                        width={'calc(100% - 60px)'}
                        flexGrow={1}
                        sx={{
                            overflowY: 'hidden',
                            // backgroundColor: '#4cc982',
                        }}
                    >
                        <Stack
                            width={'100%'}
                            direction={'row'}
                            flexWrap={'nowrap'}
                            sx={{
                                // backgroundColor: '#7a6a4b',
                            }}
                        >
                            <Header/>
                        </Stack>
                    </Stack>
                </Stack>
                    <Stack
                        width={'100%'}
                        height={'100%'}
                        flexGrow={1}
                        sx={{
                            // backgroundColor: '#868c30',
                            overflowY: 'hidden',
                        }}
                    >
                        {/* Content section where children components are rendered */}
                        {children}
                    </Stack>
            </Stack>
        </Box>
    </Stack>
}
