import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ErrorSlice } from "./features/apiErrors/errorSlice";
import { LanguageSlice } from "./features/localisation/LanguageSlice";
import { UserSlice } from "./features/user/userSlice";
import { ManageUsersSlice } from "./features/manage_users/manageUsersSlice";
import { MoneyTransfersSlice } from "./features/money_transfers/moneyTransfersSlice";
import { GameHistorySlice } from "./features/game_history/gameHistorySlice";
import { PaymentSystemsSlice } from "./features/payment_system/paymentSystemsSlice";
import { SecurityLogSlice } from "./features/security_log/securityLogSlice";
import { RoyaltySlice } from "./features/royalty/royaltySlice";
import { BonusSlice } from "./features/bonus/BonusSlice";
import { FinancesSlice } from "./features/finances/FinancesSlice";
import { ProviderSlice } from "./features/providers/ProviderSlice";
import { AgentsSlice } from "./features/agents/AgentsSlice";
import { ManagePlayersAgentsSlice } from "./features/manage_players_agents/managePlayersAndAgentsSlice";
import { NetwinSlice } from "./features/netwin/netwinSlice";
import { AgentTransfersSlice } from "./features/agent_transfers/agentTransfersSlice";
import { UserTransfersSlice } from "./features/user_transfers/UserTransfersSlice";
import {GeneralInfoSlice} from "./features/general_info/GeneralInfoSlice";

const store = configureStore({
    reducer: {
        lang: LanguageSlice.reducer,
        errors: ErrorSlice.reducer,
        user: UserSlice.reducer,
        manageUsers: ManageUsersSlice.reducer,
        moneyTransfers: MoneyTransfersSlice.reducer,
        gameHistory: GameHistorySlice.reducer,
        paymentSystems: PaymentSystemsSlice.reducer,
        securityLog: SecurityLogSlice.reducer,
        royalty: RoyaltySlice.reducer,
        bonus: BonusSlice.reducer,
        finances: FinancesSlice.reducer,
        providers: ProviderSlice.reducer,
        agents: AgentsSlice.reducer,
        managePlayersAgents: ManagePlayersAgentsSlice.reducer,
        netwin: NetwinSlice.reducer,
        agentTransfers: AgentTransfersSlice.reducer,
        userTransfers: UserTransfersSlice.reducer,
        generalInfo: GeneralInfoSlice.reducer
    }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector