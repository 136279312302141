import React, { FC, useEffect, useState } from "react";
import { Agent, AgentNode } from "../../../features/agents/agentsModels";
import { useAgents } from "../../../features/agents/useAgents";
import { Box, Chip, CircularProgress } from "@mui/material";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { BranchHolder } from "./BranchHolder";
import HdrWeakRoundedIcon from '@mui/icons-material/HdrWeakRounded';
import {setSelectedAgent} from "../../../features/agents/AgentsSlice";
import {useAppDispatch} from "../../../store";
import { useQuery } from "../../../features/common_funcs/query_hook/useQuery";
import { useLocation } from "react-router-dom";

interface AgentNodeComponentProps {
    agentNode: AgentNode
}

export const AgentNodeComponent:FC<AgentNodeComponentProps> = ({agentNode}) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const [ isExpanded, setExpanded ] = useState<boolean>(false)
    const {agents, agentsLoadingIds, agentsNodes, getAgentChildren, agentFilter, selectedAgent} = useAgents()
    const currentAgent: Agent|undefined = agents ? agents.find(agent => agent.user_id === agentNode.agent_user_id) : undefined
    const isLoading: boolean = currentAgent !== undefined ? agentsLoadingIds.includes(currentAgent.user_id) : false;
    const childNodes: AgentNode[] = agentsNodes !== null && agentNode.childIds !== undefined ? agentsNodes?.filter(node => agentNode.childIds?.includes(node.agent_user_id)) : []
    const dispatch = useAppDispatch()
    const isCurrentSelected = selectedAgent !== null && selectedAgent.user_id === currentAgent?.user_id
    const { updateQueryParameter } = useQuery()

    useEffect(() => {
        if (initLoad) {
            setInitLoad(false)
            setExpanded(agentNode.initialExpanded)
        }
    }, [agentNode.initialExpanded, initLoad])

    const expandClickToggle = () => {
        if (isExpanded) {
            setExpanded(false)
        } else if (childNodes?.length > 0) {
            setExpanded(true)
        } else {
            getAgentChildren(agentNode.agent_user_id, () => setExpanded(true))
        }
    }

    const toggleAgentSelection = () => {
        if (currentAgent) {
            if (searchParams.get("page")) {
                updateQueryParameter("page", null)
            }
            if (selectedAgent && selectedAgent.user_id === currentAgent.user_id) {
                dispatch(setSelectedAgent(null))
            } else {
                dispatch(setSelectedAgent(currentAgent))
            }
        }
    }

    return <Box
        display={'flex'}
        flexDirection={'column'}
    >
        {currentAgent && <Box display={'flex'} alignItems={'center'} columnGap={'8px'}>
            {isLoading ? <CircularProgress size={18} /> : (
                agentNode.rootNode ? <PanoramaFishEyeIcon/> : agentNode.hasChildren ? (
                    isExpanded ? <RemoveCircleOutlineIcon onClick={expandClickToggle} className={'cursor-pointer'}/> : <AddCircleOutlineIcon onClick={expandClickToggle} className={'cursor-pointer'}/>
                ) : <HdrWeakRoundedIcon/>)}
            <Chip sx={theme => ({
                backgroundColor: isCurrentSelected ? theme.palette.primary.main : 'default',
                userSelect: 'none',
                border: agentFilter === currentAgent.user_name ? `3px solid ${theme.palette.primary.main}` : 'none',
                '&:hover': {
                    backgroundColor: isCurrentSelected ? theme.palette.primary.light : 'default'
                }
            })} label={currentAgent.user_name} className={'cursor-pointer'} onClick={toggleAgentSelection}/>
        </Box>}
        {
            (isExpanded || agentNode.rootNode) && Boolean(childNodes) && childNodes.map(node => <BranchHolder key={`branch-${node.agent_user_id}`}>
                <AgentNodeComponent agentNode={node}/>
            </BranchHolder>)
        }
    </Box>;
};