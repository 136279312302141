export enum NetwinUserSort {
    agent_name = 'agent_name',
    user_losses = 'user_losses',
    user_wins = 'user_wins',
    ggr = 'ggr',
    agent_commission = 'agent_commission',
}

export const stringToNetwinUserSort = (stringNetwinUserSort: string): NetwinUserSort => {
    switch (stringNetwinUserSort) {
        case 'agent_name':
            return NetwinUserSort.agent_name
        case 'user_losses':
            return NetwinUserSort.user_losses
        case 'user_wins':
            return NetwinUserSort.user_wins
        case 'ggr':
            return NetwinUserSort.ggr
        case 'agent_commission':
            return NetwinUserSort.agent_commission
        default:
            return NetwinUserSort.agent_name
    }
}

export enum NetwinRoyaltySort {
    provider = 'provider',
    user_losses = 'user_losses',
    user_wins = 'user_wins',
    ggr = 'ggr',
}

export const stringToNetwinRoyaltySort = (stringNetwinRoyaltySort: string): NetwinRoyaltySort => {
    switch (stringNetwinRoyaltySort) {
        case 'provider':
            return NetwinRoyaltySort.provider
        case 'user_losses':
            return NetwinRoyaltySort.user_losses
        case 'user_wins':
            return NetwinRoyaltySort.user_wins
        case 'ggr':
            return NetwinRoyaltySort.ggr
        default:
            return NetwinRoyaltySort.provider
    }
}