import {Divider, Typography, useTheme} from "@mui/material";
import {FC} from "react";
import {alpha} from "@mui/system";

interface SideMenuDividerProps {
    children?: string
}

export const SideMenuDivider: FC<SideMenuDividerProps> = ({children}) => {
    const theme = useTheme();

    return (
        <Divider textAlign="left">{children && <Typography
            fontFamily={'Poppins'} variant={'caption'} color={alpha(theme.palette.primary.main, 0.6)}>
            {children}
        </Typography>}</Divider>
    );
}