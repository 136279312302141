import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useAppDispatch } from "../../store";
import { useUserTransfers } from "../../features/user_transfers/useUserTransfers";
import {
    Alert,
    Box, Skeleton, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import { resetUserTransfers } from "../../features/user_transfers/UserTransfersSlice";
import { BalanceTooltip } from "../commons/balance/BalanceTooltip";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const UserTransfersTable: FC = () => {
    const { languagePack: {pack: {agentTransfersTable: lang}}} = useLanguage()
    const dispatch = useAppDispatch()
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0)
    const { isUserTransfersLoading, userTransfers } = useUserTransfers()

    const updateHeight = useCallback(() => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight
            if (height !== newHeight) {
                setHeight(newHeight)
            }
        }
    }, [height])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    useEffect(() => {
        return () => {
            dispatch(resetUserTransfers())
        }
    }, [dispatch])

    return <Box
        height={'100%'}
        ref={wrapperRef}
        flexGrow={1}
    >
        <TableContainer sx={{
            scrollbarWidth: 'thin',
            maxHeight: `${height}px`,
            '& .MuiTableCell-head:not(:last-child)': {
                borderRight: 'rgba(0,0,0,0.05) solid 1px',
            },
        }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{lang.timeHeader}</TableCell>
                        <TableCell align="center">{lang.typeHeader}</TableCell>
                        <TableCell align="center">{lang.initiatorNameHeader}</TableCell>
                        <TableCell align="center">{lang.parentNameHeader}</TableCell>
                        <TableCell align="center">{lang.userNameHeader}</TableCell>
                        <TableCell align="center">{lang.amountHeader}</TableCell>
                        <TableCell align="center">{lang.balanceHeader}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userTransfers !== null && userTransfers.map(transfer => <TableRow>
                        <TableCell align={'center'}>{transfer.time}</TableCell>
                        <TableCell align="center">
                            <Typography fontWeight={500} textTransform={'uppercase'} color={transfer.transfer_type === 'deposit' ? 'primary' : 'secondary'}>
                                {transfer.transfer_type}
                            </Typography>
                        </TableCell>
                        <TableCell align={'center'}>{transfer.initiator_user_name}</TableCell>
                        <BalanceTooltip balance_after={transfer.origin_balance_after} balance_before={transfer.origin_balance_before}>
                            <TableCell align={'center'} sx={{cursor: 'help'}}>{transfer.origin_user_name}</TableCell>
                        </BalanceTooltip>
                        <TableCell align={'center'}>{transfer.user_name}</TableCell>
                        <TableCell align={'center'}>{convertCentsToCurrencyString(transfer.transfer_amount, true)}</TableCell>
                        <TableCell align={'center'}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                <Box>
                                    {convertCentsToCurrencyString(transfer.user_balance_before, true)}
                                </Box>
                                <Box width={'16px'} display={'flex'} alignItems={'center'}>
                                    <PlayArrowIcon fontSize={'small'} sx={{transform: `scaleX(${transfer.transfer_type === 'deposit' ? '1' : '-1'})`, color: transfer.transfer_type === 'deposit' ?  '#a9d933' : '#e86708'}}/>
                                </Box>
                                <Box>
                                    {convertCentsToCurrencyString(transfer.user_balance_after, true)}
                                </Box>
                            </Stack>
                        </TableCell>

                    </TableRow>)}

                    { userTransfers === null && isUserTransfersLoading &&
                        <TableRow>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
        { userTransfers !== null && userTransfers.length === 0 &&
            <Alert variant="outlined" severity="warning">
                {lang.transfersNotFoundMessage}
            </Alert>}
    </Box>
}