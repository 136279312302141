import {
    ActiveUserStatistic,
    CompoundedUsersDaily,
    DailyWagerReport,
    NewUserStatistic,
    UserCounter
} from "./models/model";
import {UserRole} from "../user/user";

export const groupDataByDay = (data: DailyWagerReport[]) => {
    return data.reduce((acc: Record<string, DailyWagerReport>, curr) => {
        const { calendar_day, user_bets, user_losses, user_wins, ggr } = curr;

        if (!acc[calendar_day]) {
            acc[calendar_day] = { ...curr };
        } else {
            acc[calendar_day].user_bets += user_bets;
            acc[calendar_day].user_losses += user_losses;
            acc[calendar_day].user_wins += user_wins;
            acc[calendar_day].ggr += ggr;
        }

        return acc;
    }, {});
};

export const mergeUserStatistics = (
    dailyUsers: NewUserStatistic[],
    dailyActiveUsers: ActiveUserStatistic[]
): CompoundedUsersDaily[] => {
    const combinedData: Record<string, CompoundedUsersDaily> = {};

    dailyUsers.forEach(userStat => {
        const { calendar_day, new_players, date } = userStat;
        if (!combinedData[calendar_day]) {
            combinedData[calendar_day] = { calendar_day, new_players, active_players: null, date };
        } else {
            combinedData[calendar_day].new_players = new_players;
        }
    });

    dailyActiveUsers.forEach(activeStat => {
        const { calendar_day, active_players, date } = activeStat;
        if (!combinedData[calendar_day]) {
            combinedData[calendar_day] = { calendar_day, new_players: null, active_players, date };
        } else {
            combinedData[calendar_day].active_players = active_players;
        }
    });

    // Преобразуем объект в массив и сортируем по дате
    return Object.values(combinedData).sort((a, b) => {
        if (a.date && b.date) {
            return a.date.getTime() - b.date.getTime();
        }
        return 0;
    });
};

export const filterCounters = (counters: UserCounter[], userRole: UserRole) : UserCounter[] => {
    let roles : string[] = [];

    switch (userRole) {
        case UserRole.PLATFORM:
            roles = ['agent', 'betshop', 'cashier', 'player']
            break;
        case UserRole.OWNER:
            roles = ['agent', 'betshop', 'cashier', 'player']
            break;
        case UserRole.AGENT:
            roles = ['agent', 'betshop', 'cashier', 'player']
            break;
        case UserRole.CASHIER:
            roles = ['cashier', 'player']
            break;
        default:
            break;
    }

    return counters.filter(counter => roles.includes(counter.user_role))
}