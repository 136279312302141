import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const deLang: LangPack = {
    lang: Lang.DE,
    langName: "German",
    langShort: "de",
    pack: {
        common: {
            yesBTN: "Ja",
            noBTN: "Nein",
            closeBTN: "Schließen",
            addBTN: "Hinzufügen",
            editBTN: "Bearbeiten",
            cancelBTN: "Abbrechen",
            applyBTN: "Anwenden",
            resetBTN: "Zurücksetzen",
            saveBTN: "Speichern",
            errorCodeTitle: "Fehlercode",
            optionsButton: 'Optionen',
            pageSizeSwitcherLabel: "Zeilen pro Seite",
            logOutConfirmationMessage: "Sind Sie sicher, dass Sie sich abmelden möchten?",
            youMustBeLoggedMessage: "Um diesen Abschnitt anzuzeigen, müssen Sie sich anmelden.",
            notEnoughRightsMessage: 'Nicht genug Rechte, um diesen Abschnitt anzuzeigen.'

        },
        login: {
            logInBTN: 'Anmelden',
            usernameLabel: 'Benutzername',
            passwordLabel: 'Passwort',
        },
        internalErrorMessages: {
            networkError: "Ein Netzwerkfehler ist aufgetreten, während die Anfrage ausgeführt wurde.",
            error502: "Ein Serverfehler 502 ist bei der Ausführung der Anfrage aufgetreten."
        },
        errorMessages: {
            2: "Keine Einträge.",
            3: "Benutzer-E-Mail-Adresse existiert bereits.",
            4: "Benutzer-Telefonnummer existiert bereits.",
            5: "Benutzer ist bereits eingeloggt.",
            6: "Falsches Passwort.",
            7: "Leeres Spielergebnis.",
            8: "Ergebnis-ID stimmt nicht mit der Anfrage-ID überein.",
            9: "Fehler bei der Ergebnisanforderung.",
            10: "Kein Sitzungs-ID in der Antwort.",
            11: "Benutzer existiert bereits.",
            12: "Ihr Token ist nicht gültig. Bitte erneut einloggen.",
            13: "Sie haben nicht genügend Rechte, um diese Aktion auszuführen.",
            14: "Benutzer-ID für diese Aktion fehlt.",
            15: "Geldbetrag fehlt.",
            16: "Aktion existiert nicht.",
            17: "Token fehlt.",
            18: "Nicht genug Geld.",
            19: "Sie müssen der Elternteil dieses Benutzers sein, um diese Aktion auszuführen.",
            20: "Benutzer existiert nicht.",
            21: "Falscher Werttyp für dieses Feld.",
            22: "Passwort fehlt.",
            23: "Benutzername fehlt.",
            24: "Spiel-ID fehlt.",
            25: "Neues Passwort fehlt.",
            26: "Token konnte nicht eingerichtet werden.",
            27: "Token konnte nicht gelöscht werden.",
            28: "Benutzer-ID fehlt.",
            29: "Der Werttyp für dieses Feld sollte ein Datum im ISO-Format sein.",
            30: "Benutzerbann konnte nicht aktualisiert werden.",
            31: "Dieser Benutzer muss in Ihrer Hierarchie sein, um diese Aktion auszuführen.",
            32: "Dieser Benutzer ist gesperrt und kann keine Aktionen ausführen.",
            33: "Währung fehlt.",
            34: "Maximalwert fehlt.",
            35: "Limit-Typ muss 'once', 'daily', 'weekly' oder 'monthly' sein.",
            36: "Limit-Typ fehlt.",
            37: "Anbieter existiert nicht.",
            38: "Betrag für Stufenbedingung fehlt.",
            39: "Typ für Stufenbedingung fehlt.",
            40: "Titel der Stufe fehlt.",
            41: "Cashback für diese Stufe existiert bereits.",
            42: "Cashback für diese Stufe existiert nicht.",
            43: "Bonus für diesen Promo-Code existiert bereits.",
            44: "Bonus existiert nicht.",
            45: "Promo-Code fehlt.",
            46: "Startzeit fehlt.",
            47: "Bedingung für Einzahlungsbetrag fehlt.",
            48: "Einsatzanforderung fehlt.",
            49: "Gültigkeitsdauer des Betrags fehlt.",
            50: "ID fehlt.",
            51: "Cashback-Typ fehlt, muss 'wager' oder 'deposit' sein.",
            52: "Cashback-Satz fehlt.",
            53: "Diese Währung ist nicht verfügbar.",
            54: "Bann-Parameter fehlt – 'true' oder 'false'.",
            55: "Die Bankgruppe für diese Währung ist nicht gültig.",
            56: "Name der Zahlungsmethode fehlt.",
            57: "Dieser Benutzer hat bereits ein Konto für dieses Zahlungssystem hinzugefügt.",
            58: "Diese Zahlungsmethode existiert nicht.",
            59: "Diese Zahlungsmethode unterstützt die Währung dieses Benutzers nicht.",
            60: "Dieser Benutzer hat kein bestehendes Konto für dieses Zahlungssystem.",
            61: "Der Elternteil dieses Benutzers hat kein bestehendes Konto für dieses Zahlungssystem.",
            62: "Transfer-ID fehlt.",
            63: "Transfer mit dieser ID existiert nicht.",
            64: "Dieser Transfer wurde von einem anderen Elternteil angefordert.",
            65: "Sie haben noch einen nicht abgelaufenen Bonus.",
            66: "Diese Bonus-Promo-Aktion hat noch nicht begonnen.",
            67: "Diese Bonus-Promo-Aktion ist bereits beendet.",
            68: "Diese Einzahlung erfüllt die Bonusanforderungen nicht.",
            69: "Dieser Transfer ist nicht ausstehend und kann nicht genehmigt oder abgelehnt werden.",
            70: "Genehmigungsflagge ('true'/'false') fehlt.",
            71: "Falscher Wochentag für diesen Bonus.",
            72: "Die Anzahl Ihrer Einzahlungen erfüllt die Bonusanforderungen nicht.",
            73: "Pflichtparameter für diese Zahlungsmethode fehlen oder entsprechen nicht dem Nachweisformat.",
            74: "Einzahlungsrate fehlt.",
            75: "Benutzerrolle fehlt.",
            76: "Sie können keinen Benutzer mit dieser Benutzerrolle erstellen.",
            77: "Aktivierungsparameter für Fun-Modus fehlt.",
            78: "Diese Funktion ist für diese Website nicht verfügbar.",
            79: "Münzmenge fehlt.",
            80: "Preis für Münzpaket fehlt.",
            81: "Anbietertyp fehlt.",
            82: "Fehler bei der Ergebnisanforderung.",
            83: "Anbietername fehlt.",
            84: "Name des Auftragnehmers fehlt.",
            85: "Auftragnehmer existiert nicht.",
            86: "Goodies-Paket fehlt.",
            87: "Nachweisparameter fehlen.",
            88: "Goodies-Paket existiert nicht.",
            89: "Dieser Benutzer kann keine Agenten erstellen.",
            90: "Dieser Benutzer ist für Einzahlungen deaktiviert.",
            91: "Dieser Benutzer ist für Auszahlungen deaktiviert.",
            92: "Transaktionstyp(en) fehlen.",
            93: "Keine Benutzer gefunden.",
            94: "Ränge fehlen.",
            95: "Kein Anbieter mit diesem Rang.",
            96: "Diese Aktion kann nicht auf Anbieter mit unterschiedlichen Typen angewendet werden.",
            97: "Kein Spiel mit diesem Rang.",
            98: "Diese Aktion kann nicht auf Spiele mit unterschiedlichen Anbietern angewendet werden.",
            99: "Diese Aktion kann nicht auf Spiele mit unterschiedlichen Auftragnehmern angewendet werden.",
            100: "Der Benutzername darf nur lateinische Buchstaben, Zahlen, '.', '_' enthalten und muss zwischen 5 und 18 Zeichen lang sein.",
            101: "Das Benutzerpasswort darf nur lateinische Buchstaben, Zahlen sowie die folgenden Sonderzeichen '_', '.', '?', '!' enthalten und muss zwischen 5 und 18 Zeichen lang sein.",
            102: "Einsatz- und/oder Einzahlungsbedingung muss größer als 0 sein.",
            103: "Dieses Spiel existiert nicht oder ist deaktiviert.",
            104: "Gitslotpark-Anfragefehler.",
            105: "Fun-Guthaben konnte nicht umgeschaltet werden.",
            106: "Geldtransfer konnte nicht in der Datenbank gespeichert werden.",
            107: "Sie können Benutzer nur für sich selbst oder Ihre Agenten erstellen.",
            108: "Fehler beim Erstellen des Evolution-Tokens.",
            109: "Eltern- und Kind-Benutzerrollen erlauben keine Einzahlungen.",
            110: "Eltern- und Kind-Benutzerrollen erlauben keine Auszahlungen.",
            111: "Ezugi-Token konnte nicht erstellt werden.",
            112: "Relum-Token konnte nicht erstellt werden.",
            113: "Die Abkühlzeit für das Glücksrad ist noch nicht abgelaufen.",
            114: "Gratispaket nicht (mehr) verfügbar.",
            115: "Amigo-Token konnte nicht erstellt werden.",
            116: "Fehler beim Erstellen des Tada-Tokens",
            117: "Fehler beim Erstellen des DreamPlay-Tokens"
        },
        sideMenu: {
            logOutBtn: "Abmelden",
            dashboard: 'Dashboard',
            users: 'Benutzer',
            gameHistory: 'Spielverlauf',
            moneyTransfers: 'Geldtransfers',
            limitsDivider: 'Limits',
            limitsGeneral: 'Allgemein',
            limitsOutcome: 'Outcome',
            bonus: 'Bonus',
            paymentSystems: 'Zahlungssysteme',
            buyBalance: 'Guthaben aufladen',
            affiliateSystem: 'Partnerprogramm',
            crmSettings: 'CRM-Einstellungen',
            triggerEvents: 'Auslösende Ereignisse',
            websiteSettings: 'Webseiteneinstellungen',
            securityLog: 'Sicherheitsprotokoll',
            royaltyStatistics: 'Royalty-Statistiken',
            finances: 'Finanzen',
            providers: 'Anbieter',
            netwinDivider: 'Netwin',
            netwinUser: 'Agenten',
            netwinGeneralInfo: 'Allgemeine Informationen',
            agentTransfers: 'Agenten-Transfers',
            userTransfers: 'Benutzer-Transfers',
            depositsAndWithdrawals: 'Einzahlungen und Auszahlungen',
            myFinances: 'Meine Finanzen'
        },
        commonSortingNames: {
            sortLabel: 'Sortierung',
            usernameAsc: '↑ Nach Namen',
            usernameDesc: '↓ Nach Namen',
            balanceAsc: '↑ Guthaben',
            balanceDesc: '↓ Guthaben',
            providerAsc: '↑ Provider',
            providerDesc: '↓ Provider',
            gameAsc: '↑ Spiel',
            gameDesc: '↓ Spiel',
            betAsc: '↑ Einsatz',
            betDesc: '↓ Einsatz',
            winAsc: '↑ Sieg',
            winDesc: '↓ Sieg',
            dateAsc: '↑ Datum',
            dateDesc: '↓ Datum',
            netwinAsc: '↑ Netwin',
            netwinDesc: '↓ Netwin',
            percentAsc: '↑ Prozentsatz',
            percentDesc: '↓ Prozentsatz'
        },
        userProfileForm: {
            userProfileTabLabel: 'Benutzerprofil',
            passwordTabLabel: 'Passwort',
            emailIsRequiredMess: 'E-Mail ist erforderlich',
            notValidEmail: 'Geben Sie eine gültige E-Mail-Adresse ein',
            currentPassFieldLabel: "Aktuelles Passwort",
            emailFieldLabel: "E-Mail",
            emptyFieldWarningMessage: "Dieses Feld muss ausgefüllt sein",
            firstnameFieldLabel: "Vorname",
            lastnameFieldLabel: "Nachname",
            newPassFieldLabel: "Neues Passwort",
            passNotMatchWarningMessage: "Neues Passwort und Passwortwiederholung stimmen nicht überein",
            phoneFieldLabel: "Telefon",
            retypePassFieldLabel: "Neues Passwort wiederholen",
            saveInfoBTN: "Informationen speichern",
            updatePassBTN: "Passwort aktualisieren",
            onlyRequiredFieldsLabel: "Nur erforderliche Felder",
        },
        userInfo: {
            registeredLabel: 'Registriert',
            roleLabel: 'Rolle',
            balanceLabel: 'Guthaben',
            firstNameLabel: 'Vorname',
            lastnameLabel: 'Nachname',
            phoneLabel: 'Telefon',
            emailLabel: 'E-Mail',
            depositMoneyBtn: 'Geld einzahlen',
            withdrawMoneyBtn: 'Geld auszahlen',
            moneyTransfersBtn: 'Geldtransfers',
            gamesHistoryBtn: 'Spielverlauf',
            banUserLabel: 'Benutzer sperren',
            banUserStateBanned: 'Gesperrt',
            banUserStateActive: 'Aktiv'
        },
        createUser: {
            title: "Neuen Benutzer erstellen",
            usernameLabel: "Benutzername",
            createBtn: "Erstellen",
            agentCreationTitle: "Anbieter erstellen",
            playerCreationTitle: "Spieler erstellen",
            agentEditingTitle: "Edit agent",
            playerEditingTitle: "Edit player",
            agentSuccessfullyCreatedMessage: "Sie haben erfolgreich einen Anbieter erstellt.",
            playerSuccessfullyCreatedMessage: "Sie haben erfolgreich einen Spieler erstellt.",
            createAgentBTN: "Agent erstellen",
            createPlayerBTN: "Spieler erstellen",
            editAgentBTN: "Agent speichern",
            editPlayerBTN: "Spieler speichern",
            depositEnabledSwitcherLabel: 'Einzahlung aktivieren',
            withdrawEnabledSwitcherLabel: 'Auszahlung aktivieren',
            onlyPlayersSwitcherLabel: 'Nur Spieler',
        },
        moneyInput: {
            maxAmountMessage: "Maximaler Betrag",
            exceedAmountError: "Maximalbetrag überschritten.",
            moreThanOneDotError: "Sie haben zu viele Punkte eingegeben.",
            moreThanTwoDecimals: "Sie haben mehr als zwei Dezimalstellen eingegeben."
        },
        depositMoneyToUser: {
            depositLabel: "Einzahlung",
            confirmSendingMessage: "Sind Sie sicher, dass Sie Geld an den Benutzer %username% senden möchten?",
            moneySuccessfulSentMessage: "Sie haben erfolgreich %username% %money% gesendet.",
            moneySuccessfulSentMessageShort: "Sie haben erfolgreich Geld gesendet.",
            sendBtn: "Senden",
            senderLabel: "Absender",
            recipientLabel: "Empfänger",
        },
        withdrawMoneyFromUser: {
            withdrawLabel: "Geld aus dem System abheben",
            confirmWithdrawMessage: "Sind Sie sicher, dass Sie Geld aus dem System %username% abheben möchten?",
            moneySuccessfulWithdrawedMessage: "Sie haben erfolgreich %money% aus dem System %username% abgehoben.",
            moneySuccessfulWithdrawedMessageShort: "Sie haben erfolgreich Geld abgehoben.",
            withdrawBtn: "Abheben",
            withdrawFromLabel: "Von",
            toReceiverLabel: "An",
            withdrawAllBtn: "Alles abheben"
        },
        moneyTransfersTable: {
            usernameHeader: "Benutzername",
            amountTableHeader: "Betrag",
            methodTableHeader: "Methode",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Einzahlung/Auszahlung",
            dateTimeTableHeader: "Datum/Uhrzeit",
            moneyTransfersNotFoundMessage: "Geldtransfers nicht gefunden"
        },
        gamesHistory: {
            userNameTableHeader: 'Benutzername',
            providerTableHeader: 'Anbieter',
            gameTableHeader: 'Spiel',
            betAmountTableHeader: 'Einsatz',
            winAmountTableHeader: 'Gewinn',
            dateTimeTableHeader: 'Datum/Uhrzeit',
            gamesHistoryNotFoundMessage: 'Keine Aufzeichnungen im Spielverlauf gefunden'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Keine verfügbaren Zahlungsmethoden",
            paymentMethodsDropdownLabel: "Zahlungsmethoden",
            yourPaymentAccountsTitle: "Ihre Zahlungskonten",
            addPaymentMethodBTN: "Zahlungsmethode hinzufügen",
            paymentAccountSuccessfullyUpdated: "Sie haben Ihr Zahlungskonto erfolgreich aktualisiert.",
            depositRateLabel: 'Einzahlungsrate',
            depositRateSavedMessage: 'Die Einzahlungsrate wurde erfolgreich gespeichert.'
        },
        approveTransaction: {
            userRequestPayoutTitle: "Benutzer %username% hat eine Auszahlung von %money% über %payment_system% angefordert.",
            userRequestPaymentTitle: "Benutzer %username% hat eine Zahlung von %money% über %payment_system% zur Aufladung seines Kontos getätigt.",
            confirmPayoutMessage: "Bestätigen Sie die Transaktion?",
            declineBTN: "Ablehnen",
            approveBTN: "Bestätigen"
        },
        requestPayment: {
            choosePaymentAccountTitle: "Wählen Sie das Zahlungskonto aus\n für die Aufladung des Kontos",
            paymentAmountLabel: "Zahlungsbetrag",
            youllReceiveOnBalanceLabel: "Sie erhalten auf dem Konto",
            depositRateHelper: "Einzahlungsrate %rate%",
            backBtn: "Zurück",
            nextBtn: "Weiter",
            iTransferMoneyBtn: "Ich überweise Geld",
            transferMoneyToThisAccountMessage: "1. Überweisen Sie Geld auf dieses Konto",
            enterYourProofMessage: "2. Geben Sie Ihren Nachweis ein, um Ihre Zahlung zu überprüfen"
        },
        securityLog: {
            logID: "ID",
            userName: "Benutzername",
            userIP: "Benutzer-IP",
            logAction: "Aktion",
            requestJSON: "Anfrage JSON",
            responseJSON: "Antwort JSON",
            timestamp: "Datum/Uhrzeit",
            securityLogNotFoundMessage: "Einträge im Sicherheitsprotokoll nicht gefunden"
        },
        royaltyStatistics: {
            royaltyStatisticsTitle: "Royalty-Statistiken",
            providerTableHeader: "Anbieter",
            sectionTableHeader: "Abschnitt",
            currencyTableHeader: "Währung",
            userLossesTableHeader: "Verluste des Benutzers",
            userWinsTableHeader: "Gewinne des Benutzers",
            ggrTableHeader: "GGR",
            royaltyStatisticsNotFoundMessage: "Einträge in den Royalty-Statistiken nicht gefunden"
        },
        manageUsers: {
            usersTreeSwitcher: "Benutzerbaum",
            createUserBtn: "Benutzer erstellen",
            activeTableHeader: "Aktiv",
            usernameHeader: "Benutzername",
            firstNameTableHeader: "Vorname",
            lastnameTableHeader: "Nachname",
            balanceTableHeader: "Guthaben",
            currencyTableHeader: "Währung",
            roleTableHeader: "Rolle",
            emailTableHeader: "E-Mail",
            phoneTableHeader: "Telefon",
            operationsTableHeader: "Operationen",
            dateTableHeader: "Datum",
            commissionTableHeader: "Kommission",
            actionsTableHeader: "Aktionen",
            usersNotFoundMessage: "Benutzer nicht gefunden"
        },
        timeFilter: {
            fromTimeDefaultLabel: "Von Zeitpunkt",
            toTimeDefaultLabel: "Bis Zeitpunkt",
            fromTimeLabel: "Von %time%",
            toTimeLabel: "Bis %time%",
            todayBTN: 'Heute',
            yesterdayBTN: 'Gestern',
            weekBTN: 'Woche',
            monthBTN: 'Monat',

            date: 'Datum',
            quickDates: {
                today: 'Heute',
                yesterday: 'Gestern',
                lastWeek: 'Letzte Woche',
                thisWeek: 'DieserKW',
                lastMonth: 'Letzter Monat',
                thisMonth: 'Dieser Monat',
            }
        },
        usernameFilter: {
            usernameFilterLabel: "Benutzername"
        },
        userRoles: {
            platform: "Plattform",
            owner: "Besitzer",
            superadmin: "Superadmin",
            admin: "Admin",
            cashier: "Kassierer",
            player: "Spieler",
            agent: "Agent",
        },
        currencySwitcher: {
            currencyLabel: 'Währung'
        },
        bonusConstructor: {
            createBonusBTN: 'Bonus erstellen',
            bonusConstructorTitle: 'Bonuskonstruktor',
            promoCodeLabel: 'Promo-Code',
            promoCodeHelper: 'Name des Promo-Codes, der bei der Bonusanfrage eingegeben wird',
            conditionDepositLabel: 'Bedingung Einzahlung',
            conditionDepositHelper: 'minimale Einzahlung, die für den Bonus erforderlich ist',
            wagerLabel: 'Wettanforderung',
            wagerHelper: 'Wettfaktor',
            daysValidLabel: 'Gültig für Tage',
            daysValidHelper: 'wie lange der Bonus nach der Anfrage gültig ist (in Tagen)',
            bonusAmountLabel: 'Bonusbetrag',
            bonusAmountHelper: 'Bonuspreis in Geld',
            startPromoDateLabel: 'Startdatum der Promoaktion',
            endPromoDateLabel: 'Enddatum der Promoaktion',
            additionalConditionLabel: "Zusätzliche Bedingung",
            additionalConditionHelper: 'zusätzliche Bedingung für den Erhalt des Bonus',
            bonusPercentageLabel: 'Bonusprozentsatz, %',
            bonusPercentageHelper: 'Bonuspreis in Prozent des Einzahlungsbetrags',
            freeSpinsLabel: 'Freispiele',
            freeSpinsHelper: 'Bonus-Freispiele',
            bonusMaxLabel: 'Maximaler Bonus',
            bonusMaxHelper: 'oberer Bonusgrenzwert',
            saveBonusBTN: "Bonus speichern",
            bonusCreateSuccessMessage: 'Bonus erfolgreich gespeichert!'
        },
        bonusList: {
            promoLabel: 'Promo-Code',
            fromTimeLabel: 'Beginn',
            toTimeLabel: 'Ende',
            conditionDepositLabel: 'Einzahlung',
            additionConditionLabel: 'Zusätzliche Bedingung',
            wagerLabel: 'Wettanforderung',
            bonusAmountLabel: 'Bonusbetrag',
            bonusPercentageLabel: 'Bonus in Prozent',
            bonusFreeSpinsLabel: 'Freispiele',
            bonusMaxLabel: 'Maximaler Bonus',
            daysValidLabel: 'Gültig für Tage',
            deleteBonusConfirmationMessage: 'Sind Sie sicher, dass Sie das Bonus löschen können?',
        },
        coinsPackCreator: {
            savePackBTN: 'Paket speichern',
            coinsPackCreatorTitle: 'Paketkonstruktor',
            coinsLabel: 'Coins',
            coinsHelper: 'Anzahl der Coins, die im Paket enthalten sind',
            priceLabel: 'Preis',
            priceHelper: 'Preis in Coins',
            freeCoinsLabel: 'Free Coins',
            freeCoinsHelper: 'Anzahl der Free Coins, die im Paket enthalten sind',
            coinsPackCreateSuccessMessage: 'Paket erfolgreich gespeichert!'
        },
        coinsList: {
            idHeader: 'ID', // coin pack ID
            coinsHeader: 'Coins', // coins
            priceHeader: 'Preis', // price
            freeCoinsHeader: 'Free Coins', // free coins
            deleteCoinsPackConfirmationMessage: 'Sind Sie sicher, dass Sie das Paket löschen können?', // 'Are you sure you want to delete this pack?'
            createCoinPackBTN: 'Paket erstellen' // 'Create coins pack'
        },
        playersAgents: {
            allUsersTab: "Alle Benutzer",
            playersTab: "Spieler",
            agentsTab: "Agenten",
            depositBtn: "Einzahlung",
            withdrawBtn: "Abheben",
            gameHistoryBTN: "Spielhistorie",
            editUserBTN: "Benutzer bearbeiten",
            editDepositRate: 'Einzahlungsrate',
            noUsersFoundMessage: "Keine Benutzer gefunden",
            userCardBalanceLabel: "Guthaben",
            totalDeposits: "Gesamteinzahlungen",
            totalWithdrawals: "Gesamtabhebungen",
            netDeposit: "Nettoeinzahlung",
            directSwitchLabel: 'Nur Direkt',
        },
        netwinUsersTable: {
            nameHeader: 'Name',
            betsHeader: 'Wetten',
            winsHeader: 'Gewinn',
            netwinHeader: 'Netwin',
            commissionHeader: 'Kommission',
            percentageHeader: 'Prozentsatz',
            noUsersFoundMessage: 'Keine Benutzer gefunden',
        },
        netwinRoyaltyStatisticsTable: {
            providerHeader: 'Anbieter',
            betsHeader: 'Wetten',
            winsHeader: 'Gewinn',
            netwinHeader: 'Netwin',
            royaltyStatisticsNotFoundMessage: 'Keine Statistiken gefunden',
        },
        agentTransfersTable: {
            typeHeader: "Typ",
            amountHeader: "Betrag",
            timeHeader: "Zeit",
            initiatorUserID: "Initiator-ID",
            userIdHeader: "Benutzer-ID",
            balanceHeader: "Guthaben",
            userBalanceBeforeHeader: "Guthaben vor dem Transfer",
            userBalanceAfterHeader: "Guthaben nach dem Transfer",
            originUserIdHeader: "Origin-Benutzer-ID",
            originUserBalanceBeforeHeader: "Ursprungssaldo vorher",
            originUserBalanceAfterHeader: "Ursprungssaldo danach",
            initiatorNameHeader: "Initiator",
            parentNameHeader: "Elternteil",
            userNameHeader: "Benutzer",
            transfersNotFoundMessage: "Keine Transaktionen gefunden",
            allTypeTab: "Alle Typen",
            depositsTab: "Einzahlungen",
            withdrawalsTab: "Auszahlungen",
        },
        balanceTooltip: {
            before: 'Vorher',
            after: 'Danach'
        },
        generalInfo: {
            myBalanceLabel: 'Mein Kontostand',
            financialDailyWidgetLabel: 'Finanzen',
            betsLabel: 'Wetten',
            userBetsLabel: 'Benutzerwetten',
            userLossesLabel: 'Benutzerverluste',
            userWinsLabel: 'Benutzergewinn',
            totalBetsLabel: 'Wetten',
            totalGGRLabel: 'GGR',
            totalNewUsersLabel: 'Neue Benutzer',
            totalActiveUsersLabel: 'Aktive Benutzer',
            usersDailyWidgetLabel: 'Aktivität',
            activeUsersLabel: 'Benutzer',
            newPlayersLabel: 'Neue Spieler',
            usersWidgetLabel: 'Benutzer',
            counterWidget: {
                revshare: 'Revshare',
                agents: 'Agenten',
                betshops: 'Betshops',
                cashiers: 'Kassierer',
                players: 'Spieler',
            }
        }
    }
}