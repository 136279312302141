import React, { FC, useState } from "react";
import { TableHeightWrapper } from "../../layout/content_components/TableHeightWrapper";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Alert, Box, Stack } from "@mui/material";
import { PaginatorRow } from "../../layout/content_components/PaginatorRow";
import { useGameHistory } from "../../../features/game_history/useGameHistory";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { GameHistoryCard } from "./GameHistoryCard";

export const GameHistoryList: FC = () => {
    const { languagePack: {pack: {gamesHistory: lang}}} = useLanguage()
    const { xxSmallScreen } = useCommonMediaQueries()
    const [height, setHeight] = useState<number>(0)
    const { gameHistory, currentPage, pageSize, totalPages, isGameHistoryLoading } = useGameHistory()

    return <TableHeightWrapper onHeightChanged={setHeight} currentHeight={height}>
        <Box height={height} sx={{
            backgroundColor: 'rgba(0,0,0,0.05)',
            overflowY: 'auto'
        }}>
            <Stack
                justifyContent={'space-between'}
                direction={'row'}
                gap={'5px'}
                padding={'5px'}
                flexWrap={'wrap'}
            >
                {xxSmallScreen && gameHistory !== null && gameHistory.length > 10 && !isGameHistoryLoading && <Box width={'100%'}>
                    <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                </Box>}
                {gameHistory && !isGameHistoryLoading && gameHistory.map(
                    (game) => <Box
                        id={game.localUUID}
                        width={xxSmallScreen ? '100%' : 'calc((100% - 10px) / 2)'}
                        key={game.localUUID}
                    >
                        <GameHistoryCard game={game}/>
                    </Box>
                )}
                {  gameHistory?.length === 0 &&
                    <Alert variant="outlined" severity="warning" sx={{width: '100%'}}>
                        {lang.gamesHistoryNotFoundMessage}
                    </Alert>}
                {xxSmallScreen && <Box width={'100%'}>
                    <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                </Box>}
            </Stack>
        </Box>
    </TableHeightWrapper>
}