import {useMemo} from "react";
import {useUserTransfers} from "../../features/user_transfers/useUserTransfers";

export type TransfersToMyselfInfo = {totalDeposits?: number, totalWithdraws?: number, netDeposit?: number}

export const useTransfersToMyselfInfo = (): TransfersToMyselfInfo => {
    const { transfersToMyself } = useUserTransfers()

    return useMemo(() => {
        if (transfersToMyself !== null) {
            // Summarize all transfer_amount from transfersToMyself where transfer_type contain 'deposit' word case-insensitive with help of reduce function
            const totalDeposits = transfersToMyself.reduce((total, transfer) => {
                if (transfer.transfer_type.toLowerCase().includes('deposit')) {
                    return total + transfer.transfer_amount
                }
                return total
            }, 0)
            // Summarize all transfer_amount from transfersToMyself where transfer_type contain 'withdraw' word case-insensitive with help of reduce function
            const totalWithdraws = transfersToMyself.reduce((total, transfer) => {
                if (transfer.transfer_type.toLowerCase().includes('withdraw')) {
                    return total + transfer.transfer_amount
                }
                return total
            }, 0)
            return { totalDeposits, totalWithdraws, netDeposit: totalDeposits - totalWithdraws }
        } else {
            return {}
        }
    }, [transfersToMyself])
}