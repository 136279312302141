import React, {FC, useEffect, useState} from "react";
import { Box, CircularProgress, InputAdornment, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useAgents } from "../../../features/agents/useAgents";
import { useAppDispatch } from "../../../store";
import { clearAgentsTree } from "../../../features/agents/AgentsSlice";


export const AgentsSearchBox: FC = () => {
    const dispatch = useAppDispatch()
    const {isAgentSearchLoading, findAgent, agentFilter, getAgentChildren} = useAgents()
    const [textValue, setTextValue] = useState<string>('')
    const [initLoad, setInitLoad] = useState<boolean>(true)

    useEffect(() => {
        if (initLoad) {
            if (agentFilter !== null) {
                setTextValue(agentFilter)
            }
            setInitLoad(false)
        }
    }, [agentFilter, initLoad])

    const toggleFilter = () => {
        if (Boolean(agentFilter)) {
            dispatch(clearAgentsTree())
            setTextValue('')
            getAgentChildren()
        } else {
            findAgent(textValue)
        }
    }

    return <Box>
        <TextField
            fullWidth
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            label={'Agent name'}
            size={'small'}
            placeholder={'Type full name'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton disabled={isAgentSearchLoading || (!Boolean(agentFilter) && !Boolean(textValue))} onClick={toggleFilter}>
                            {isAgentSearchLoading ? <CircularProgress size={18}/> : (
                                !Boolean(agentFilter) ? <SearchIcon/> : <CloseIcon/>
                            )}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    </Box>
}