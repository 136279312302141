import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const AgentIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 24 24" xmlSpace="preserve">
                <path d="M1.7,7c0.2-0.5,0.6-1.1,0.9-1.6l0.7-1.1c0.8-1.3,0.9-1.1,2.3-1.1l2.4,0l10.3,0c1.3,0,1.5-0.2,2.2,1l1.4,2.3
	c0.1,0.2,0.2,0.4,0.4,0.6c-0.2,0-0.5,0-0.7,0L16,7H1.7z" fill="currentColor"/>
                <path d="M4.8,10.6L4.8,10.6c-0.7,0.7-1.8,0.8-2.5,0.1C1.7,10,1.7,9.3,1.7,8.5l0-1c1.1,0.1,2.6,0,3.7,0l0,1.2
	C5.4,9.5,5.4,9.9,4.8,10.6z" fill="currentColor"/>
                <path d="M14.3,7.5h1.9h1.9l0,1.1c0,0.8,0,1.4-0.6,2c-0.4,0.4-0.8,0.6-1.3,0.6c-1,0-1.7-0.9-1.8-1.9c0-0.2,0-0.5,0-0.7L14.3,7.5z" fill="currentColor"/>
                <path d="M21.7,10.6c-1.3,1.2-3,0.4-3.1-1.2c0-0.2,0-1.9,0-1.9h2.5c0.4,0,0.8,0,1.3,0C22.3,8.6,22.5,9.7,21.7,10.6z" fill="currentColor"/>
                <path d="M9.1,10.6C7.9,11.8,6,11,5.9,9.4c0-0.3,0-0.5,0-0.8l0-1.1h3.8C9.7,8.6,9.8,9.7,9.1,10.6z" fill="currentColor"/>
                <path d="M13.3,10.6c-0.7,0.7-1.8,0.8-2.6,0.1c-0.7-0.7-0.7-1.2-0.7-2l0-1.1h3.8l0,1.2C13.9,9.5,13.9,9.9,13.3,10.6z" fill="currentColor"/>
                <path d="M5.6,10.1c0.9,1.9,3.3,2.1,4.2,0c0.6,0.9,1.4,1.8,2.7,1.4c0.8-0.2,1.2-0.8,1.6-1.4c0.4,1,1.5,1.7,2.6,1.5
	c0.8-0.2,1.2-0.8,1.6-1.5c0.4,0.8,1,1.4,1.9,1.5c0.2,0,0.4,0,0.6,0l0,0v9.3c0.2,0,0.8-0.1,1,0.1c0.2,0.2,0,0.4-0.2,0.5
	c-0.2,0-0.4,0-0.6,0l-5.4,0l-12.7,0c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4-0.3-0.2-0.5c0.2-0.1,0.7-0.1,0.9-0.1c0-0.4,0-0.7,0-1.1l0-8.3
	C4.2,11.8,5.2,11,5.6,10.1z M5.2,13.7c-0.1,0-0.2,0.1-0.3,0.2c0,0.3,0,0.5,0,0.8l0,6.2h4.4h0.3v-7c-0.2-0.3-0.7-0.2-1.1-0.2l-2.9,0
	C5.5,13.7,5.3,13.7,5.2,13.7z M12.2,13.7c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,3.4,0,3.8c0,0.4,0.6,0.3,0.9,0.3l4.2,0c0.5,0,1.1,0,1.6,0
	c0.4-0.2,0.3-0.8,0.3-1.2l0-2c0-0.3,0-0.7,0-0.9c-0.3-0.4-1.1-0.2-1.5-0.2l-4.3,0C12.9,13.7,12.5,13.7,12.2,13.7z" fill="currentColor"/>
</svg>
        </SvgIcon>
    );
}

export default AgentIcon;