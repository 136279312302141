import { useLocation } from "react-router-dom";
import { useAgents } from "../agents/useAgents";
import { useEffect, useRef } from "react";
import { useNetwin } from "./useNetwin";
import { getNumberParam } from "../common_funcs";
import { NetwinUsersFilter } from "./models/filter";
import { SortDirection } from "../common_models";
import { stringToNetwinUserSort } from "./models/sorting";

export const useNetwinUsersWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const {selectedAgent} = useAgents()
    const prevSelectedAgent = useRef(selectedAgent)
    const { users, usersFilter, isNetwinUsersLoading, getNetwinUsers } = useNetwin()
    
    useEffect(() => {
        if (!isNetwinUsersLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const userRoleValue = searchParams.get('userRole')
            const qUserRole: 'player' | 'agent' | null = (userRoleValue === 'player' || userRoleValue === 'agent') ? userRoleValue : null
            const qOrderBy = searchParams.get('orderBy')
            const sortDirection = searchParams.get('sortDirection')
            const qSortDirection: SortDirection | null = (sortDirection === 'DESC' || sortDirection === 'ASC') ? sortDirection : null

            const fromTimeHasChanges = qFromTime !== usersFilter.fromTime
            const toTimeHasChanges = qToTime !== usersFilter.toTime
            const userRoleHasChanges = qUserRole !== usersFilter.userRole
            const usernameHasChanges = qUsername !== usersFilter.username
            const orderByHasChanges = qOrderBy !== usersFilter.orderBy
            const sortDirectionHasChanges = qSortDirection !== usersFilter.sortDirection
            const selectedAgentHasChanges = prevSelectedAgent.current !== selectedAgent
            const usersNotLoadedYet = users === null

            if (fromTimeHasChanges
                || toTimeHasChanges
                || fromTimeHasChanges
                || userRoleHasChanges
                || usernameHasChanges
                || orderByHasChanges
                || sortDirectionHasChanges
                || selectedAgentHasChanges
                || usersNotLoadedYet) {
                // console.group('Changes useNetwinUsersWatcher')
                // console.log('fromTimeHasChanges', fromTimeHasChanges)
                // console.log('toTimeHasChanges', toTimeHasChanges)
                // console.log('userRoleHasChanges', userRoleHasChanges)
                // console.log('usernameHasChanges', usernameHasChanges)
                // console.log('selectedAgentHasChanges', selectedAgentHasChanges)
                // console.log('usersNotLoadedYet', usersNotLoadedYet)
                // console.groupEnd()
                
                const filterNew: NetwinUsersFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    userRole: qUserRole,
                    username: qUsername,
                    orderBy: qOrderBy !== null ? stringToNetwinUserSort(qOrderBy) : null,
                    sortDirection: qSortDirection,
                }
                
                getNetwinUsers(selectedAgent?.user_id ?? undefined, filterNew)
                
                prevSelectedAgent.current = selectedAgent
            }    
        }
    }, [disabled, getNetwinUsers, isNetwinUsersLoading, location.search, selectedAgent, users, usersFilter])
}