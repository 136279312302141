import React, { FC, ReactNode } from "react";
import { AgentsTree } from "../../agents/agents_tree/AgentsTree";
import { Card, Stack } from "@mui/material";

interface MainContentZoneProps {
    children?: ReactNode
    withAgentsTree?: boolean
}

export const MainContentZone: FC<MainContentZoneProps> = ({ children, withAgentsTree = false }) => {
    return <Stack direction={'row'} width={'100%'} flexGrow={1}>
        <Card sx={{ margin: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {children}
        </Card>
        {withAgentsTree && <AgentsTree/>}
    </Stack>
}