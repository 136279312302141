import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { resetNetwinRoyalty } from "../../features/netwin/netwinSlice";
import { useAppDispatch } from "../../store";
import { useNetwin } from "../../features/netwin/useNetwin";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import { useLanguage } from "../../features/localisation/useLanguage";
import { NetwinRoyaltySort } from "../../features/netwin/models/sorting";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";
import { SortDirection } from "../../features/common_models";
import { SortedCellsNetwinRoyalty } from "./SortedCellsNetwinRoyalty";
import { getCurrentDomain } from "../../config";

export const NetwinRoyaltyStatisticsTable: FC = () => {
    const { languagePack: {pack: {netwinRoyaltyStatisticsTable: lang}}} = useLanguage()
    const dispatch = useAppDispatch()
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0)
    const { isNetwinRoyaltyLoading, netwinRoyalty, royaltyFilter: {orderBy, sortDirection } } = useNetwin()
    const { batchUpdateQueryParameters } = useQuery()
    const currentDomain = getCurrentDomain()

    const updateHeight = useCallback(() => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight
            if (height !== newHeight) {
                setHeight(newHeight)
            }
        }
    }, [height])

    useEffect(() => {
        return () => {
            dispatch(resetNetwinRoyalty())
        }
    }, [dispatch])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    const handleClickSorting = (sort: NetwinRoyaltySort, sortDirection: SortDirection = 'DESC') => {
        batchUpdateQueryParameters([
            { paramName: 'orderBy', value: sort },
            { paramName: 'sortDirection', value: sortDirection }
        ])
    }

    return <Box
        height={'100%'}
        ref={wrapperRef}
        flexGrow={1}
    >
        <TableContainer sx={{
            maxHeight: `${height}px`,
            '& .MuiTableCell-head': {
                verticalAlign: 'top'
            },
        }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <SortedCellsNetwinRoyalty
                                orderName={NetwinRoyaltySort.provider}
                                currentOrderBy={orderBy}
                                sortDirection={sortDirection ?? 'DESC'}
                                onClick={handleClickSorting}
                            >
                                {lang.providerHeader}
                            </SortedCellsNetwinRoyalty>
                        </TableCell>
                        <TableCell align="center">
                                    <SortedCellsNetwinRoyalty
                                        orderName={NetwinRoyaltySort.user_losses}
                                        currentOrderBy={orderBy}
                                        sortDirection={sortDirection ?? 'DESC'}
                                        onClick={handleClickSorting}
                                    >
                                        {lang.betsHeader}
                                    </SortedCellsNetwinRoyalty>
                        </TableCell>
                        <TableCell align="center">
                                    <SortedCellsNetwinRoyalty
                                        orderName={NetwinRoyaltySort.user_wins}
                                        currentOrderBy={orderBy}
                                        sortDirection={sortDirection ?? 'DESC'}
                                        onClick={handleClickSorting}
                                    >
                                        {lang.winsHeader}
                                    </SortedCellsNetwinRoyalty>
                        </TableCell>
                        <TableCell align="center">
                                    <SortedCellsNetwinRoyalty
                                        orderName={NetwinRoyaltySort.ggr}
                                        currentOrderBy={orderBy}
                                        sortDirection={sortDirection ?? 'DESC'}
                                        onClick={handleClickSorting}
                                    >
                                        {lang.netwinHeader}
                                    </SortedCellsNetwinRoyalty>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        netwinRoyalty !== null && netwinRoyalty?.map(royalty => <TableRow key={royalty.localUUID}>
                            <TableCell align="left" sx={{display: 'flex', gap: '6px', alignItems: 'center'}}><Box
                                borderRadius={'6px'}
                                width={'30px'}
                                height={'30px'}
                                sx={{
                                    // backgroundColor: '#f6f5f5',
                                    backgroundImage: `url(${currentDomain}/cdn/provider_img/mini_sqr/${royalty.provider}.png)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                                {royalty.provider}</TableCell>
                            <TableCell align={'center'}>{convertCentsToCurrencyString(royalty.user_losses, true)}</TableCell>
                            <TableCell align={'center'}>{convertCentsToCurrencyString(royalty.user_wins, true)}</TableCell>
                            <TableCell align={'center'}>{convertCentsToCurrencyString(royalty.ggr, true)}</TableCell>
                        </TableRow>)
                    }
                    {netwinRoyalty === null && isNetwinRoyaltyLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        {  netwinRoyalty?.length === 0 &&
            <Alert variant="outlined" severity="warning" sx={{width: '100%'}}>
                {lang.royaltyStatisticsNotFoundMessage}
            </Alert>}
    </Box>
}