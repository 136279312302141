import {FC} from "react";
import {TimeFilterPickerViewProps} from "../../../../../components-v2/commons/time_filter/TimeFilter.interface";
import {Box, Button, Stack, useTheme} from "@mui/material";
import {useLanguage} from "../../../../../features/localisation/useLanguage";

export const TimeFilterPickerView: FC<TimeFilterPickerViewProps> = ({ children, onCancelClicked, onApplyClicked, onResetClicked }) => {
    const { languagePack: {pack: {common: lang}}} = useLanguage()
    const theme = useTheme()

    return <Box>
        <Stack maxHeight={`calc(100vh - ${theme.spacing(4)})`}>
            <Box flex={1} sx={{overflowY: 'auto', overflowX: 'hidden'}}>
                {children}
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} padding={1} gap={1}>
                <Button onClick={onCancelClicked} size={'small'} variant={'outlined'}>
                    {lang.cancelBTN}
                </Button>
                <Button onClick={onResetClicked} size={'small'} variant={'contained'} color={'secondary'}>
                    {lang.resetBTN}
                </Button>
                <Button onClick={onApplyClicked} size={'small'} variant={'contained'}>
                    {lang.applyBTN}
                </Button>
            </Box>
        </Stack>
    </Box>
}