import React, {FC, useEffect, useState} from "react";
import {Autocomplete, Box, Button, CircularProgress, IconButton, InputAdornment, TextField} from "@mui/material";
import {usePlayersAgents} from "../../../features/manage_players_agents/usePlayersAgents";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {useQuery} from "../../../features/common_funcs/query_hook/useQuery";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface UsernameFilterProps {
    username: string | null
    onUsernameChanged?: (username: string|null) => void
    pullParamsToAddressBar?: boolean
}

interface UsernameFilterBaseProps extends UsernameFilterProps {
    onCancel?: () => void
    startIconMode?: boolean
}

const UsernameFilterBase: FC<UsernameFilterBaseProps> = ({username, onUsernameChanged = ()=>{}, pullParamsToAddressBar = false, onCancel, startIconMode = false}) => {
    const [inputValue, setInputValue] = useState<string>(username ?? '');
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [prevValue, setPrevValue] = useState<string>(username ?? '');
    const [prevOptionsLength, setPrevOptionsLength] = useState<number>(0);
    const { getUsernames } = usePlayersAgents()
    const { updateQueryParameter } = useQuery()

    useEffect(() => {
        const shouldFetch = () => {
            if (inputValue.length < 3) {
                console.log('too short')
                return false;
            }
            if (inputValue.length < prevValue.length) {
                console.log('new length shorter than old')
                return true;
            }
            if (!inputValue.startsWith(prevValue)) {
                console.log('new value does not start with old value')
                return true;
            }
            if (prevOptionsLength > 100 || prevOptionsLength === 0) {
                if (prevOptionsLength > 100) console.log('prev length > 100')
                if (prevOptionsLength === 0) console.log('prev length = 0')
                return true;
            }
        };

        if (inputValue !== '' && inputValue !== prevValue && shouldFetch()) {
            getUsernames(inputValue, setOptions, setLoading);
            setPrevValue(inputValue);
            setPrevOptionsLength(options.length);
        }
    }, [inputValue, prevValue, prevOptionsLength, options.length, getUsernames]);

    const handleSearch = () => {
        onUsernameChanged(inputValue)
        if (pullParamsToAddressBar) {
            updateQueryParameter('username', inputValue.trim() !== '' ? inputValue : null)
        }
    }

    const handleOnKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    return <Autocomplete
        sx={{
            height: '100%',
            '& .MuiOutlinedInput-root': {
                py: '0 !important',
            },
            '& .MuiTextField-root, .MuiOutlinedInput-root': {
                height: '100%',
            }
        }}
        disableClearable={true}
        freeSolo
        fullWidth
        options={inputValue.length < 3 ? [] : options}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        loading={loading}
        onKeyDown={handleOnKeyDown}
        renderInput={(params) => (
            <TextField
                {...params}
                variant="outlined"
                sx={{minWidth: '100px'}}
                size={'small'}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: startIconMode ? (
                        <InputAdornment position="start">
                            <PersonSearchIcon sx={{color: 'primary.main'}}/>
                        </InputAdornment>
                    ) : undefined,
                    endAdornment: (
                        <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                            {onCancel !== undefined && <IconButton edge="end" onClick={onCancel}>
                                <BlockIcon fontSize={'small'}/>
                            </IconButton>}
                            <IconButton edge="end" onClick={handleSearch}>
                                <SearchIcon  sx={{color: 'primary.main'}}/>
                            </IconButton>
                        </>
                    ),
                }}
            />
        )}
    />
}

export const UsernameFilter: FC<UsernameFilterProps> = (props) => {
    const [ editMode, setEditMode ] = useState<boolean>(false)
    const {languagePack: {pack: {usernameFilter: lang}}} = useLanguage()
    const { updateQueryParameter } = useQuery()

    return <Box display={'flex'} width={'100%'} columnGap={'4px'} minWidth={'200px'}>
        {!editMode ? <AddUsernameButton holdData={Boolean(props.username)} label={props.username ?? lang.usernameFilterLabel} onClick={() => {
                setEditMode(true)
            }}/> :
            <UsernameFilterBase {...props} onCancel={() => setEditMode(false)} onUsernameChanged={(username) => {
                setEditMode(false)
            props.onUsernameChanged?.(username)
        }}/>}

        {props.username !== null && !editMode && <IconButton color="warning" onClick={() => {
            props.onUsernameChanged?.(null)
            if (props.pullParamsToAddressBar) {
                updateQueryParameter('username', null)
            }
        }}>
            <HighlightOffIcon fontSize={'small'}/>
        </IconButton>}
    </Box>
}

interface AddUsernameButtonProps {
    label: string
    onClick?: () => void
    holdData: boolean
}

const AddUsernameButton:FC<AddUsernameButtonProps> = ({label, holdData, onClick = () => {}}) => {
    return <Button sx={{textWrap: 'nowrap'}} color={holdData ? 'secondary' : 'primary'} size={'medium'} variant={'outlined'} onClick={onClick} fullWidth>
        <Box display={'flex'} alignItems={'center'} columnGap={'4px'}>
            {label}
            <PersonSearchIcon/>
        </Box>
    </Button>
}

export const UsernameInlineFilter: FC<UsernameFilterProps> = (props) => {
    return <UsernameFilterBase {...props} startIconMode/>
}