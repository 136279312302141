import { FC } from "react";
import {User, UserRole} from "../../../features/user/user";
import {Box, Chip, Paper, Skeleton, Stack, Typography} from "@mui/material";
import { UserRoleChip } from "../../manage_users/UserRoleChip";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import {stringToUserRole} from "../../../features/user/userFuncs";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

interface UserCardProps {
    user?: User
    loading?: boolean
}

export const UserCard: FC<UserCardProps> = ({user, loading = false}) => {
    const userRole = user ? stringToUserRole(user.user_role) : null

    return <Paper sx={{flex: 1}}>
        <Stack
            direction={'column'}
            padding={'10px'}
            justifyContent={'space-between'}
        >
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'12px'} variant={'caption'}>{!loading ? user?.user_name : <Skeleton width={'60px'}/>}</Typography>
                {!loading ? <UserRoleChip userRole={user?.user_role ?? 'none'}/> : <Chip  size={'small'} label={<Skeleton width={'40px'}/>}/>}
            </Box>
            <Stack alignItems={'center'}>
                {/*<Typography variant={'caption'}>{!loading ? `${lang.userCardBalanceLabel}:` : <Skeleton width={'60px'}/>}</Typography>*/}
                {!loading ? (user?.user_balance !== undefined && userRole !== null ? (userRole !== UserRole.PLATFORM && userRole !== UserRole.OWNER) ? convertCentsToCurrencyString(user.user_balance) : <AllInclusiveIcon/> : '---') : <Skeleton width={'70px'}/>}
            </Stack>
        </Stack>
    </Paper>
}