import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ModalInterface } from "./model";

interface ModalWrapperProps<T> {
    component: React.ComponentType<T>;
    componentProps: T;
}

export const ModalWrapper  = <T extends ModalInterface>({ component: Component, componentProps }: ModalWrapperProps<T>) => {
    const { dialogTitle } = componentProps
    const { close = () => {} } = componentProps
    const [ opened, setOpened ] = useState(true)
    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={ 'xs' }
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        <DialogContent>
            <Component {...componentProps} onDone={pendingClose}/>
        </DialogContent>
    </Dialog>
}