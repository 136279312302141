import React, { FC } from "react";
import { ProvidersTable } from "../../components/providers/ProvidersTable";
import { ContentPage } from "../../components/layout/content_components/ContentPage";
import { MainContentZone } from "../../components/layout/content_components/MainContentZone";
import { useProviders } from "../../features/providers/useProviders";
import { ContentLoader } from "../../components/layout/content_components/ContentLoader";

export const ProvidersPage: FC = () => {
    const { isProvidersLoading } = useProviders()

    return <ContentPage>
        <MainContentZone>
            <ContentLoader isLoading={isProvidersLoading}/>
            <ProvidersTable/>
        </MainContentZone>
    </ContentPage>
}