import React, {FC, useState} from "react";
import {TableHeightWrapper} from "../../layout/content_components/TableHeightWrapper";
import {Alert, Box, Stack} from "@mui/material";
import {GeneralInfoCard} from "../../commons/GeneralInfoCard";
import {convertCentsToCurrencyString} from "../../../features/common_funcs";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {TransfersToMyselfInfo} from "../useTransfersToMyselfInfo";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useUserTransfers} from "../../../features/user_transfers/useUserTransfers";
import {MyselfTransfersCard} from "../MyselfTransfersCard";

export const TransfersToMyselfList: FC<{info: TransfersToMyselfInfo}> = ({info}) => {
    const { languagePack: {pack: {agentTransfersTable: lang, playersAgents: playersAgentsLang}}} = useLanguage()
    const [height, setHeight] = useState<number>(0)
    const { xxSmallScreen: xs } = useCommonMediaQueries()
    const { isMoneyTransfersToMyselfLoading, transfersToMyself } = useUserTransfers()

    return <TableHeightWrapper onHeightChanged={setHeight} currentHeight={height}>
        <Box height={height} sx={{
            backgroundColor: 'rgba(0,0,0,0.05)',
            overflowY: 'auto'
        }}>
            <Stack
                justifyContent={'space-between'}
                direction={'row'}
                gap={'5px'}
                padding={'5px'}
                flexWrap={'wrap'}
            >
                <Stack padding={'8px'} gap={'8px'} width={'100%'}>
                    <Box flex={1}>
                        <GeneralInfoCard title={playersAgentsLang.totalDeposits}
                                         value={info.totalDeposits !== undefined ? convertCentsToCurrencyString(info.totalDeposits, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={playersAgentsLang.totalWithdrawals}
                                         value={info.totalWithdraws !== undefined ? convertCentsToCurrencyString(info.totalWithdraws, true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                    <Box flex={1}>
                        <GeneralInfoCard title={playersAgentsLang.netDeposit}
                                         value={info.netDeposit !== undefined ? convertCentsToCurrencyString((info.netDeposit), true) : undefined}
                                         suffix={'ARS'}/>
                    </Box>
                </Stack>
                {
                    transfersToMyself !== null && !isMoneyTransfersToMyselfLoading && transfersToMyself.map(
                        transfer => <Box
                            width={xs ? '100%' : 'calc((100% - 10px) / 2)'}
                            key={`userID-${transfer.transfer_id}`}
                        >
                            <MyselfTransfersCard transfer={transfer}/>
                        </Box>
                    )
                }
                {  transfersToMyself !== null && transfersToMyself.length === 0 &&
                    <Alert variant="outlined" severity="warning" sx={{width: '100%'}}>
                        {lang.transfersNotFoundMessage}
                    </Alert>}
            </Stack>
        </Box>
    </TableHeightWrapper>
}