import React, { FC, useState } from "react";
import { Button, CircularProgress, Dialog, DialogContent, TextField } from "@mui/material";
import { useUser } from "../../features/user/useUser";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../features/localisation/useLanguage";

export const LoginForm: FC = () => {
    const { logIn, isLogInLoading } = useUser()
    const [ username, setUsername ] = useState<string>('')
    const [ password, setPassword ] = useState<string>('')
    const { xxSmallScreen } = useCommonMediaQueries()
    const { languagePack: {pack: {login: lang}}} = useLanguage()
    const [ loginDialogOpened, setLoginDialogOpened ] = useState<boolean>(false)

    const usernameFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
    }

    const passwordFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const submitDisabled = (): boolean => username !== '' && password !== '' && isLogInLoading

    return xxSmallScreen ? <>
            <Button variant={'contained'} size={'small'} onClick={() => setLoginDialogOpened(true)}>{lang.logInBTN}</Button>

            <Dialog open={loginDialogOpened} onClose={() => setLoginDialogOpened(false)} fullWidth>
                <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <TextField value={username} onChange={usernameFieldHandler} size={'small'} label={lang.usernameLabel}
                               variant="outlined"/>
                    <TextField value={password} onChange={passwordFieldHandler} type={'password'} size={'small'}
                               label={lang.passwordLabel} variant="outlined"/>
                    <Button endIcon={isLogInLoading ? <CircularProgress size={16}/> : <></>}
                            onClick={() => logIn(username, password)} disabled={submitDisabled()} variant={'contained'}
                            size={'small'}>{lang.logInBTN}</Button>
                </DialogContent>
            </Dialog>
        </>
        : <>
            <TextField value={username} onChange={usernameFieldHandler} size={'small'} label={lang.usernameLabel}
                       variant="outlined"/>
            <TextField value={password} onChange={passwordFieldHandler} type={'password'} size={'small'}
                       label={lang.passwordLabel} variant="outlined"/>
            <Button endIcon={isLogInLoading ? <CircularProgress size={16}/> : <></>}
                    onClick={() => logIn(username, password)} disabled={submitDisabled()} variant={'contained'}
                    size={'small'}>{lang.logInBTN}</Button>
        </>
}