export enum GameHistorySort {
    Username = 'user_name',
    Provider = 'sectionid',
    Game = 'game_name',
    BetAmount = 'withdraw_sum',
    WinAmount = 'deposit_sum',
    DateTime = 'time'
}

export const stringToGameHistorySort = (stringGameHistorySort: string): GameHistorySort => {
    switch (stringGameHistorySort) {
        case 'user_name':
            return GameHistorySort.Username
        case 'sectionid':
            return GameHistorySort.Provider
        case 'game_name':
            return GameHistorySort.Game
        case 'withdraw_sum':
            return GameHistorySort.BetAmount
        case 'deposit_sum':
            return GameHistorySort.WinAmount
        case 'time':
        default:
            return GameHistorySort.DateTime
    }
}