import React, { FC } from "react";
import { GameHistoryTable } from "../../components/game_history_table/GameHistoryTable";
import { useGameHistory } from "../../features/game_history/useGameHistory";
import { ContentPage } from "../../components/layout/content_components/ContentPage";
import { FilterPanel, FilterPanelSide } from "../../components/layout/content_components/FilterPanel";
import { PaginatorRow } from "../../components/layout/content_components/PaginatorRow";
import { MainContentZone } from "../../components/layout/content_components/MainContentZone";
import { ContentLoader } from "../../components/layout/content_components/ContentLoader";
import { useGameHistoryWatcher } from "../../features/game_history/useGameHistoryWatcher";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { GameHistoryList } from "../../components/game_history_table/game_history_list/GameHistoryList";
import {UsernameFilter} from "../../components/commons/username_filter_v2/UsernameFilter";
import {TimeFilter} from "../../components-v2/commons/time_filter/TimeFilter";

export const GameHistoryPage: FC = () => {
    // const { languagePack: {pack: { commonSortingNames: lanSorting}}} = useLanguage()
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries()
    useGameHistoryWatcher()
    const { totalPages, currentPage, pageSize, filter, isGameHistoryLoading } = useGameHistory()

    // const sortingList = [
    //     { title: lanSorting.usernameAsc, orderBy: GameHistorySort.Username, sortDirection: "ASC" },
    //     { title: lanSorting.usernameDesc, orderBy: GameHistorySort.Username, sortDirection: "DESC" },
    //     { title: lanSorting.providerAsc, orderBy: GameHistorySort.Provider, sortDirection: "ASC" },
    //     { title: lanSorting.providerDesc, orderBy: GameHistorySort.Provider, sortDirection: "DESC" },
    //     { title: lanSorting.gameAsc, orderBy: GameHistorySort.Game, sortDirection: "ASC" },
    //     { title: lanSorting.gameDesc, orderBy: GameHistorySort.Game, sortDirection: "DESC" },
    //     { title: lanSorting.betAsc, orderBy: GameHistorySort.BetAmount, sortDirection: "ASC" },
    //     { title: lanSorting.betDesc, orderBy: GameHistorySort.BetAmount, sortDirection: "DESC" },
    //     { title: lanSorting.winAsc, orderBy: GameHistorySort.WinAmount, sortDirection: "ASC" },
    //     { title: lanSorting.winDesc, orderBy: GameHistorySort.WinAmount, sortDirection: "DESC" },
    //     { title: lanSorting.dateAsc, orderBy: GameHistorySort.DateTime, sortDirection: "ASC" },
    //     { title: lanSorting.dateDesc, orderBy: GameHistorySort.DateTime, sortDirection: "DESC" },
    // ]

    return <ContentPage>
        <FilterPanel
            // currentSortDirection={filter.sortDirection ?? undefined}
            // currentOrderBy={filter.orderBy ?? undefined}
            // sortingList={sortingList}
        >
            <FilterPanelSide>
                <UsernameFilter username={filter.username} pullParamsToAddressBar/>
                <TimeFilter
                    fromTime={filter.fromTime}
                    toTime={filter.toTime}
                    pullParamsToAddressBar/>
            </FilterPanelSide>
        </FilterPanel>
        <MainContentZone withAgentsTree>
            <ContentLoader isLoading={isGameHistoryLoading}/>
            {smallScreen ? <GameHistoryList/>  : <GameHistoryTable/>}
            {!xxSmallScreen &&
                <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
            }
        </MainContentZone>
    </ContentPage>
}