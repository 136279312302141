import { Provider } from "./models/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderAction } from "../common_models";
import { ProviderLoader } from "./models/loaders";
import { ProvidersState } from "./models/state";

const initialState: ProvidersState = {
    providers: null,
    isProvidersLoading: false,
    isProviderContractorUpdateLoading: false
}

export const ProviderSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {
        /** Clears providers after Provider's page is unmounted */
        clearProvidersSlice: (state) => {
            state.providers = null
            state.isProvidersLoading = false
            state.isProviderContractorUpdateLoading = false
        },
        /** Set Providers list to state */
        setProviders: (state, action: PayloadAction<Provider[]>) => {
            state.providers = action.payload
        },
        /** Set Providers loading state to state */
        setProvidersLoading: (state, action: PayloadAction<LoaderAction<ProviderLoader>>) => {
            switch (action.payload.loader) {
                case ProviderLoader.isProvidersLoading:
                    state.isProvidersLoading = action.payload.isLoading
                    break
                case ProviderLoader.isProviderContractorUpdateLoading:
                    state.isProviderContractorUpdateLoading = action.payload.isLoading
                    break
            }
        },
        /** Update Provider in state */
        updateProviderContractorID: (state, action: PayloadAction<{providerID: number, contractorID: number}>) => {
            if (state.providers) {
                state.providers = state.providers.map(provider => {
                    if (provider.id === action.payload.providerID) {
                        provider.current_contractor_id = action.payload.contractorID
                    }
                    return provider
                })
            }
        }
    }
})

export const {
    clearProvidersSlice,
    setProviders,
    setProvidersLoading,
    updateProviderContractorID
} = ProviderSlice.actions