export enum SortingPlayerAgent {
    UserID = 'user_id',
    Username = 'user_name',
    Active = 'banned',
    Firstname = 'user_firstname',
    Lastname = 'user_lastname',
    Balance = 'user_balance',
    Currency = 'user_currency',
    Role = 'user_role',
    Email = 'user_email',
    Phone = 'user_phone'
}

export const stringToSortingPlayerAgent = (stringSortingPlayerAgent: string): SortingPlayerAgent => {
    switch (stringSortingPlayerAgent) {
        case 'user_id':
            return SortingPlayerAgent.UserID
        case 'banned':
            return SortingPlayerAgent.Active
        case 'user_firstname':
            return SortingPlayerAgent.Firstname
        case 'user_lastname':
            return SortingPlayerAgent.Lastname
        case 'user_balance':
            return SortingPlayerAgent.Balance
        case 'user_currency':
            return SortingPlayerAgent.Currency
        case 'user_role':
            return SortingPlayerAgent.Role
        case 'user_email':
            return SortingPlayerAgent.Email
        case 'user_phone':
            return SortingPlayerAgent.Phone
        case 'user_name':
        default:
            return SortingPlayerAgent.Username
    }
}