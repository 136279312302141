// Provider's contractor interface
export interface Contractor {
    contractor_id: number
    contractor_name: string
    comission_rate: number
}

// Game provider interface
export interface Provider {
    id: number
    name: string
    title: string
    logo: string
    current_contractor_id: number
    contractor: Contractor[]
}

export enum ProviderType {
    Slots = 'slots'
}