import {FC, useEffect, useRef} from "react";
import {WidgetProps} from "./Widget.interface";
import {WidgetView} from "../../../themes/theme_connector";

export const Widget: FC<WidgetProps> = (props) => {
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            if (entries[0].contentRect) {
                props.setWidgetWidth?.(entries[0].contentRect.width);
            }
        });

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, [props]);

    return <WidgetView {...props} ref={componentRef}/>
}