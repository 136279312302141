import React, { FC, ReactNode } from "react";
import { useUser } from "../../features/user/useUser";
import { MustBeLogged } from "./MustBeLogged";

interface AuthorizedZoneProps {
    children?: ReactNode
}

export const AuthorizedZone: FC<AuthorizedZoneProps> = ({children}) => {
    const { token, user } = useUser()

    return token && user ? <>{children}</> : <MustBeLogged/>
}