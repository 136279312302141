import React, {FC, useState} from "react";
import {TableHeightWrapper} from "../layout/content_components/TableHeightWrapper";
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {convertCentsToCurrencyString} from "../../features/common_funcs";
import {useLanguage} from "../../features/localisation/useLanguage";
import {useUserTransfers} from "../../features/user_transfers/useUserTransfers";

export const TransfersToMyselfTable: FC = () => {
    const [height, setHeight] = useState<number>(0)
    const { languagePack: {pack: {agentTransfersTable: lang}}} = useLanguage()
    const { isMoneyTransfersToMyselfLoading, transfersToMyself } = useUserTransfers()

    return <TableHeightWrapper currentHeight={height} onHeightChanged={setHeight}>
        <TableContainer sx={{
            scrollbarWidth: 'thin',
            maxHeight: `${height}px`,
            '& .MuiTableCell-head:not(:last-child)': {
                borderRight: 'rgba(0,0,0,0.05) solid 1px',
            },
        }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{lang.timeHeader}</TableCell>
                        <TableCell align="left">{lang.typeHeader}</TableCell>
                        <TableCell align="left">{lang.amountHeader}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transfersToMyself !== null && transfersToMyself.map(transfer => <TableRow>
                        <TableCell align="left">{transfer.request_time}</TableCell>
                        <TableCell align="left">
                            <Typography fontWeight={500} textTransform={'uppercase'} color={transfer.transfer_type === 'deposit' ? 'primary' : 'secondary'}>
                                {transfer.transfer_type}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">{convertCentsToCurrencyString(transfer.transfer_amount, true)}</TableCell>
                    </TableRow>)}

                    { transfersToMyself === null && isMoneyTransfersToMyselfLoading &&
                        <TableRow>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                            <TableCell><Skeleton animation="wave"/></TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </TableHeightWrapper>
}