import { BonusLoader, BonusState, Bonus } from "./bonusModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderAction } from "../common_models";

const initialState: BonusState = {
    bonuses: null,
    isBonusCreatingLoading: false,
    isBonusesLoading: false,
    isBonusDeletingLoading: false
}

export const BonusSlice = createSlice({
    name: 'bonus',
    initialState,
    reducers: {
        clearBonusSlice: (state) => {
            state.bonuses = null
            state.isBonusCreatingLoading = false
            state.isBonusesLoading = false
            state.isBonusDeletingLoading = false
        },
        setBonusLoader: (state, action: PayloadAction<LoaderAction<BonusLoader>>) => {
            switch (action.payload.loader) {
                case BonusLoader.isBonusCreatingLoading:
                    state.isBonusCreatingLoading = action.payload.isLoading
                    break
                case BonusLoader.isBonusesLoading:
                    state.isBonusesLoading = action.payload.isLoading
                    break
                case BonusLoader.isBonusDeletingLoading:
                    state.isBonusDeletingLoading = action.payload.isLoading
                    break
            }
        },
        setBonuses: (state, action: PayloadAction<Bonus[]>) => {
            state.bonuses = action.payload
        },
        updateBonus: (state, action: PayloadAction<Bonus>) => {
            if (state.bonuses !== null) {
                const index = state.bonuses.findIndex(b => b.id === action.payload.id)
                if (index !== -1) {
                    state.bonuses = [
                        ...state.bonuses.slice(0, index),
                        action.payload,
                        ...state.bonuses.slice(index + 1)
                    ]
                }
            }
        },
        deleteBonus: (state, action: PayloadAction<number>) => {
            if (state.bonuses !== null) {
                state.bonuses = state.bonuses.filter(b => b.id !== action.payload)
            }
        }
    }
})

export const { clearBonusSlice, setBonusLoader, setBonuses, updateBonus, deleteBonus } = BonusSlice.actions