import { useLocation, useNavigate } from "react-router-dom";
import { iUseQuery } from "./models/hookInteface";
import { QueryParam } from "./models/models";

export const useQuery = (): iUseQuery => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const updateQueryParameter = (paramName: string, value: string | null) => {
        if (value === null) {
            searchParams.delete(paramName);
        } else {
            searchParams.set(paramName, value);
        }

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    const batchUpdateQueryParameters = (params: QueryParam[]) => {
        params.forEach(param => {
            if (param.value === null) {
                searchParams.delete(param.paramName);
            } else {
                searchParams.set(param.paramName, param.value);
            }
        })

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return {
        updateQueryParameter,
        batchUpdateQueryParameters
    }
}