import React, { FC } from "react";
import { useNetwinUsersWatcher } from "../../features/netwin/useNetwinUsersWatcher";
import { useNetwin } from "../../features/netwin/useNetwin";
import Tab from "@mui/material/Tab";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";
import { NetwinUsersTable } from "../../components/netwin/NetwinUsersTable";
import { NetwinAccess } from "../../features/netwin/models/access";
import { ContentPage } from "../../components/layout/content_components/ContentPage";
import { FilterPanel, FilterPanelSide } from "../../components/layout/content_components/FilterPanel";
import { MainContentZone } from "../../components/layout/content_components/MainContentZone";
import { ContentLoader } from "../../components/layout/content_components/ContentLoader";
import { Tabs} from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { NetwinAgentsList } from "../../components/netwin/netwin_agents_list/NetwinAgentsList";
import { UserRoleFilter } from "../../components/commons/user_role_filter/UserRoleFilter";
import { NetwinUserSort } from "../../features/netwin/models/sorting";
import {UsernameFilter} from "../../components/commons/username_filter_v2/UsernameFilter";
import {TimeFilter} from "../../components-v2/commons/time_filter/TimeFilter";

export const NetwinUserPage: FC = () => {
    useNetwinUsersWatcher()
    const { languagePack: {pack: { playersAgents: playersAgentsLang, commonSortingNames: lanSorting}}} = useLanguage()
    const { usersFilter, isNetwinUsersLoading } = useNetwin()
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries()

    const { updateQueryParameter } = useQuery()

    const handleTabsChange = (event: React.SyntheticEvent, newValue: string | null) => {
        updateQueryParameter("userRole", newValue)
    }

    const sortingList = [
        { title: lanSorting.usernameAsc, orderBy: NetwinUserSort.agent_name, sortDirection: "ASC" },
        { title: lanSorting.usernameDesc, orderBy: NetwinUserSort.agent_name, sortDirection: "DESC" },
        { title: lanSorting.betAsc, orderBy: NetwinUserSort.user_losses, sortDirection: "ASC" },
        { title: lanSorting.betDesc, orderBy: NetwinUserSort.user_losses, sortDirection: "DESC" },
        { title: lanSorting.winAsc, orderBy: NetwinUserSort.user_wins, sortDirection: "ASC" },
        { title: lanSorting.winDesc, orderBy: NetwinUserSort.user_wins, sortDirection: "DESC" },
        { title: lanSorting.netwinAsc, orderBy: NetwinUserSort.ggr, sortDirection: "ASC" },
        { title: lanSorting.netwinDesc, orderBy: NetwinUserSort.ggr, sortDirection: "DESC" },
        { title: lanSorting.percentAsc, orderBy: NetwinUserSort.agent_commission, sortDirection: "ASC" },
        { title: lanSorting.percentDesc, orderBy: NetwinUserSort.agent_commission, sortDirection: "DESC" },
    ]

    return <ContentPage accessUserRoles={NetwinAccess}>
        <FilterPanel
            currentSortDirection={usersFilter.sortDirection ?? undefined}
            currentOrderBy={usersFilter.orderBy ?? undefined}
            sortingList={sortingList}
        >
            <FilterPanelSide>
                <UsernameFilter username={usersFilter.username} pullParamsToAddressBar/>
                {xxSmallScreen && <UserRoleFilter userRole={usersFilter.userRole} pullParamsToAddressBar/>}
                <TimeFilter
                    fromTime={usersFilter.fromTime}
                    toTime={usersFilter.toTime}
                    pullParamsToAddressBar/>
            </FilterPanelSide>
        </FilterPanel>
        <MainContentZone withAgentsTree>
            {!xxSmallScreen && <Tabs
                value={usersFilter.userRole}
                onChange={handleTabsChange}
            >
                <Tab label={playersAgentsLang.allUsersTab} value={null}/>
                <Tab label={playersAgentsLang.playersTab} value={"player"}/>
                <Tab label={playersAgentsLang.agentsTab} value={"agent"}/>
            </Tabs>}
            <ContentLoader isLoading={isNetwinUsersLoading}/>
            {smallScreen ? <NetwinAgentsList/> : <NetwinUsersTable/>}
        </MainContentZone>
    </ContentPage>
}