import { TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { convertStringCurrencyToCents } from "../../../features/common_funcs";
import {ThousandsFormatCustom} from "../../players_n_agents/deposit&withdraw/ThousandFormatCustom";

interface MoneyInputProps{
    onValueChanged: (value: string) => void
    value: string
    maxAmount?: string
    currency?: string
    label?: ReactNode
    helperText?: string
    hasError?: (has: boolean) => void
    disabled?: boolean
    required?: boolean
    error?: boolean
}

enum ValueError {
    ExceededAmount,
    MoreThanOneDot,
    MoreThanTwoDecimals
}

export const MoneyInput:FC<MoneyInputProps> = ({
                                                   onValueChanged: setValue = () => {},
                                                   value = '',
                                                   maxAmount,
                                                   currency,
                                                   label,
                                                   helperText,
                                                   hasError = () => {},
                                                   disabled= false,
                                                   required = false,
                                                   error: errorFromProps
}) => {
    const { languagePack: {pack: {moneyInput: lang}}} = useLanguage()
    const [ suffix, setSuffix ] = useState<string>('')
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const [ error, setError ] = useState<ValueError|null>(null)
    const prevMaxAmount = useRef<string|undefined>()



    useEffect(() => {
        if (initLoad) {
            setSuffix(getSuffix(value))
            setInitLoad(false)
        }
    }, [ initLoad, value ])

    useEffect(() => {
        const sfx = getSuffix(value)
        if (sfx !== suffix) setSuffix(sfx)
    }, [suffix, value])

    const inputHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = normalizeNumbersInput(value, event.target.value)
        if (newValue !== value) {
            // const sfx = getSuffix(newValue)
            // if (sfx !== suffix) setSuffix(sfx)
            setValue(newValue)
            const err = getError(newValue)
            if (err !== error) {
                hasError(err !== null)
                setError(err)
            }
        }
    }

    const getError = useCallback((value: string) : ValueError|null => {
        if (maxAmount) {
            if (!notExceedMaxAmount(value, maxAmount)) {
                return ValueError.ExceededAmount
            }
        }
        if (moreThanOneDot(value)) {
            return ValueError.MoreThanOneDot
        }
        if (moreThanTwoDecimals(value)) {
            return ValueError.MoreThanTwoDecimals
        }
        return null
    }, [maxAmount])

    const getHelperText = useCallback((): string | undefined => {
        let amount = maxAmount ?? maxAmount
        if (amount && currency) amount = `${amount} ${currency}`
        let message: string|undefined
        switch (error) {
            case ValueError.ExceededAmount:
                if (maxAmount) {
                    message = `${lang.exceedAmountError} ${lang.maxAmountMessage} ${amount}`
                }
                break
            case ValueError.MoreThanOneDot:
                message = lang.moreThanOneDotError
                break
            case ValueError.MoreThanTwoDecimals:
                message = lang.moreThanTwoDecimals
                break
            default:
                if (maxAmount) {
                    message = `${helperText ? helperText + ' ' : ''}${lang.maxAmountMessage} ${amount}`
                } else {
                    message = helperText
                }
        }
        return message
    }, [currency, error, lang, maxAmount, helperText])


    useEffect(() => {
        if (prevMaxAmount.current !== maxAmount) {
            const err = getError(value)
            if (err !== error) {
                hasError(err !== null)
                setError(err)
            }
            prevMaxAmount.current = maxAmount
        }

    }, [error, getError, hasError, maxAmount, value])

    return <TextField
        disabled={disabled}
        label={label}
        size={'small'}
        fullWidth
        value={value}
        onChange={inputHandler}
        inputProps={{
            min: 0,
            style: { textAlign: 'right' }
        }}
        InputProps={{
            inputComponent: ThousandsFormatCustom as any,
            endAdornment: (
                <Typography color={'gray'}>
                    {suffix}
                </Typography>
            ),
        }}
        helperText={getHelperText()}
        error={error !== null || errorFromProps}
        required={required}
    />
}

const isValidInput = (inputString: string): boolean => {
    const regex = /^$|^[0-9.,]+$/
    return regex.test(inputString)
}

export const normalizeNumbersInput = (oldValue: string, newValue: string) : string => {
    if (isValidInput(newValue)) return newValue.replace(/,/g, '.')
    return oldValue
}

const getSuffix = (value: string) : string => {
    if (value === '') return '0.00'
    if(value.includes('.')) {
        const parts = value.split('.')
        const suffixLength = parts[parts.length - 1].length
        if (suffixLength === 0) return '00'
        else if (suffixLength === 1) return '0'
        else return ''
    }
    return '.00'
}

const notExceedMaxAmount = (value: string, maxAmount: string): boolean => {
    const valueCents = convertStringCurrencyToCents(value)
    const maxAmountCents = convertStringCurrencyToCents(maxAmount)
    return maxAmountCents >= valueCents
}

export const moreThanOneDot = (value: string) : boolean => {
    return (value.split('.').length - 1) >= 2
}

const moreThanTwoDecimals = (value: string) : boolean => {
    if (value.includes('.')) {
        const parts = value.split('.')
        const suffixLength = parts[parts.length - 1].length
        if (suffixLength > 2) return true
    }
    return false
}