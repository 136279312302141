import { NetwinState } from "./models/state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NetwinRoyalty, NetwinUser, RoyaltySummary, UsersSummary } from "./models/models";
import { LoaderAction } from "../common_models";
import { NetwinLoaders } from "./models/loaders";
import { NetwinRoyaltyFilter, NetwinUsersFilter } from "./models/filter";

export const initialState: NetwinState = {
    users: null,
    usersFilter: {
        fromTime: null,
        toTime: null,
        userRole: null,
        username: null,
        orderBy: null,
        sortDirection: null
    },
    selectedAgentCommission: null,
    usersSummary: {
        userTotalBets: null,
        userTotalWins: null,
        userTotalGGR: null,
        userTotalCommission: null
    },
    isNetwinUsersLoading: false,

    // Netwin Royalty info
    netwinRoyalty: null,
    royaltyFilter: {
        fromTime: null,
        toTime: null,
        username: null,
        orderBy: null,
        sortDirection: null
    },
    royaltySummary: {
        totalBets: null,
        totalWins: null,
        totalGGR: null,
        totalCommission: null
    },
    isNetwinRoyaltyLoading: false
}

export const NetwinSlice = createSlice({
    name: 'netwin',
    initialState,
    reducers: {
        resetNetwinUsers: (state) => {
            state.users = null
            state.usersFilter = {
                fromTime: null,
                toTime: null,
                userRole: null,
                username: null,
                orderBy: null,
                sortDirection: null
            }
            state.selectedAgentCommission = null
            state.usersSummary = {
                userTotalBets: null,
                userTotalWins: null,
                userTotalGGR: null,
                userTotalCommission: null
            }
            state.isNetwinUsersLoading = false
        },
        resetNetwinRoyalty: (state) => {
            state.netwinRoyalty = null
            state.royaltyFilter = {
                fromTime: null,
                toTime: null,
                username: null,
                orderBy: null,
                sortDirection: null
            }
            state.royaltySummary = {
                totalBets: null,
                totalWins: null,
                totalGGR: null,
                totalCommission: null
            }
            state.isNetwinRoyaltyLoading = false
        },
        setNetwinLoader: (state, action: PayloadAction<LoaderAction<NetwinLoaders>>) => {
            switch (action.payload.loader) {
                case NetwinLoaders.isNetwinUsersLoading:
                    state.isNetwinUsersLoading = action.payload.isLoading
                    break
                case NetwinLoaders.isNetwinRoyaltyLoading:
                    state.isNetwinRoyaltyLoading = action.payload.isLoading
                    break
            }
        },
        setNetwinUsers: (state, action: PayloadAction<NetwinUser[] | null>) => {
            state.users = action.payload
        },
        setUsersFilter: (state, action: PayloadAction<NetwinUsersFilter>) => {
            state.usersFilter = action.payload
        },
        setSelectedAgentCommission: (state, action: PayloadAction<number | null>) => {
            state.selectedAgentCommission = action.payload
        },
        setNetwinRoyaltyFilter: (state, action: PayloadAction<NetwinRoyaltyFilter>) => {
            state.royaltyFilter = action.payload
        },
        setNetwinRoyalty: (state, action: PayloadAction<NetwinRoyalty[]>) => {
            state.netwinRoyalty = action.payload
        },
        setNetwinUsersSummary: (state, action: PayloadAction<UsersSummary>) => {
            state.usersSummary = action.payload
        },
        setNetwinRoyaltySummary: (state, action: PayloadAction<RoyaltySummary>) => {
            state.royaltySummary = action.payload
        }
    }
})

export const {
    resetNetwinUsers,
    setNetwinUsers,
    setNetwinLoader,
    setUsersFilter,
    setSelectedAgentCommission,
    setNetwinUsersSummary,

    resetNetwinRoyalty,
    setNetwinRoyaltyFilter,
    setNetwinRoyalty,
    setNetwinRoyaltySummary
} = NetwinSlice.actions