import { AgentTransfersState } from "./models/state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AgentTransfer } from "./models/models";
import { AgentTransfersFilter } from "./models/filter";
import { LoaderAction } from "../common_models";
import { AgentsTransfersLoaders } from "./models/loaders";

export const initialState: AgentTransfersState = {
    agentTransfers: null,
    agentTransfersFilter: {
        fromTime: null,
        toTime: null,
        username: null,
        typeTransaction: null
    },
    agentPageSize: 30,
    agentCurrentPage: 1,
    agentTotalRecords: 0,
    agentTotalPages: 0,
    isAgentsTransfersLoading: false
}

export const AgentTransfersSlice = createSlice({
    name: 'agentTransfers',
    initialState,
    reducers: {
        resetAgentTransfers: (state) => {
            state.agentTransfers = null
            state.agentTransfersFilter = {
                fromTime: null,
                toTime: null,
                username: null,
                typeTransaction: null
            }
            state.agentPageSize = 30
            state.agentCurrentPage = 1
            state.agentTotalRecords = 0
            state.agentTotalPages = 0
            state.isAgentsTransfersLoading = false
        },
        setAgentTransfersLoader: (state, action: PayloadAction<LoaderAction<AgentsTransfersLoaders>>) => {
            switch (action.payload.loader) {
                case AgentsTransfersLoaders.isAgentsTransfersLoading:
                    state.isAgentsTransfersLoading = action.payload.isLoading
                    break
            }
        },
        setAgentTransfers: (state, action: PayloadAction<AgentTransfer[] | null>) => {
            state.agentTransfers = action.payload
        },
        setAgentTransfersFilter: (state, action: PayloadAction<AgentTransfersFilter>) => {
            state.agentTransfersFilter = action.payload
        },
        setAgentPageSize: (state, action: PayloadAction<number>) => {
            state.agentPageSize = action.payload
        },
        setCurrentAgentPage: (state, action: PayloadAction<number>) => {
            state.agentCurrentPage = action.payload
        },
        setTotalAgentTransfersRecords: (state, action: PayloadAction<number>) => {
            state.agentTotalRecords = action.payload
            state.agentTotalPages = Math.ceil(action.payload / state.agentPageSize)
        }
    }
})

export const {
    resetAgentTransfers,
    setAgentTransfers,
    setAgentTransfersLoader,
    setAgentTransfersFilter,
    setAgentPageSize,
    setCurrentAgentPage,
    setTotalAgentTransfersRecords
} = AgentTransfersSlice.actions