import React, { FC } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { AgentTransfer } from "../../../features/agent_transfers/models/models";
import { UserTransfer } from "../../../features/user_transfers/models/models";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

interface AgentsTransfersCardProps {
    transfer: AgentTransfer | UserTransfer
}

export const AgentsTransfersCard: FC<AgentsTransfersCardProps> = ({transfer}) => {
    const { languagePack: {pack: {agentTransfersTable: lang, balanceTooltip: balanceLang}}} = useLanguage()

    return <Card sx={{ py: '18px', px: '2vw' }}>
        <Stack gap={'8px'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flex={1}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.typeHeader}:
                    </Typography>
                    <Typography fontWeight={500} textTransform={'uppercase'} color={transfer.transfer_type === 'deposit' ? 'primary' : 'secondary'} textAlign={'left'}  lineHeight={0.8}>
                        {transfer.transfer_type}
                    </Typography>

                </Stack>

                <Stack flex={1} alignItems={'center'}>
                    {/*<Stack>*/}
                    {/*    <Typography fontWeight={300} fontSize={'10px'} textAlign={'center'}>*/}
                    {/*        {lang.amountHeader}:*/}
                    {/*    </Typography>*/}

                    {/*    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>*/}
                    {/*        {convertCentsToCurrencyString(transfer.transfer_amount, true)}*/}
                    {/*    </Typography>*/}
                    {/*</Stack>*/}
                </Stack>

                <Stack flex={1} alignItems={'end'}>
                    <Typography fontWeight={400} fontSize={'10px'}>
                        {transfer.time.split(' ')[0]}
                    </Typography>
                    <Typography fontWeight={600} fontSize={'16px'} lineHeight={0.8}>
                        {transfer.time.split(' ')[1].split(':').splice(0,2).join(':')}
                    </Typography>
                </Stack>
            </Stack>


            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={1}>
                <Stack flex={1}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.initiatorNameHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {transfer.initiator_user_name}
                    </Typography>
                </Stack>

                <Stack flex={1} alignItems={'end'}>
                    <Stack>
                        <Typography fontWeight={300} fontSize={'10px'} textAlign={'right'}>
                            {lang.parentNameHeader}:
                        </Typography>
                        <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8} textAlign={'right'}>
                            {transfer.origin_user_name}
                        </Typography>
                    </Stack>
                </Stack>
<br/>
                <Stack alignItems={'start'} width={'100%'}>
                    <Stack>
                        <Typography fontWeight={300} fontSize={'10px'}>
                            {lang.userNameHeader}:
                        </Typography>
                        <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                            {transfer.user_name}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>




            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flex={1}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {balanceLang.before}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {convertCentsToCurrencyString(transfer.user_balance_before, true)}
                    </Typography>
                </Stack>

                <Stack flex={1} alignItems={'center'}>
                    <Stack>
                        <Typography fontWeight={300} fontSize={'10px'} textAlign={'center'}>
                            {lang.amountHeader}:
                        </Typography>
                        <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8} textAlign={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <PlayArrowIcon fontSize={'small'} sx={{transform: `scaleX(${transfer.transfer_type === 'deposit' ? '1' : '-1'})`, color: transfer.transfer_type === 'deposit' ?  '#a9d933' : '#e86708'}}/>
                                {convertCentsToCurrencyString(transfer.transfer_amount, true)}
                            </Box>

                        </Typography>
                    </Stack>
                </Stack>

                <Stack flex={1} alignItems={'end'}>
                    <Stack>
                        <Typography fontWeight={300} fontSize={'10px'} textAlign={'right'}>
                            {balanceLang.after}:
                        </Typography>
                        <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8} textAlign={'right'}>
                            {convertCentsToCurrencyString(transfer.user_balance_after, true)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </Card>
}