import { User } from "../../user/user";
import { ManageUsersSort } from "./sorting";

export interface UsersTreeNode extends User {
    childUsersIDs?: number[]
    parentId?: number | null
    childUsersCount?: number
}

export enum ShowUsersMode {
    List = 'list',
    Tree = 'tree'
}

export interface ManageUsersFilter {
    fromTime: number | null
    toTime: number | null
    orderBy: ManageUsersSort | null
}

export interface BanUserSending {
    user_id: number
    banned: boolean
}

export interface CurrencyObj {
    currency_name: string
    exchange_rate: number
}