import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Tabs,
    TextField,
    useTheme
} from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useUser } from "../../features/user/useUser";
import { User } from "../../features/user/user";
import { validateEmail } from "../../features/common_funcs";
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { TabPanel } from "../../components/commons/TabPanel";
import s from './ProfilePage.module.css'
import Grid from '@mui/material/Unstable_Grid2';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { AuthorizedZone } from "../../components/commons/AuthorizedZone";

export const ProfilePage: FC = () => {
    const theme = useTheme();
    const { languagePack: { pack: { userProfileForm } } } = useLanguage()
    const { user, updateUserInfo, isUserInfoUpdateLoading, isChangePasswordLoading, changePassword } = useUser()

    const [ tab, setTab ] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setTab(index);
    };

    const [ emailError, setEmailError ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ newPasswordError, setNewPasswordError ] = useState('');
    const [ retypeNewPasswordError, setRetypeNewPasswordError ] = useState('');

    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');

    const [ currentPassword, setCurrentPassword ] = useState<string | undefined>();
    const [ newPassword, setNewPassword ] = useState<string | undefined>();
    const [ retypeNewPassword, setRetypeNewPassword ] = useState<string | undefined>();

    useEffect(() => {
        if (user && (user !== prevUser.current)) {
            setEmail(user.user_email || '');
            setPhone(user.user_phone || '');
            setFirstname(user.user_firstname || '');
            setLastname(user.user_lastname || '');

            prevUser.current = user;
        }
    }, [ user ]);

    const prevUser = useRef<User | null>(null);

    useEffect(() => {
        if ((currentPassword !== undefined && currentPassword.trim() === '') || (currentPassword === undefined && (retypeNewPassword || newPassword))) {
            setPasswordError(userProfileForm.emptyFieldWarningMessage)
        } else setPasswordError('')

        if ((newPassword !== undefined && newPassword.trim() === '') || (newPassword === undefined && (currentPassword || retypeNewPassword))) {
            setNewPasswordError(userProfileForm.emptyFieldWarningMessage)
        } else {
            setNewPasswordError('')
        }

        if (((newPassword || currentPassword) && retypeNewPassword === undefined) || (retypeNewPassword !== undefined && retypeNewPassword.trim() === '')) {
            setRetypeNewPasswordError(userProfileForm.emptyFieldWarningMessage)
        } else if (newPassword && retypeNewPassword && newPassword !== retypeNewPassword) {
            setRetypeNewPasswordError(userProfileForm.passNotMatchWarningMessage)
        } else {
            setRetypeNewPasswordError('')
        }
    }, [ currentPassword, newPassword, retypeNewPassword, userProfileForm.emptyFieldWarningMessage, userProfileForm.passNotMatchWarningMessage ])

    const handleSaveInfo = () => {
        if (user) {
            const updatedUser: User = {
                ...user,
                user_email: email,
                user_phone: phone,
                user_firstname: firstname,
                user_lastname: lastname
            }
            updateUserInfo(updatedUser)
        }
    };

    const handleUpdatePassword = () => {
        if (Boolean(currentPassword) && Boolean(newPassword) && Boolean(retypeNewPassword)) {
            changePassword(currentPassword as string, newPassword as string, () => {
                setCurrentPassword(undefined)
                setNewPassword(undefined)
                setRetypeNewPassword(undefined)
            })
        }
    };

    return <AuthorizedZone>
        <Card style={{ margin: 10 }}>
        <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="User profile tabs"
        >
            <Tab iconPosition="start" icon={<PersonIcon/>} label={userProfileForm.userProfileTabLabel} value={0}/>
            <Tab iconPosition="start" icon={<LockIcon/>} label={userProfileForm.passwordTabLabel} value={1}/>
        </Tabs>
        <CardContent>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={tab} index={0} dir={theme.direction}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isUserInfoUpdateLoading}
                                label={userProfileForm.emailFieldLabel}
                                type={'email'}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    const emailValidationResult = validateEmail(e.target.value, userProfileForm.emailIsRequiredMess, userProfileForm.notValidEmail);
                                    setEmailError(emailValidationResult);
                                }}
                                error={Boolean(emailError)}
                                helperText={emailError}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isUserInfoUpdateLoading}
                                label={userProfileForm.phoneFieldLabel}
                                type={'tel'}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isUserInfoUpdateLoading}
                                label={userProfileForm.firstnameFieldLabel}
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isUserInfoUpdateLoading}
                                label={userProfileForm.lastnameFieldLabel}
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isChangePasswordLoading}
                                label={userProfileForm.currentPassFieldLabel}
                                type={'password'}
                                value={Boolean(currentPassword) ? currentPassword : ''}
                                error={Boolean(passwordError)}
                                helperText={passwordError}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isChangePasswordLoading}
                                label={userProfileForm.newPassFieldLabel}
                                type={'password'}
                                value={Boolean(newPassword) ? newPassword : ''}
                                error={Boolean(newPasswordError)}
                                helperText={newPasswordError}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={isChangePasswordLoading}
                                label={userProfileForm.retypePassFieldLabel}
                                type={'password'}
                                value={Boolean(retypeNewPassword) ? retypeNewPassword : ''}
                                error={Boolean(retypeNewPasswordError)}
                                helperText={retypeNewPasswordError}
                                onChange={(e) => setRetypeNewPassword(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
            </SwipeableViews>
        </CardContent>
        <CardActions className={s.actions}>
            {(() => {
                switch (tab) {
                    case 0:
                        return <Button classes={{ contained: s.button, disabled: s.buttonDisabled }}
                                       endIcon={isUserInfoUpdateLoading ? <CircularProgress size={16}/> : <></>}
                                       disabled={Boolean(emailError) || isUserInfoUpdateLoading}
                                       variant={'contained'} onClick={handleSaveInfo}>
                            {userProfileForm.saveInfoBTN}
                        </Button>;
                    case 1:
                        return <Button classes={{ contained: s.button, disabled: s.buttonDisabled }}
                                       disabled={isChangePasswordLoading || (!Boolean(currentPassword) || !Boolean(newPassword) || !Boolean(retypeNewPassword) || Boolean(passwordError) || Boolean(newPasswordError) || Boolean(retypeNewPasswordError))}
                                       endIcon={isChangePasswordLoading ? <CircularProgress size={16}/> : <></>}
                                       variant={'contained'} onClick={handleUpdatePassword}>
                            {userProfileForm.updatePassBTN}
                        </Button>;
                    default:
                        return <></>;
                }
            })()}

        </CardActions>
        </Card>
    </AuthorizedZone>
}