import {FC, useState, MouseEvent} from "react";
import {TimeFilterProps} from "./TimeFilter.interface";
import {EditTimeButtonView, TimeFilterPickerView} from "../../../themes/theme_connector";
import {Popover, useTheme} from "@mui/material";
import {DateRangePicker, RangeKeyDict, Range, DefinedRangeProps} from "react-date-range";
import { enUS, ru, es, de } from 'date-fns/locale';
import '../../../themes/classical_theme/components/commons/time_filter/calendar_styles.css';
import '../../../themes/classical_theme/components/commons/time_filter/calendar_theme.css';
import {convertDateToUnixSeconds, getWeekStartDayFromTimeZone} from "../../../features/common_funcs";
import {useLanguage} from "../../../features/localisation/useLanguage";
import { isSameDay } from "date-fns";
import {definedsDateRanges} from "../../../features/common_funcs/date_time/dateRanges";
import {useQuery} from "../../../features/common_funcs/query_hook/useQuery";


export const TimeFilter: FC<TimeFilterProps> = ({fromTime, toTime, onTimeChanged= () => {}, pullParamsToAddressBar = false}) => {
    const theme = useTheme()
    const { batchUpdateQueryParameters } = useQuery()
    const { languagePack: {lang: localeName, pack: {timeFilter: {quickDates: quickDatesLang, date: dateLang}}},} = useLanguage()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [dateRange, setDateRange] = useState< Range>({
        startDate: fromTime ? new Date(fromTime * 1000) : new Date(),
        endDate: toTime ? new Date(toTime * 1000) : new Date(),
        key: 'selection',
        color: theme.palette.primary.main,
    });

    const getLocale = () : Locale => {
        switch (localeName) {
            case 'ru':
                return ru
            case 'es':
                return es
            case 'de':
                return de
            default:
                return enUS
        }
    }

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setDateRange({
            startDate: fromTime ? new Date(fromTime * 1000) : new Date(),
            endDate: toTime ? new Date(toTime * 1000) : new Date(),
            key: 'selection',
            color: theme.palette.primary.main,
        })
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleSelect = (ranges: RangeKeyDict) => {
        console.log(ranges);
        setDateRange(ranges.selection);
    }


    const getDateLabel = () => {
        const startDate = fromTime ? new Date(fromTime * 1000) : undefined
        const endDate = toTime ? new Date(toTime * 1000) : undefined

        const formatDate = (date: Date) =>
            `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')}`;

        if (startDate && endDate) {
            if (isSameDay(startDate, definedsDateRanges.startOfToday) && isSameDay(endDate, definedsDateRanges.endOfToday)) {
                return quickDatesLang.today
            } else if (isSameDay(startDate, definedsDateRanges.startOfYesterday) && isSameDay(endDate, definedsDateRanges.endOfYesterday)) {
                return quickDatesLang.yesterday
            } else if (isSameDay(startDate, definedsDateRanges.startOfWeek) && isSameDay(endDate, definedsDateRanges.endOfWeek)) {
                return quickDatesLang.thisWeek
            } else if (isSameDay(startDate, definedsDateRanges.startOfLastWeek) && isSameDay(endDate, definedsDateRanges.endOfLastWeek)) {
                return quickDatesLang.lastWeek
            } else if (isSameDay(startDate, definedsDateRanges.startOfMonth) && isSameDay(endDate, definedsDateRanges.endOfMonth)) {
                return quickDatesLang.thisMonth
            } else if (isSameDay(startDate, definedsDateRanges.startOfLastMonth) && isSameDay(endDate, definedsDateRanges.endOfLastMonth)) {
                return quickDatesLang.lastMonth
            } else {
                return `${formatDate(startDate)} - ${formatDate(endDate)}`
            }
        }
        return dateLang
    }

    const staticRanges = [
        {
            label: quickDatesLang.today,
            range: (props?: DefinedRangeProps) => {
                return {
                    startDate: definedsDateRanges.startOfToday,
                    endDate: definedsDateRanges.endOfToday,
                    ...props
                }
            },
            isSelected: (range: Range) => {
                return range.startDate && range.endDate ? isSameDay(range.startDate, new Date()) && isSameDay(range.endDate, new Date()) : false
            }
        },
        {
            label: quickDatesLang.yesterday,
            range: (props?: DefinedRangeProps) => {
                return {
                    startDate: definedsDateRanges.startOfYesterday,
                    endDate: definedsDateRanges.endOfYesterday,
                    ...props
                }
            },
            isSelected: (range: Range) => {
                return range.startDate && range.endDate ? isSameDay(range.startDate, definedsDateRanges.startOfYesterday) && isSameDay(range.endDate, definedsDateRanges.endOfYesterday) : false
            }
        },
        {
            label: quickDatesLang.thisWeek,
            range: (props?: DefinedRangeProps) => {
                return {
                    startDate: definedsDateRanges.startOfWeek,
                    endDate: definedsDateRanges.endOfWeek,
                    ...props
                }
            },
            isSelected: (range: Range) => {
                return range.startDate && range.endDate ? isSameDay(range.startDate, definedsDateRanges.startOfWeek) && isSameDay(range.endDate, definedsDateRanges.endOfWeek) : false
            }
        },
        {
            label: quickDatesLang.lastWeek,
            range: (props?: DefinedRangeProps) => {
                return {
                    startDate: definedsDateRanges.startOfLastWeek,
                    endDate: definedsDateRanges.endOfLastWeek,
                    ...props
                }
            },
            isSelected: (range: Range) => {
                return range.startDate && range.endDate ? isSameDay(range.startDate, definedsDateRanges.startOfLastWeek) && isSameDay(range.endDate, definedsDateRanges.endOfLastWeek) : false
            }
        },
        {
            label: quickDatesLang.thisMonth,
            range: (props?: DefinedRangeProps) => {
                return {
                    startDate: definedsDateRanges.startOfMonth,
                    endDate: definedsDateRanges.endOfMonth,
                    ...props
                }
            },
            isSelected: (range: Range) => {
                return range.startDate && range.endDate ? isSameDay(range.startDate, definedsDateRanges.startOfMonth) && isSameDay(range.endDate, definedsDateRanges.endOfMonth) : false
            }
        },
        {
            label: quickDatesLang.lastMonth,
            range: (props?: DefinedRangeProps) => {
                return {
                    startDate: definedsDateRanges.startOfLastMonth,
                    endDate: definedsDateRanges.endOfLastMonth,
                    ...props
                }
            },
            isSelected: (range: Range) => {
                return range.startDate && range.endDate ? isSameDay(range.startDate, definedsDateRanges.startOfLastMonth) && isSameDay(range.endDate, definedsDateRanges.endOfLastMonth) : false
            }
        }
    ]

    const handleApply = () => {
        const start = dateRange.startDate ? convertDateToUnixSeconds(dateRange.startDate) : null;

        let end = null;
        if (dateRange.endDate) {
            const endDate = new Date(dateRange.endDate);
            endDate.setHours(23, 59, 59); // Задаем 23:59:59
            end = convertDateToUnixSeconds(endDate);
        }

        onTimeChanged(start, end);

        if (pullParamsToAddressBar) {
            batchUpdateQueryParameters([
                { paramName: 'fromTime', value: start ? start.toString() : null },
                { paramName: 'toTime', value: end ? end.toString() : null },
            ]);
        }

        handleClose();
    };

    const handleReset = () => {
        onTimeChanged(null, null)
        if (pullParamsToAddressBar) {
            batchUpdateQueryParameters([
                {paramName: 'fromTime', value: null},
                {paramName: 'toTime', value: null}
            ])
        }
        handleClose()
    }

    return <>
        <EditTimeButtonView label={getDateLabel()} isActive={open} onClick={handleClick}/>

        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <TimeFilterPickerView
                onCancelClicked={handleClose}
                onApplyClicked={handleApply}
                onResetClicked={handleReset}
            >
                <DateRangePicker
                    staticRanges={staticRanges}
                    weekdayDisplayFormat={'EEEEEE'}
                    weekStartsOn={getWeekStartDayFromTimeZone()}
                    ranges={[dateRange]}
                    locale={getLocale()}
                    onChange={handleSelect}
                />
            </TimeFilterPickerView>
        </Popover>
    </>
}