import React, { useEffect, useState } from 'react';
import { BaseLayout } from "./app/components/layout/BaseLayout";
import { useLanguage } from "./app/features/localisation/useLanguage";
import { Route, Routes } from "react-router-dom";
import { DashboardPage } from "./app/pages/dashboard/DashboardPage";
import { ProfilePage } from "./app/pages/profile/ProfilePage";
import { useUser } from "./app/features/user/useUser";
import { GameHistoryPage } from "./app/pages/games_history/GameHistoryPage";
import { ProvidersPage } from "./app/pages/providers/ProvidersPage";
import { DebugPage } from "./app/pages/debug/DebugPage";
import { ManagePlayersAgentsPage } from "./app/pages_argentina/manage_players_agents/ManagePlayersAgentsPage";
import { NetwinUserPage } from "./app/pages_argentina/netwin_user/NetwinUserPage";
import { NetwinGeneralInfoPage } from "./app/pages_argentina/netwin_user/NetwinGeneralInfoPage";
import { UserTransfersPage } from "./app/pages_argentina/user_transfers/UserTransfersPage";
import {MediaqueryIndicator} from "./app/components/commons/dev/MediaqueryIndicator";
import {FinancesPage} from "./app/pages_argentina/FinancesPage";

function App() {
    const { switchLanguageFromStore } = useLanguage()
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const { refreshUserInfo } = useUser()

    useEffect(() => {
        if (initLoad) {
            setInitLoad(false)
            refreshUserInfo()
            switchLanguageFromStore()
        }
    }, [initLoad, refreshUserInfo, switchLanguageFromStore])

    return (
        <BaseLayout>
            <MediaqueryIndicator/>
            <Routes>
                <Route path="/" element={<DashboardPage/>}/>
                <Route path="/dashboard" element={<DashboardPage/>}/>
                <Route path="/users" element={<ManagePlayersAgentsPage/>}/>
                <Route path="/game_history" element={<GameHistoryPage/>}/>
                <Route path="/netwin/agents" element={<NetwinUserPage/>}/>
                <Route path="/netwin/general_info" element={<NetwinGeneralInfoPage/>}/>
                <Route path="/transfers/user" element={<UserTransfersPage/>}/>
                <Route path={'/finances'} element={<FinancesPage/>}/>
                <Route path="/providers" element={<ProvidersPage/>}/>
                <Route path="/profile" element={<ProfilePage/>}/>
                <Route path={"/debug"} element={<DebugPage/>}/>
            </Routes>
        </BaseLayout>
    );
}

export default App;
