import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentAccount, PaymentMethodTemplate } from "./PaymentSystemModels";

export interface PaymentSystemsState {
    userPaymentAccounts: PaymentAccount[] | null
    isPaymentAccountsLoading: boolean
    isPaymentAccountAddLoading: boolean
    isPaymentAccountEditLoading: boolean

    paymentMethods: PaymentMethodTemplate[] | null // Available payment methods for this platform
    unfilteredPaymentMethods: PaymentMethodTemplate[] | null// Available payment methods for this platform unfiltered
    isPaymentMethodsLoading: boolean

    isApprovePayoutLoading: boolean // When cashier sending approve payout request
    isApprovePaymentLoading: boolean // When cashier sending approve payment request

    depositRate: number | null // own deposit rate
    isSetDepositRateLoading: boolean // when user saving deposit rate

    parentDepositRate: number | null // Deposit rate of user parent
    depositPaymentAccounts: PaymentAccount[] | null // Payment accounts of user parent
    isDepositPaymentAccountsLoading: boolean // When depositPaymentAccounts is loading

    isPaymentRequestLoading: boolean // When user request payment
}

const initialState: PaymentSystemsState = {
    userPaymentAccounts: null,
    isPaymentAccountsLoading: false,
    isPaymentAccountAddLoading: false,
    isPaymentAccountEditLoading: false,

    paymentMethods: null,
    unfilteredPaymentMethods: null,
    isPaymentMethodsLoading: false,

    isApprovePayoutLoading: false,
    isApprovePaymentLoading: false,

    depositRate: null,
    isSetDepositRateLoading: false,

    parentDepositRate: null,
    depositPaymentAccounts: null,
    isDepositPaymentAccountsLoading: false,

    isPaymentRequestLoading:  false
}

export const PaymentSystemsSlice = createSlice({
    name: 'paymentSystems',
    initialState,
    reducers: {
        // Resetting current slice (e.g., when the component is closed).
        clearPaymentSystems: (state) => {
            state.userPaymentAccounts = null
            state.paymentMethods = null
            state.unfilteredPaymentMethods = null
            state.depositRate = null
        },
        // Set user payment accounts list
        setUserPaymentAccounts: (state, action: PayloadAction<PaymentAccount[]>) => {
            state.userPaymentAccounts = action.payload
            if (state.paymentMethods !== null) {
                // Filter methods which user not added yet
                const userPaymentAccIds = state.userPaymentAccounts.map(paymentAcc => paymentAcc.method_id)
                state.paymentMethods = state.paymentMethods.filter(method => !userPaymentAccIds.includes(method.id))
            }
        },
        // Add new payment account to the end of user payments list
        addUserPaymentAccountToList: (state, action: PayloadAction<PaymentAccount>) => {
            if (state.userPaymentAccounts) {
                state.userPaymentAccounts = [
                    ...state.userPaymentAccounts,
                    action.payload
                ]
            } else {
                state.userPaymentAccounts = [
                    action.payload
                ]
            }
            if (state.paymentMethods !== null) {
                // Filter methods which user not added yet
                const userPaymentAccIds = state.userPaymentAccounts.map(paymentAcc => paymentAcc.method_id)
                state.paymentMethods = state.paymentMethods.filter(method => !userPaymentAccIds.includes(method.id))
            }
        },
        // Change loading state isPaymentAccountAddLoading when adding new user payment account
        setPaymentAccountAddLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentAccountAddLoading = action.payload
        },
        // Update payment account in list after editing
        updateUserPaymentAccount: (state, action: PayloadAction<PaymentAccount>) => {
            if (state.userPaymentAccounts !== null) {
                const index = state.userPaymentAccounts.findIndex(up => up.id === action.payload.id)
                if (index !== -1) {
                    state.userPaymentAccounts = [
                        ...state.userPaymentAccounts.slice(0, index),
                        action.payload,
                        ...state.userPaymentAccounts.slice(index + 1)
                    ]
                }
            }
        },
        // Change loading state isPaymentAccountEditLoading when updating user payment account
        setPaymentAccountEditLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentAccountEditLoading = action.payload
        },

        // Change loading state isPaymentAccountsLoading for userPaymentAccounts
        setPaymentAccountsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentAccountsLoading = action.payload
        },
        // Set payment methods list
        setUserPaymentMethods: (state, action: PayloadAction<PaymentMethodTemplate[]>) => {
            state.unfilteredPaymentMethods = action.payload
            if (state.userPaymentAccounts) {
                // Filter methods which user not added yet
                const userPaymentAccIds = state.userPaymentAccounts.map(paymentAcc => paymentAcc.method_id)
                state.paymentMethods = action.payload.filter(method => !userPaymentAccIds.includes(method.id))
            } else {
                state.paymentMethods = action.payload
            }
        },
        // Change loading state isPaymentMethodsLoading for paymentMethods
        setPaymentMethodsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentMethodsLoading = action.payload
        },

        // Change loading state isApprovePayoutLoading for approvePayout
        setApprovePayoutLoading: (state, action: PayloadAction<boolean>) => {
            state.isApprovePayoutLoading = action.payload
        },
        // Change loading state isApprovePaymentLoading for approvePayment
        setApprovePaymentLoading: (state, action: PayloadAction<boolean>) => {
            state.isApprovePaymentLoading = action.payload
        },

        // Set deposit rate
        setDepositRate: (state, action: PayloadAction<number>) => {
            state.depositRate = action.payload
        },
        // Change loading state isSetDepositRateLoading for loading SetDepositRate
        setSetDepositRateLoading: (state, action: PayloadAction<boolean>) => {
            state.isSetDepositRateLoading = action.payload
        },

        // Set parent deposit rate
        setParentDepositRate: (state, action: PayloadAction<number>) => {
            state.parentDepositRate = action.payload
        },
        // Set user parent payment accounts list
        setDepositPaymentAccounts: (state, action: PayloadAction<PaymentAccount[]>) => {
            state.depositPaymentAccounts = action.payload
        },
        // Change loading state isDepositPaymentAccountsLoading for loading ShowDepositAccounts
        setDepositPaymentAccountsLoading: (state, action: PayloadAction<boolean>) => {
            state.isDepositPaymentAccountsLoading = action.payload
        },

        // Change loading state isPaymentRequestLoading when user request RequestPayment
        setPaymentRequestLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentRequestLoading = action.payload
        },
    }
})

export default PaymentSystemsSlice.reducer

export const {
    clearPaymentSystems,
    setUserPaymentAccounts,
    setPaymentAccountsLoading,
    addUserPaymentAccountToList,
    setPaymentAccountAddLoading,
    updateUserPaymentAccount,
    setPaymentAccountEditLoading,

    setUserPaymentMethods,
    setPaymentMethodsLoading,

    setApprovePayoutLoading,
    setApprovePaymentLoading,

    setDepositRate,
    setSetDepositRateLoading,

    setParentDepositRate,
    setDepositPaymentAccounts,
    setDepositPaymentAccountsLoading,
    setPaymentRequestLoading
} = PaymentSystemsSlice.actions