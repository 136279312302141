import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Lang } from "../../../features/localisation/useLanguageInterface";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface LanguageSelectorProps {
    fullWidth?: boolean
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({fullWidth = false}) => {
    const { switchLanguage, languagePack: {lang} } = useLanguage()

    const handleLanguageChange = (event: SelectChangeEvent<Lang>) => {
        switchLanguage(event.target.value as Lang);
    };

    return (
        <FormControl size={'small'} variant={'outlined'} fullWidth={fullWidth}>
            <Select
                labelId="language-selector-label"
                id="language-selector"
                value={lang}
                onChange={handleLanguageChange}
            >
                <MenuItem value={Lang.EN}>{fullWidth ? 'English' : 'En'}</MenuItem>
                <MenuItem value={Lang.RU}>{fullWidth ? 'Russian' : 'Ru'}</MenuItem>
                <MenuItem value={Lang.DE}>{fullWidth ? 'Deutsch' : 'De'}</MenuItem>
                {/*<MenuItem value={Lang.FR}>{fullWidth ? 'Francais' : 'Fr'}</MenuItem>*/}
                <MenuItem value={Lang.ES}>{fullWidth ? 'Espanol' : 'Es'}</MenuItem>
                {/*<MenuItem value={Lang.PT}>{fullWidth ? 'Portugues' : 'Pt'}</MenuItem>*/}
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
