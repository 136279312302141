import { useLanguage } from "../localisation/useLanguage";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import {
    setCurrentGameHistoryPage,
    setGameGamePageSize,
    setGameHistory,
    setGameHistoryFilter,
    setGameHistoryLoading,
    setTotalGameHistoryRecords
} from "./gameHistorySlice";
import axios, { AxiosError } from "axios";
import Config, { getApiHost } from "../../config";
import { useUser } from "../user/useUser";
import { GameHistoryFilter } from "./models/filter";
import { ShowGameHistoryResponse } from "./models/responses";
import { iUseGameHistory } from "./models/hookInterface";

export const useGameHistory = (): iUseGameHistory => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()
    const { user } = useUser()
    const state = useAppSelector(state => state.gameHistory)
    const apiURL = getApiHost()

    const getGameHistory = useCallback((page: number, itemsPerPage: number = 30, filter: GameHistoryFilter | null = null, childID?: number|null) => {
        let filterForSending = state.filter;
        if (filter) {
            if (
                filter.toTime !== state.filter.toTime ||
                filter.fromTime !== state.filter.fromTime ||
                filter.username !== state.filter.username ||
                filter.orderBy !== state.filter.orderBy ||
                filter.sortDirection !== state.filter.sortDirection
            ) {
                dispatch(setGameHistoryFilter(filter))
                filterForSending = filter
            }
        }
        let itemsPerPageToSend = state.pageSize
        if (itemsPerPage !== state.pageSize) {
            dispatch(setGameGamePageSize(itemsPerPage))
            itemsPerPageToSend = itemsPerPage
        }
        let pageToSend = state.currentPage
        if (page !== state.currentPage) {
            dispatch(setCurrentGameHistoryPage(page))
            pageToSend = page
        }
        if (token && apiURL && user) {
            dispatch(setGameHistoryLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowGameHistory')
            data.append('token', token)

            data.append('page', pageToSend.toString())
            data.append('pagesize', itemsPerPageToSend.toString())

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }
            if (filterForSending.orderBy !== null) {
                const direction = filterForSending.sortDirection ?? 'DESC'
                data.append('orderby', filterForSending.orderBy + ` ${direction}`)
            }

            data.append('childid', (childID ?? user.user_id).toString())

            axios.post<ShowGameHistoryResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data: gameHistoryList, total_transactions } = response.data
                    if (total_transactions) {
                        dispatch(setTotalGameHistoryRecords(total_transactions))
                    }
                    if (success) {
                        if (gameHistoryList) {
                            dispatch(setGameHistory(gameHistoryList))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setGameHistory([]))
                            if (total_transactions && total_transactions > 0) {
                                setLocalizedError(error)
                            }
                        }
                        else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setGameHistory([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setGameHistoryLoading(false))
                })
        }
    }, [state.filter, state.pageSize, state.currentPage, token, apiURL, user, dispatch, setLocalizedError, handleNetworkErrors])

    return {
        ...state,
        getGameHistory
    }
}