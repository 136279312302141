import { UserRole } from "../user/user";
import { ApiResponse } from "../apiResponseModels";

export const FinancesAccess = [
    UserRole.PLATFORM,
    UserRole.OWNER
]

export interface MoneyPack {
    id: number; // The unique identifier for the money pack
    freecoins: number; // The amount of free coins included in the money pack
    coins: number; // The amount of coins included in the money pack
    price: number; // The price of the money pack in currency
}

export interface FinancesState {
    coinPacks: MoneyPack[]|null // The list of money packs
    isCoinPacksLoading: boolean // Whether the money packs are being loaded
    isUpdatingCoinPackLoading: boolean // Whether the money pack is being updated
    isCreatingCoinPackLoading: boolean // Whether the money pack is being created
    isDeletingCoinPackLoading: boolean // Whether the money pack is being deleted
}

export interface iUseFinances extends FinancesState {
    getCoinPacks: () => void
    updateCoinPack: (coinPack: MoneyPack, onSuccess?: () => void) => void
    createCoinPack: (coinPack: MoneyPack, onSuccess?: () => void) => void
    deleteCoinPack: (id: number, onSuccess?: () => void, onFinish?: () => void) => void
}

export enum FinancesLoader {
    isCoinPacksLoading,
    isUpdatingCoinPackLoading,
    isCreatingCoinPackLoading,
    isDeletingCoinPackLoading
}

export interface ShowCoinPacksResponse extends ApiResponse {
    coinpacks?: MoneyPack[]
}

export interface CreateCoinPackResponse extends ApiResponse {
    data?: MoneyPack
}