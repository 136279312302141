import { NumericFormat, NumericFormatProps } from 'react-number-format';
import React from "react";

interface CustomProps {
    inputRef: (instance: typeof NumericFormat<HTMLInputElement> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

type ThousandsFormatProps = CustomProps & NumericFormatProps;

export const ThousandsFormatCustom = React.forwardRef<typeof NumericFormat<HTMLInputElement>, ThousandsFormatProps>(
    function ThousandsFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
        thousandSeparator="."
        decimalSeparator=","
        valueIsNumericString
        />
    );
    },
);
