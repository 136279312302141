import { useLanguage } from "../../../features/localisation/useLanguage";
import React, { FC } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { NetwinRoyalty } from "../../../features/netwin/models/models";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { getCurrentDomain } from "../../../config";

interface NetwinRoyaltyStatisticsCardProps {
    royalty: NetwinRoyalty
}

export const NetwinRoyaltyStatisticsCard: FC<NetwinRoyaltyStatisticsCardProps> = ({royalty}) => {
    const { languagePack: {pack: {netwinRoyaltyStatisticsTable: lang}}} = useLanguage()
    const currentDomain = getCurrentDomain()

    return <Card sx={{ py: '10px', px: '2vw' }}>
        <Stack gap={'8px'}>
            <Stack gap={'8px'} justifyContent={'start'} direction={'row'} alignItems={'center'}>
                <Box
                    borderRadius={'6px'}
                    width={'30px'}
                    height={'30px'}
                    sx={{
                        backgroundColor: '#f6f5f5',
                        backgroundImage: `url(${currentDomain}/cdn/provider_img/mini_sqr/${royalty.provider}.png)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                    <Typography fontWeight={700} fontSize={'16px'} lineHeight={0.8}>
                        {royalty.provider}
                    </Typography>
            </Stack>

            <Stack direction={'row'} gap={'4px'} justifyContent={'space-between'}>
                <Stack>
                    <Typography fontWeight={300} fontSize={'8px'}>
                        {lang.betsHeader}:
                    </Typography>
                    <Typography color={'secondary'} fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                        {convertCentsToCurrencyString(royalty.user_losses, true)}
                    </Typography>
                </Stack>

                <Stack alignItems={'center'}>
                    <Typography fontWeight={300} fontSize={'8px'}>
                        {lang.winsHeader}:
                    </Typography>
                    <Typography color={'primary'} fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                        {convertCentsToCurrencyString(royalty.user_wins, true)}
                    </Typography>
                </Stack>

                <Stack alignItems={'end'}>
                    <Typography fontWeight={300} fontSize={'8px'}>
                        {lang.netwinHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                        {convertCentsToCurrencyString(royalty.ggr, true)}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    </Card>
}