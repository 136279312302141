import { FC, ReactNode } from "react";
import { UserRole } from "../../../features/user/user";
import { useUser } from "../../../features/user/useUser";
import { Box, Card, Stack, Typography } from "@mui/material";
import RoadCones from '../../../../assets/clipart/two-road-cones.png'
import { useLanguage } from "../../../features/localisation/useLanguage";

interface AccessZoneProps {
    children?: ReactNode
    accessUserRoles: UserRole[]
}

export const AccessZone: FC<AccessZoneProps> = ({children, accessUserRoles}) => {
    const { userRole } = useUser();
    const { languagePack: {pack: {common: lang}}} = useLanguage()
    const hasAccess = userRole !== null && accessUserRoles.includes(userRole);

    return hasAccess ? <>{children}</> :
        <Box height={'100%'} pb={'10px'}>
            <Card sx={{ padding: '10px', height: '100%' }}>
                <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
                    <Box width={'50vh'}>
                        <Typography variant={'h1'} fontSize={'4vh'} textAlign={'center'} textTransform={'uppercase'}
                                    fontWeight={600}>
                            {lang.notEnoughRightsMessage}
                        </Typography>
                    </Box>

                    <Box
                        height={'50vh'}
                        width={'50vh'}
                        sx={{
                            backgroundImage: `url('${RoadCones}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                        }}
                    />
                </Stack>
            </Card>
        </Box>
}