import { User } from "../user/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BanUserSending, CurrencyObj, ManageUsersFilter, ShowUsersMode, UsersTreeNode } from "./models/models";

export interface ManageUsersState {
    mode: ShowUsersMode
    showUser: User|null
    usersTreeList: UsersTreeNode[]
    usersList: User[]|null
    pageSize: number
    currentPage: number
    banUserSending: BanUserSending[]
    totalRecords: number
    totalPages: number
    filter: ManageUsersFilter
    currencies: CurrencyObj[]|null
    nextAvailableUserLevels: string[]|null

    userNodeIdLoading: number | null // ID of user which loading now
    isUsersListLoading: boolean
    isUserCreating: boolean
    isCurrenciesLoading: boolean,
    isDepositToUserSending: boolean
    isWithdrawFromUserLoading: boolean
    isNextAvailableUserLevelsLoading: boolean
}

const initialState: ManageUsersState = {
    mode: ShowUsersMode.List,
    showUser: null,
    userNodeIdLoading: null,
    usersList: null,
    pageSize: 30,
    currentPage: 1,
    banUserSending: [],
    totalRecords: 0,
    totalPages: 0,
    filter: {
        fromTime: null,
        toTime: null,
        orderBy: null
    },
    usersTreeList: [],
    currencies: null,
    nextAvailableUserLevels: null,

    isUsersListLoading: false,
    isUserCreating: false,
    isCurrenciesLoading: false,
    isDepositToUserSending: false,
    isWithdrawFromUserLoading: false,
    isNextAvailableUserLevelsLoading: false
}

export const ManageUsersSlice = createSlice({
    name: 'manageUsers',
    initialState,
    reducers: {
        resetManageUsersSlice: (state) => {
            state.mode = ShowUsersMode.List
            state.showUser = null
            state.userNodeIdLoading = null
            state.usersList = null
            state.pageSize = 30
            state.currentPage = 1
            state.banUserSending = []
            state.totalRecords = 0
            state.totalPages = 0
            state.filter = {
                fromTime: null,
                toTime: null,
                orderBy: null
            }
            state.usersTreeList = []
            state.currencies = null
            state.nextAvailableUserLevels = null

            state.isUsersListLoading = false
            state.isUserCreating = false
            state.isCurrenciesLoading = false
            state.isDepositToUserSending = false
            state.isWithdrawFromUserLoading = false
            state.isNextAvailableUserLevelsLoading = false
        },
        changeShowUser: (state, action: PayloadAction<User|null>) => {
            state.showUser = action.payload
        },
        setUsersList: (state, action: PayloadAction<User[]>) => {
            state.usersList = action.payload
        },
        addToEndOfUsersTreeList: (state, action: PayloadAction<UsersTreeNode[]>) => {
            if (action.payload.length > 0 && action.payload[0].parentId) {
                const parentID = action.payload[0].parentId

                const userIds: number[] = action.payload.map((node) => node.user_id);
                const parentNode = state.usersTreeList.find((node) => node.user_id === parentID);
                const index = state.usersTreeList.findIndex((node) => node.user_id === parentID);

                if (index !== -1 && parentNode) {
                    state.usersTreeList = [
                        ...state.usersTreeList.slice(0, index),
                        { ...parentNode, childUsersIDs: [...userIds] },
                        ...state.usersTreeList.slice(index + 1),
                        ...action.payload
                    ];
                }
            } else {
                state.usersTreeList = [
                    ...state.usersTreeList,
                    ...action.payload
                ]
            }
        },
        clearUsersList: (state) => {
            state.usersList = null
        },
        clearUsersTreeList: (state) => {
            state.usersTreeList = []
        },
        setMode: (state, action: PayloadAction<ShowUsersMode>) => {
            state.mode = action.payload
        },
        setUserNodeIdLoading: (state, action: PayloadAction<number | null>) => {
            state.userNodeIdLoading = action.payload
        },
        setIsUsersListLoading: (state, action: PayloadAction<boolean>) => {
            state.isUsersListLoading = action.payload
        },
        setCurrentManageUsersPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setTotalManageUsersRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setManageUsersPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        changeManageUsersFilter: (state, action: PayloadAction<ManageUsersFilter>) => {
            state.filter = action.payload
        },
        addUserBanSending: (state, action: PayloadAction<BanUserSending>) => {
            state.banUserSending = [
                ...state.banUserSending,
                action.payload
            ]
        },
        removeBanUserSending: (state, action: PayloadAction<BanUserSending>) => {
            const index = state.banUserSending.findIndex(ban => ban.user_id === action.payload.user_id)
            state.banUserSending = [
                ...state.banUserSending.slice(0, index),
                ...state.banUserSending.slice(index + 1)
            ]
        },
        changeUserBanState: (state, action: PayloadAction<BanUserSending>) => {
            if (state.mode === ShowUsersMode.Tree) {
                const user = state.usersTreeList.find((node) => node.user_id === action.payload.user_id);
                const index = state.usersTreeList.findIndex((node) => node.user_id === action.payload.user_id);
                if (user && index !== -1) {
                    state.usersTreeList = [
                        ...state.usersTreeList.slice(0, index),
                        { ...user, banned: action.payload.banned },
                        ...state.usersTreeList.slice(index + 1)
                    ];
                }
            } else {
                if (state.usersList) {
                    const user = state.usersList.find((node) => node.user_id === action.payload.user_id);
                    const index = state.usersList.findIndex((node) => node.user_id === action.payload.user_id);
                    if (user && index !== -1) {
                        user.banned = action.payload.banned
                        state.usersList = [
                            ...state.usersList.slice(0, index),
                            user,
                            ...state.usersList.slice(index + 1)
                        ];
                    }
                }
            }
            if (state.showUser && state.showUser.user_id === action.payload.user_id) {
                state.showUser = {
                    ...state.showUser,
                    banned: action.payload.banned
                }
            }
        },
        setIsUserCreating: (state, action: PayloadAction<boolean>) => {
            state.isUserCreating = action.payload
        },
        setCurrencies: (state, action: PayloadAction<CurrencyObj[]>) => {
            state.currencies = action.payload
        },
        setIsCurrenciesLoading: (state, action: PayloadAction<boolean>) => {
            state.isCurrenciesLoading = action.payload
        },
        setIsDepositToUserSending: (state, action: PayloadAction<boolean>) => {
            state.isDepositToUserSending = action.payload
        },
        updateManageUserBalance: (state, action: PayloadAction<{userID: number, balance: number}>) => {
            if (state.mode === ShowUsersMode.Tree) {
                const user = state.usersTreeList.find((node) => node.user_id === action.payload.userID);
                const index = state.usersTreeList.findIndex((node) => node.user_id === action.payload.userID);
                if (user && index !== -1) {
                    state.usersTreeList = [
                        ...state.usersTreeList.slice(0, index),
                        { ...user, user_balance: action.payload.balance },
                        ...state.usersTreeList.slice(index + 1)
                    ];
                }
            } else {
                if (state.usersList) {
                    const user = state.usersList.find((node) => node.user_id === action.payload.userID);
                    const index = state.usersList.findIndex((node) => node.user_id === action.payload.userID);
                    if (user && index !== -1) {
                        user.user_balance = action.payload.balance
                        state.usersList = [
                            ...state.usersList.slice(0, index),
                            user,
                            ...state.usersList.slice(index + 1)
                        ];
                    }
                }
            }
            if (state.showUser && state.showUser.user_id === action.payload.userID) {
                state.showUser = {
                    ...state.showUser,
                    user_balance: action.payload.balance
                }
            }
        },
        setIsWithdrawFromUserLoading: (state, action: PayloadAction<boolean>) => {
            state.isWithdrawFromUserLoading = action.payload
        },
        setNextAvailableUserLevels: (state, action: PayloadAction<string[]>) => {
            state.nextAvailableUserLevels = action.payload
        },
        setIsNextAvailableUserLevelsLoading: (state, action: PayloadAction<boolean>) => {
            state.isNextAvailableUserLevelsLoading = action.payload
        }
    }
})

export default ManageUsersSlice.reducer

export const {
    resetManageUsersSlice,
    setMode,
    changeShowUser,
    setUserNodeIdLoading,
    setIsUsersListLoading,
    setUsersList,
    addToEndOfUsersTreeList,
    clearUsersTreeList,
    clearUsersList,
    setTotalManageUsersRecords,
    setCurrentManageUsersPage,
    setManageUsersPageSize,
    changeManageUsersFilter,
    addUserBanSending,
    removeBanUserSending,
    changeUserBanState,
    setIsUserCreating,
    setCurrencies,
    setIsCurrenciesLoading,
    setIsDepositToUserSending,
    updateManageUserBalance,
    setIsWithdrawFromUserLoading,
    setNextAvailableUserLevels,
    setIsNextAvailableUserLevelsLoading
} = ManageUsersSlice.actions