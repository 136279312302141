import React, { FC, useEffect, useState } from "react";
import { Contractor } from "../../features/providers/models/models";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useProviders } from "../../features/providers/useProviders";

interface ContractorSelectorProps {
    providerID: number
    currentContractorID: number
    contractorsList: Contractor[]
    close?: () => void
}

export const ContractorSelector: FC<ContractorSelectorProps> = ({providerID, currentContractorID, contractorsList, close = () => {}}) => {
    const { updateProviderContractor, isProviderContractorUpdateLoading } = useProviders()
    const [ selectedContractor, setSelectedContractor ] = useState<Contractor|undefined>()
    const [initLoad, setInitLoad ] = useState<boolean>(true)
    
    useEffect(() => {
       if (initLoad) {
           setInitLoad(false)
           const selected = contractorsList.find(contractor => contractor.contractor_id === currentContractorID)
           if (selected) {
               setSelectedContractor(selected)
           }
       } 
    }, [contractorsList, currentContractorID, initLoad])

    return <FormControl size={'small'} fullWidth>
            <InputLabel id="contractor-selector-label">Contractor</InputLabel>
            <Select
                disabled={isProviderContractorUpdateLoading}
                autoFocus
                onBlur={close}
                labelId="contractor-selector-label"
                value={selectedContractor?.contractor_id ?? 0}
                onChange={e => {
                    const contractor = contractorsList.find(contractor => contractor.contractor_id === Number(e.target.value))
                        setSelectedContractor(contractor)
                        updateProviderContractor(providerID, contractor?.contractor_id ?? 0, close)
                }}
                defaultValue={selectedContractor?.contractor_id ?? 0}
                label={'Contractor'}
            >
                <MenuItem value={0}>None</MenuItem>
                {contractorsList.map((contractor) => <MenuItem key={contractor.contractor_id} value={contractor.contractor_id}>{contractor.contractor_name}</MenuItem>)}
            </Select>
        </FormControl>
}