import {forwardRef} from "react";
import {WidgetViewProps} from "../../../../../components-v2/commons/widget_layout/Widget.interface";
import {Box, Card, Typography} from "@mui/material";
import {useCommonMediaQueries} from "../../../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const WidgetView = forwardRef<HTMLDivElement, WidgetViewProps>((props, ref) => {
    const { xxSmallScreen: xs } = useCommonMediaQueries()

    return (
        <Card sx={{padding: xs ? 1.5 :4, display: 'flex', flexDirection: 'column', gap: xs ? 1 : 2, height: props.height}} ref={ref}>
            {(props.headerTitle || props.headerActions) && <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                    {typeof props.headerTitle === 'string' ? (
                        <Typography fontSize={xs ? 24 : 32} fontWeight={600}>{props.headerTitle}</Typography>
                    ) : (
                        props.headerTitle
                    )}
                </Box>
                <Box display={'flex'} gap={1}>{props.headerActions}</Box>
            </Box>}
            {props.children}
        </Card>
    );
});