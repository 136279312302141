import {FC} from "react";
import {AccountTitleProps} from "./AccountTitle.interface";
import {AccountTitleView} from "../../../themes/theme_connector";
import {AccountAvatar} from "./AccountAvatar";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const AccountTitle: FC<AccountTitleProps> = ({user}) => {
    const { getLocalizedRoleName } = useLanguage()
    const role = user ? getLocalizedRoleName(user.user_role) : undefined

    return <AccountTitleView avatar={<AccountAvatar user={user}/>} title={user?.user_name} role={role}/>
}