import { NumericFormat, NumericFormatProps } from 'react-number-format';
import React from "react";

interface CustomProps {
    inputRef: (instance: typeof NumericFormat<HTMLInputElement> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

type PercentageFormatProps = CustomProps & NumericFormatProps;

export const PercentageFormatCustom = React.forwardRef<typeof NumericFormat<HTMLInputElement>, PercentageFormatProps>(
    function CurrencyFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                suffix="%"
                max={100}
                fixedDecimalScale
                valueIsNumericString
            />
        );
    },
);