import {FC} from "react";
import {SurfaceViewProps} from "./SurfaceView.interface";
import {Card, useTheme} from "@mui/material";
import {useCommonMediaQueries} from "../../../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const SurfaceView: FC<SurfaceViewProps> = ({children, variant = 'contained', padding}) => {
    const theme = useTheme()
    const { xxSmallScreen: xs } = useCommonMediaQueries()
    padding = padding || (xs ? 1.5 : 4)

    return <Card
        sx={{
            padding: padding,
            border: variant === 'outlined' ? '1px solid' : undefined,
            borderColor: theme.palette.neutral?.main,
            borderRadius: 1,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: xs ? 1 : 2,
            backgroundColor: variant === 'contained' ? theme.palette.primary.main : undefined,
            color: variant === 'contained' ? theme.palette.primary.contrastText : theme.palette.text.primary,
            // boxShadow: variant === 'contained' ? `${theme.spacing(0, 0.5, 2, 0)} ${alpha(theme.palette.primary.main, 0.7)}` : undefined
        }}
    >
        {children}
    </Card>
}