import { FC, useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SortingListItem } from "../layout/content_components/FilterPanel";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";
import { useLanguage } from "../../features/localisation/useLanguage";

interface SortListProps {
    sortingList: SortingListItem[]
    currentOrderBy?: string
    currentSortDirection?: string
}

export const SortList: FC<SortListProps> = ({sortingList, currentOrderBy, currentSortDirection}) => {
    const { languagePack: {pack: {commonSortingNames: lang}}} = useLanguage()
    const [currentSelectedTitle, setCurrentSelectedTitle] = useState<string|undefined>()
    const prevCurrentOrderBy = useRef(currentOrderBy)
    const prevCurrentSortDirection = useRef(currentSortDirection)
    const prevLang = useRef(lang)
    const { batchUpdateQueryParameters } = useQuery()

    useEffect(() => {
        if (currentOrderBy !== prevCurrentOrderBy.current || currentSortDirection !== prevCurrentSortDirection.current || lang !== prevLang.current) {
            const srtDirect = currentSortDirection ?? 'DESC'
            const itemFromList = sortingList.find(srt => srt.sortDirection === srtDirect && srt.orderBy === currentOrderBy)
            if (itemFromList) {
                setCurrentSelectedTitle(itemFromList.title)
            }
            prevCurrentOrderBy.current = currentOrderBy
            prevCurrentSortDirection.current = currentSortDirection
            prevLang.current = lang
        }
    }, [currentOrderBy, currentSelectedTitle, currentSortDirection, lang, sortingList])

    const onSelectChange = (event: SelectChangeEvent<string|null>) => {
        if (event.target.value !== null) {
            const item = sortingList.find(item => item.title === event.target.value)
            if (item) {
                batchUpdateQueryParameters([
                    {paramName: 'orderBy', value: item.orderBy},
                    {paramName: 'sortDirection', value: item.sortDirection}
                ])
            }
        }
    }

    return <FormControl sx={{
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'secondary.main',
            color: 'white'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
    }} size="small" color="primary" variant="outlined" fullWidth>
        <Select
            value={currentSelectedTitle ?? null}
            onChange={onSelectChange}
            displayEmpty
            renderValue={(value) => value === null ? lang.sortLabel : value}
        >
            {sortingList.map(item => <MenuItem key={item.title} value={item.title}>{item.title}</MenuItem>)}
        </Select>
    </FormControl>
}