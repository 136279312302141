import {FC} from "react";
import {AccountAvatarProps} from "./AccountTitle.interface";
import {stringToColor} from "../../../features/common_funcs/color";
import {AccountAvatarView} from "../../../themes/theme_connector";

export const AccountAvatar: FC<AccountAvatarProps> = ({user}) => {
    const bgColor = user ? stringToColor(user.user_name) : undefined
    const letter = user ? user.user_name[0] : undefined

    return <AccountAvatarView
        color={bgColor}
        letter={letter}
    />
}