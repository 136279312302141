import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch } from "../../store";
import { useLanguage } from "../../features/localisation/useLanguage";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import { useGameHistory } from "../../features/game_history/useGameHistory";
import { clearGameHistory } from "../../features/game_history/gameHistorySlice";
// import { useLocation, useNavigate } from "react-router-dom";
// import { SortedCellGameHistory } from "./SortedCellGameHistory";
// import { GameHistorySort } from "../../features/game_history/models/sorting";

export const GameHistoryTable: FC = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0)
    const dispatch = useAppDispatch()
    // const navigate = useNavigate();
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);

    const { languagePack: {pack: {gamesHistory: lang}}} = useLanguage()
    const { gameHistory, isGameHistoryLoading } = useGameHistory()

    const updateHeight = useCallback(() => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight
            if (height !== newHeight) {
                setHeight(newHeight)
            }
        }
    }, [height])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    useEffect(() => {
        return () => {
            dispatch(clearGameHistory())
        }
    }, [ dispatch ])

    // const handleClickSorting = (sort: GameHistorySort) => {
    //
    //         searchParams.set('orderBy', sort);
    //
    //         navigate({
    //             pathname: location.pathname,
    //             search: searchParams.toString(),
    //         });
    //
    // }

    return <Box
        height={'100%'}
        ref={wrapperRef}
        flexGrow={1}>
        <TableContainer sx={{ maxHeight: `${height}px` }}>
            <Table stickyHeader aria-label="manage users table">
                <TableHead>
                    <TableRow >
                        <TableCell>
                            {/*<SortedCellGameHistory*/}
                            {/*    orderName={GameHistorySort.Username}*/}
                            {/*    currentOrderBy={orderBy}*/}
                            {/*    onClick={handleClickSorting}>*/}
                                {lang.userNameTableHeader}
                            {/*</SortedCellGameHistory>*/}
                        </TableCell>
                        <TableCell>
                            {/*<SortedCellGameHistory*/}
                            {/*    orderName={GameHistorySort.Provider}*/}
                            {/*    currentOrderBy={orderBy}*/}
                            {/*    onClick={handleClickSorting}>*/}
                                {lang.providerTableHeader}
                            {/*</SortedCellGameHistory>*/}
                        </TableCell>
                        <TableCell>
                            {/*<SortedCellGameHistory*/}
                            {/*    orderName={GameHistorySort.Game}*/}
                            {/*    currentOrderBy={orderBy}*/}
                            {/*    onClick={handleClickSorting}>*/}
                                {lang.gameTableHeader}
                            {/*</SortedCellGameHistory>*/}
                        </TableCell>
                        <TableCell>
                            {/*<SortedCellGameHistory*/}
                            {/*    orderName={GameHistorySort.BetAmount}*/}
                            {/*    currentOrderBy={orderBy}*/}
                            {/*    onClick={handleClickSorting}>*/}
                                {lang.betAmountTableHeader}
                            {/*</SortedCellGameHistory>*/}
                        </TableCell>
                        <TableCell>
                            {/*<SortedCellGameHistory*/}
                            {/*    orderName={GameHistorySort.WinAmount}*/}
                            {/*    currentOrderBy={orderBy}*/}
                            {/*    onClick={handleClickSorting}>*/}
                                {lang.winAmountTableHeader}
                            {/*</SortedCellGameHistory>*/}
                        </TableCell>
                        <TableCell>
                            {/*<SortedCellGameHistory*/}
                            {/*    orderName={GameHistorySort.DateTime}*/}
                            {/*    currentOrderBy={orderBy}*/}
                            {/*    onClick={handleClickSorting}>*/}
                                {lang.dateTimeTableHeader}
                            {/*</SortedCellGameHistory>*/}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gameHistory && gameHistory.map((game, index) => (
                        <TableRow
                            key={game.game_name + '-gt-' + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {game.user_name}
                            </TableCell>
                            <TableCell>{game.provider_name}</TableCell>
                            <TableCell>{game.game_name}</TableCell>
                            <TableCell>{game.withdraw_sum ? convertCentsToCurrencyString(game.withdraw_sum) : '--'}</TableCell>
                            <TableCell>{game.deposit_sum ? convertCentsToCurrencyString(game.deposit_sum) : '--'}</TableCell>
                            <TableCell>{game.time}</TableCell>
                        </TableRow>
                    ))}
                    { gameHistory === null && isGameHistoryLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        { gameHistory?.length === 0 && <Alert variant="outlined" severity="warning">
            {lang.gamesHistoryNotFoundMessage}
        </Alert>}
    </Box>
}