import React, {FC} from "react";
import {MoneySurfaceViewProps} from "./SurfaceView.interface";
import {SurfaceView} from "./SurfaceView";
import {Box, Skeleton, Stack, Typography, useTheme} from "@mui/material";
import SlotCounter from "react-slot-counter";
import {useCommonMediaQueries} from "../../../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const MoneySurfaceView: FC<MoneySurfaceViewProps> = ({icon: Icon, title, value, currency, size = 'medium', ...props}) => {
    const theme = useTheme()
    const { xxSmallScreen: xs } = useCommonMediaQueries()

    return <SurfaceView {...props} padding={size === 'small' ? (xs ? 1 : theme.spacing(1.5, 2)) : xs ? 1 : 2}>
        {Icon && <Icon sx={{fontSize: size === 'small' ? '24px' : '40px'}} color={props.variant === 'outlined' ? 'primary' : undefined}/>}
        <Stack gap={size === 'small' ? (xs ? 0.3 : 0.5) : (xs ? 0.5 : 1)}>
            <Typography fontSize={xs ? 12 : 14} fontWeight={600} lineHeight={1}>
                {title}
            </Typography>
            <Box display={'flex'} gap={1} alignItems={'end'}>
                <Typography fontSize={size === 'small' ? (xs ? 18 : 24) : (xs ? 20 : 26)} fontWeight={600} lineHeight={1}>
                    {value !== undefined ?
                        typeof value === 'number' || typeof value === 'string' ?
                            <SlotCounter value={value} /> : value
                        : <Skeleton width={'100px'}/>}
                </Typography>
                <Typography sx={{opacity: 0.2}} fontSize={14} fontWeight={600} lineHeight={1}>
                    {currency}
                </Typography>
            </Box>
        </Stack>
    </SurfaceView>
}