// Enum representing different API request types
export enum ApiRequest {
    GetGames = 'GetGames',
    ShowTopWins = 'ShowTopWins',
    ShowProviders = 'ShowProviders',
    GetBalance = 'GetBalance',
    StartSession = 'StartSession',
    OpenSportsBook = 'OpenSportsBook',
    ShowMoneyTransfersToMyself = 'SHOWMONEYTRANSFERSTOMYSELF',
    DepositMoney = 'DepositMoney',
    WithdrawMoney = 'WithdrawMoney'
}

export type LoaderServicePayload = Record<string, string>