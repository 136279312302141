import React, { FC, useEffect, useRef, useState } from "react";
import { CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { PercentageFormatCustom } from "./PercentageFormatCustom";
import IconButton from "@mui/material/IconButton";
import { usePlayersAgents } from "../../../features/manage_players_agents/usePlayersAgents";
import { ModalInterface } from "../../commons/modal_wrapper/model";
import { ModalWrapper } from "../../commons/modal_wrapper/ModalWrapper";

interface PercentInlineEditorProps extends ModalInterface {
    value: number;
    onDone?: () => void
    onEnterEditMode?: () => void
    disabled?: boolean
    userId: number // edited agent ID
    forceEditMode?: boolean
}

export const PercentInlineEditor: FC<PercentInlineEditorProps> = ({value, onDone, onEnterEditMode, disabled = false, userId, forceEditMode = false}) => {
    const { saveDepositRate } = usePlayersAgents()
    const [editMode, setEditMode] = useState(false);
    const [localValue, setLocalValue] = useState<string>('');
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [justMounted, setJustMounted] = useState(true);
    
    useEffect(() => {
        if (justMounted) {
            setJustMounted(false);
            if (forceEditMode) {
                setLocalValue((value * 100).toString())
                setEditMode(true)
            }
        }
    }, [forceEditMode, justMounted, value])

    useEffect(() => {
        if (editMode && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [editMode]);

    const cancelClickHandler = () => {
        setLocalValue('')
        onDone && onDone()
        setEditMode(false)
    }

    const saveClickHandler = () => {
        const rawValue = parseFloat(localValue) / 100;
        const roundedValue = parseFloat(rawValue.toFixed(2));
        saveDepositRate(userId, roundedValue, setSaveIsLoading, () => {
            onDone && onDone();
            setEditMode(false);
        })
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            saveClickHandler();
        } else if (event.key === 'Escape' && localValue !== '') {
            cancelClickHandler();
        }
    };

    return !editMode ?
        <Stack
            direction={'row'}
            justifyContent={'start'}
            alignItems={'center'}
            gap={'20px'}
            sx={{ cursor: 'pointer' }}
            onClick={!disabled ? () => {
                setLocalValue((value * 100).toString())
                onEnterEditMode && onEnterEditMode()
                setEditMode(true)
            } : undefined}
        >
            {value * 100}%
            <EditOutlinedIcon/>
        </Stack>
        :
        <TextField
            inputRef={inputRef}
            disabled={saveIsLoading}
            autoFocus
            InputProps={{
                inputComponent: PercentageFormatCustom as any,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton disabled={saveIsLoading} onClick={cancelClickHandler} edge="end">
                            <HighlightOffIcon />
                        </IconButton>
                        <IconButton disabled={saveIsLoading || localValue === ''} onClick={saveClickHandler} edge="end">
                            {!saveIsLoading ? <SaveAsIcon/> : <CircularProgress size={'20px'}/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            value={localValue}
            size={'small'}
            fullWidth
            onChange={event => setLocalValue(event.target.value)}
            onKeyDown={handleKeyDown}
        />;
};

export const PercentModalEditor: FC<PercentInlineEditorProps> = (props) => <ModalWrapper component={PercentInlineEditor} componentProps={props}/>