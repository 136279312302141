import { AgentLoaders, iUseAgents, ShowAgentsResponse } from "./agentsModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLanguage } from "../localisation/useLanguage";
import { useUser } from "../user/useUser";
import { useCallback } from "react";
import {
    addAgentLoadingId,
    addFoundAgents,
    pushAgents,
    removeAgentLoadingId,
    setAgentFilter,
    setAgentLoader
} from "./AgentsSlice";
import axios from "axios";
import Config, { getApiHost } from "../../config";

export const useAgents = (): iUseAgents => {
    const dispatch = useAppDispatch();
    const { setLocalizedError, handleNetworkErrors } = useLanguage();
    const state = useAppSelector(state => state.agents);
    const { token, user } = useUser();
    const apiURL = getApiHost()

    const getAgentChildren = useCallback((agentId?: number, onSuccess = () => {}) => {
        if (token && apiURL && user) {
            dispatch(addAgentLoadingId(agentId ?? user.user_id))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowAgents')
            data.append('token', token)
            if (agentId) {
                data.append('parentid', agentId.toString())
            }

            axios
                .post<ShowAgentsResponse>(apiURL, data)
                .then(response => {
                    const { success, error, agents} = response.data
                    if (success && agents) {
                        dispatch(pushAgents({ agents: agents, parentID: agentId ?? user.user_id }))
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(removeAgentLoadingId(agentId ?? user.user_id))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const findAgent = useCallback((agentName: string) => {
        if (token && apiURL) {
            dispatch(setAgentLoader({
                loader: AgentLoaders.isAgentSearchLoading,
                isLoading: true
            }))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'FindAgent')
            data.append('token', token)
            data.append('username', agentName)

            axios
                .post<ShowAgentsResponse>(apiURL, data)
                .then(response => {
                    const { success, error, parents} = response.data
                    if (success && parents) {
                        dispatch(addFoundAgents(parents))
                        if (parents.length > 0) {
                            dispatch(setAgentFilter(parents.reverse()[0].user_name))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setAgentLoader({
                        loader: AgentLoaders.isAgentSearchLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token])

    return {
        ...state,
        getAgentChildren,
        findAgent
    }
}