import { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import { Box } from "@mui/material";

interface TableHeightWrapperProps {
    children?: ReactNode
    onHeightChanged: (newHeight: number) => void
    currentHeight: number
    withFlexGrow?: boolean
}

export const TableHeightWrapper: FC<TableHeightWrapperProps> = ({ children, onHeightChanged, currentHeight, withFlexGrow = true }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const updateHeight = useCallback(() => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight
            if (currentHeight !== newHeight) {
                onHeightChanged(newHeight)
            }
        }
    }, [currentHeight, onHeightChanged])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);
    
    return <Box
        height={'100%'}
        ref={wrapperRef}
        flexGrow={withFlexGrow ? 1 : undefined}
    >
        {children}
    </Box>
}