import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface BranchHolderProps {
    children?: ReactNode
}

export const BranchHolder: FC<BranchHolderProps> = ({ children }) => {
    return <Box
        display={'flex'}
        sx={{
            '&:not(:last-child)': {
                '& > *:first-child': {
                    '& > *:last-child': {
                        flexGrow: 1,
                        borderLeft: 'black dotted 1px'
                    }
                }
            }
        }}
    >
        <Box
            display={'flex'}
            flexDirection={'column'}
            pl={'10px'}
        >
            <Box
                minHeight={'20px'}
                width={'20px'}
                borderLeft={'black dotted 1px'}
                borderBottom={'black dotted 1px'}
            />
            <Box
                flexGrow={1}
            />
        </Box>
        <Box py={'4px'}>
            {children}
        </Box>
    </Box>
}