import { iUseNetwin } from "./models/hookInterface";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLanguage } from "../localisation/useLanguage";
import { useUser } from "../user/useUser";
import Config, { getApiHost } from "../../config";
import { useCallback } from "react";
import { NetwinRoyaltyFilter, NetwinUsersFilter } from "./models/filter";
import {
    setNetwinLoader, setNetwinRoyalty,
    setNetwinRoyaltyFilter, setNetwinRoyaltySummary,
    setNetwinUsers, setNetwinUsersSummary,
    setSelectedAgentCommission,
    setUsersFilter
} from "./netwinSlice";
import { NetwinLoaders } from "./models/loaders";
import axios, { AxiosError } from "axios";
import { ShowNetwinRoyaltyResponse, ShowNetwinStatistics } from "./models/responses";
import { v4 as uuidv4 } from 'uuid';

export const useNetwin = (): iUseNetwin => {
    const dispatch = useAppDispatch()
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const { token, user } = useUser()
    const apiURL = getApiHost()
    const state = useAppSelector(state => state.netwin)

    const getNetwinUsers = useCallback((childID?: number, filter?: NetwinUsersFilter) => {
        let filterForSending = state.usersFilter
        if (filter) {
            if (
                filter.toTime !== state.usersFilter.toTime
                || filter.fromTime !== state.usersFilter.fromTime
                || filter.userRole !== state.usersFilter.userRole
                || filter.username !== state.usersFilter.username
                || filter.orderBy !== state.usersFilter.orderBy
                || filter.sortDirection !== state.usersFilter.sortDirection
            ) {
                dispatch(setUsersFilter(filter))
                filterForSending = filter
            }
        }

        if(token && user && apiURL) {
            dispatch(setNetwinLoader({
                loader: NetwinLoaders.isNetwinUsersLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowNetwinStatistics')
            data.append('token', token)

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.userRole !== null) {
                data.append('userrole', filterForSending.userRole)
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }
            if (filterForSending.orderBy !== null) {
                const direction = filterForSending.sortDirection ?? 'DESC'
                data.append('orderby', filterForSending.orderBy + ` ${direction}`)
            }
            if (childID) {
                data.append('childid', childID.toString())
            }

            axios
                .post<ShowNetwinStatistics>(apiURL, data)
                .then(response => {
                    const { success, error, data: netwinUsers, agent_commission } = response.data
                    if (success){
                        if (netwinUsers) {
                            dispatch(setNetwinUsers(netwinUsers))
                        }
                        if (agent_commission !== undefined) {
                            dispatch(setSelectedAgentCommission(agent_commission))
                        }
                        if (netwinUsers && agent_commission !== undefined) {
                            let totalBets = 0
                            let totalWins = 0
                            let totalGGR = 0
                            let totalCommission = 0
                            netwinUsers.forEach(user => {
                                totalBets += user.user_losses
                                totalWins += user.user_wins
                                totalGGR += user.ggr
                                totalCommission += user.ggr * agent_commission
                            })

                            dispatch(setNetwinUsersSummary({
                                userTotalBets: totalBets,
                                userTotalWins: totalWins,
                                userTotalGGR: totalGGR,
                                userTotalCommission: totalCommission
                            }))
                        }
                    } else if (error) {
                        if (error.code === 2) {
                            dispatch(setNetwinUsers([]))
                            dispatch(setSelectedAgentCommission(null))
                            dispatch(setNetwinUsersSummary({
                                userTotalBets: null,
                                userTotalWins: null,
                                userTotalGGR: null,
                                userTotalCommission: null
                            }))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setNetwinUsers([]))
                    dispatch(setSelectedAgentCommission(null))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setNetwinLoader({
                        loader: NetwinLoaders.isNetwinUsersLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.usersFilter, token, user])

    const getNetwinRoyalty = useCallback((childID?: number, filter?: NetwinRoyaltyFilter) => {
        let filterForSending = state.royaltyFilter
        if (filter) {
            if (
                filter.toTime !== state.royaltyFilter.toTime
                || filter.fromTime !== state.royaltyFilter.fromTime
                || filter.orderBy !== state.royaltyFilter.orderBy
                || filter.username !== state.royaltyFilter.username
                || filter.sortDirection !== state.royaltyFilter.sortDirection
            ) {
                dispatch(setNetwinRoyaltyFilter(filter))
                filterForSending = filter
            }

            if(token && user && apiURL) {
                dispatch(setNetwinLoader({
                    loader: NetwinLoaders.isNetwinRoyaltyLoading,
                    isLoading: true
                }))

                axios.create({ ...Config.axiosConfig })

                const data = new FormData();
                data.append('action', 'ShowRoyaltyStatistics')
                data.append('token', token)

                if (filterForSending.fromTime !== null) {
                    data.append('fromtime', filterForSending.fromTime.toString())
                }
                if (filterForSending.toTime !== null) {
                    data.append('totime', filterForSending.toTime.toString())
                }
                if (filterForSending.username !== null) {
                    data.append('username', filterForSending.username)
                }
                if (filterForSending.orderBy !== null) {
                    const direction = filterForSending.sortDirection ?? 'DESC'
                    data.append('orderby', filterForSending.orderBy + ` ${direction}`)
                }
                if (childID) {
                    data.append('childid', childID.toString())
                }

                axios
                    .post<ShowNetwinRoyaltyResponse>(apiURL, data)
                    .then(response => {
                        const { success, error, data: netwinRoyalty, agent_commission } = response.data
                        if (success) {
                            if (agent_commission !== undefined) {
                                dispatch(setSelectedAgentCommission(agent_commission))
                            }
                            if (netwinRoyalty && agent_commission !== undefined) {
                                netwinRoyalty.forEach(item => item.localUUID = uuidv4())
                                dispatch(setNetwinRoyalty(netwinRoyalty))

                                let totalBets = 0
                                let totalWins = 0
                                let totalGGR = 0
                                let totalCommission = 0
                                netwinRoyalty.forEach(royalty => {
                                    totalBets += royalty.user_losses
                                    totalWins += royalty.user_wins
                                    totalGGR += royalty.ggr
                                    totalCommission += royalty.ggr * agent_commission
                                })

                                dispatch(setNetwinRoyaltySummary({
                                    totalBets: totalBets,
                                    totalWins: totalWins,
                                    totalGGR: totalGGR,
                                    totalCommission
                                }))
                            }
                        } else if (error) {
                            if (error.code === 2) {
                                dispatch(setNetwinRoyalty([]))
                                dispatch(setNetwinRoyaltySummary({
                                    totalBets: null,
                                    totalWins: null,
                                    totalGGR: null,
                                    totalCommission: null
                                }))
                            } else {
                                setLocalizedError(error)
                            }
                        }
                    })
                    .catch((error: Error | AxiosError) => {
                        dispatch(setNetwinRoyalty([]))
                        handleNetworkErrors(error)
                    })
                    .finally(() => {
                        dispatch(setNetwinLoader({
                            loader: NetwinLoaders.isNetwinRoyaltyLoading,
                            isLoading: false
                        }))
                    })
            }
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.royaltyFilter, token, user])

    return {
        ...state,
        getNetwinUsers,
        getNetwinRoyalty
    }
}