import React, { FC } from "react";
import { Box } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { SortDirection } from "../../../../features/common_models";

export interface SortedCellProps<T> {
    orderName: T
    currentOrderBy: T|null
    sortDirection?: SortDirection
    children: string
    onClick: (orderName: T, sortDirection?: SortDirection) => void
}

export const GenericSortedCell: FC<SortedCellProps<any>> = ({ orderName, currentOrderBy, children, onClick, sortDirection = 'DESC' }) => {
    return currentOrderBy === orderName ? (
        <Box
            className={'cursor-pointer'}
            fontWeight={800}
            display={'flex'}
            alignItems={'center'}
            columnGap={'6px'}
            onClick={() => onClick(orderName, sortDirection === 'DESC' ? 'ASC' : 'DESC')}
        >
            {children} <SortIcon fontSize={'small'} sx={{transform: `scaleY(${sortDirection === 'DESC' ? '1' : '-1'})`}}/>
        </Box>
    ) : (
        <Box onClick={() => onClick(orderName, 'DESC')} className={'cursor-pointer'}>
            {children}
        </Box>
    );
};