import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const PlayerIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 24 24" xmlSpace="preserve">
                <path d="M17,15.5c0.3,0,1.6,0,1.8,0c0.2,0.1,0.3,0.4,0.4,0.6c0.5,0.1,1.1,0,1.7,0c1.1,0,1.2,1.1,1.4,1.9c0.1,0.7,0.6,2.4,0.5,3
	c0,0.6-0.5,1-1.1,1c-0.7,0-0.9-0.4-1.1-1l-0.3-0.8c-0.3-0.6-0.3-0.5-1.1-0.5c-0.8,0-2.4-0.1-3.1,0c-0.1,0.2-0.1,0.3-0.2,0.5L15.4,21
	c-0.1,0.2-0.1,0.4-0.3,0.6c-0.3,0.4-1.1,0.5-1.5,0.2c-0.6-0.5-0.3-1.2-0.2-1.8l0.4-2.2c0.3-2.1,1.1-1.4,2.7-1.6
	C16.6,15.9,16.6,15.5,17,15.5z M15.4,17.3c-0.3,0-0.2,0.3-0.2,0.5c-0.1,0-0.4,0-0.5,0.1c-0.1,0.1-0.1,0.4,0,0.5
	c0.1,0.1,0.3,0.1,0.4,0.1c0,0.4,0.1,0.5,0.5,0.4c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0.6-0.1,0.6-0.6-0.1-0.7
	C15.9,17.4,15.8,17.2,15.4,17.3z M19.5,18.2c-0.4,0.2-0.3,0.8,0.2,0.7C20,18.7,19.9,18.2,19.5,18.2z M20.8,17.4
	c-0.4,0.1-0.4,0.7,0.1,0.7C21.3,18,21.2,17.4,20.8,17.4z" fill="currentColor"/>
                <path d="M8,1.9c1.6-0.1,3.1,0.6,3.9,2c0.7,1.2,0.7,2.6,0.3,3.9c0.3,0.5,0.5,1,0.4,1.6c-0.1,0.6-0.5,0.8-0.9,1.1l0,0
	c-0.3,0.9-0.2,1.2-1.1,1.9c0.4,0.7,0.8,0.9,1.5,1.2c0.8,0.4,2.7,1.2,3.3,1.8c-0.9-0.1-1.9,0.3-2.2,1.2c-0.2,0.6-0.3,1.6-0.4,2.2
	l-0.2,1.3c-0.1,0.6-0.2,1.2,0.3,1.8c-0.4,0-0.9,0-1.3,0L9,22l-5.8,0c-0.9,0-1.7,0-1.9-1.2c-0.1-0.3,0-0.7,0-1l0-2.8
	c0-0.6-0.1-1.3,0.4-1.8c0.5-0.5,2.1-1.1,2.7-1.5c0.8-0.4,1.1-0.6,1.7-1.4c-0.4-0.3-0.7-0.8-0.8-1.3c-0.1-0.4-0.1-0.5-0.4-0.7
	C4,9.8,4.1,8.6,4.6,7.8c-0.2-0.3-0.4-0.6-0.4-1C4,4.3,5.5,2.2,8,1.9z M9.9,6.3C9.2,6.6,8.6,7.1,7.8,7.2C7.3,7.3,6.5,6.8,6,7.3
	C5.9,7.7,6.2,9,5.5,8.8C5.3,8.7,5.2,8.5,5,8.3C4.7,8.7,4.6,9.5,5,9.9c0.6,0.5,0.6,0.2,0.8,1c0.2,0.7,0.6,1.2,1.3,1.5
	c0.7,0.3,1.5,0.5,2.2,0.3c0.8-0.3,1.6-0.9,1.8-1.8c0.1-0.2,0.1-0.5,0.2-0.7c0.2-0.4,0.8-0.3,0.9-1c0-0.3-0.1-0.7-0.3-0.9
	c-0.1,0.1-0.2,0.3-0.4,0.3c-0.3,0-0.6-0.8-0.6-1C10.8,7.3,10.5,6.2,9.9,6.3z M6.6,12.8C6.4,13,6.2,13.2,6,13.4
	c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.5,0.4,1.4,1.1,1.4c0.3,0,1.9,0,2.1,0c0.3-0.1,0.5-0.3,0.6-0.6c0.2-0.4,0.6-1.2,0.7-1.5
	c-0.2-0.2-0.3-0.4-0.5-0.6c-1,0.8-2.4,0.6-3.5,0.1C6.7,12.9,6.7,12.8,6.6,12.8z" fill="currentColor"/>
</svg>
        </SvgIcon>
    );
}

export default PlayerIcon;