import { iUseAccess } from "./hookInterface";
import { UserRole } from "../../user/user";
import { useUser } from "../../user/useUser";

export const useAccess = (accessRoles: UserRole[]): iUseAccess => {
    const { userRole } = useUser()
    const hasAccess = userRole !== null && accessRoles.includes(userRole)

    return {
        hasAccess
    }
}