import { FC, ReactNode, useEffect, useState } from "react";
import { getPagesAfterCurrent, getPagesBeforeCurrent } from "./paginatorFuncs";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface PaginatorProps {
    totalPages: number
    currentPage: number
}

export const Paginator: FC<PaginatorProps> = ({ totalPages, currentPage }) => {
    const { xxSmallScreen } = useCommonMediaQueries()
    const intermediatePagesCount = xxSmallScreen ? 1 : undefined
    const [ beforePages, setBeforePages ] = useState<number[]>([])
    const [ afterPages, setAfterPages ] = useState<number[]>([])
    const correctedCurrentPage = currentPage > totalPages ? totalPages : currentPage

    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        setBeforePages(correctedCurrentPage > 1 ? getPagesBeforeCurrent(correctedCurrentPage, intermediatePagesCount) : [])
        setAfterPages(correctedCurrentPage < totalPages ? getPagesAfterCurrent(correctedCurrentPage, totalPages, intermediatePagesCount) : [])
    }, [correctedCurrentPage, intermediatePagesCount, totalPages])

    const changePage = (page: number) => {
        searchParams.set('page', page.toString());

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return totalPages > 1 ? <Stack
        direction={'row'}
        gap={'4px'}
        alignItems={'center'}
        minHeight={'45px'}
        height={'100%'}
    >
        {/* First page here */}
        {correctedCurrentPage > 1 && <PageButton small={xxSmallScreen} onClick={() => {changePage(1)}}>1</PageButton>}

        {correctedCurrentPage > 1 && <Box width={'4px'}/>}

        {/* pages before current */}
        {beforePages.map(p => <PageButton small={xxSmallScreen} key={'page_' + p} onClick={() => {changePage(p)}}>{p}</PageButton>)}

        {beforePages && <Box width={'4px'}/>}

        {/* Current page */}
        <PageButton small={xxSmallScreen} active>{correctedCurrentPage}</PageButton>

        {afterPages && <Box width={'4px'}/>}

        {/* pages after current */}
        {afterPages.map(p => <PageButton small={xxSmallScreen} key={'page_' + p} onClick={() => {changePage(p)}}>{p}</PageButton>)}

        {correctedCurrentPage < totalPages && <Box width={'4px'}/>}

        {/* Last page here */}
        {correctedCurrentPage < totalPages && <PageButton small={xxSmallScreen} onClick={() => {changePage(totalPages)}}>{totalPages}</PageButton>}
    </Stack> : <></>
}

interface PageButtonProps {
    children?: ReactNode
    onClick?: () => void
    active?: boolean
    small?: boolean
}

const PageButton: FC<PageButtonProps> = ({children, onClick, active = false, small  = false}) => {
    return <Paper
        elevation={active ? 0 : 2}
        onClick={onClick}
        sx={{
            width: small ? '20px' : '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: onClick ? 'pointer' : 'default',
            userSelect: 'none',
            backgroundColor: active ? 'primary.main' : 'primary.dark',
            color: active ? 'primary.contrastText' : 'initial',
        }}
    >
        <Typography fontSize={small ? '10px' : '18px'}>{children}</Typography>
    </Paper>
}