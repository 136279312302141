// Type definition for the loader state,
// where each key represents a unique request identifier,
// and the value indicates whether the request is currently loading.
import {ApiRequest} from "./loaderService.interface";

type LoaderState = {
    [key: string]: boolean;
};

class LoaderService {
    // Singleton instance of the LoaderService
    private static instance: LoaderService;

    // Object to keep track of loading states for various API requests
    private loadingState: LoaderState = {};

    // Private constructor to prevent direct instantiation
    private constructor() {}

    /**
     * Generates a unique key by combining an API request type and its payload.
     *
     * @param key - The type of API request.
     * @param payload - The data associated with the API request.
     * @returns A string that uniquely identifies the API request with its payload.
     */
    public getKeyWithPayload(key: ApiRequest, payload: any): string {
        return key + JSON.stringify(payload);
    }

    /**
     * Returns the singleton instance of LoaderService.
     * If an instance doesn't exist, it creates one.
     */
    public static getInstance(): LoaderService {
        if (!LoaderService.instance) {
            LoaderService.instance = new LoaderService();
        }
        return LoaderService.instance;
    }

    /**
     * Sets the loading state for a given API request key.
     * @param key - Unique identifier for the API request.
     * @param isLoading - Boolean indicating whether the request is loading.
     */
    public setLoading(key: string, isLoading: boolean): void {
        this.loadingState[key] = isLoading;
    }

    /**
     * Checks if a specific API request is currently loading.
     * @param key - Unique identifier for the API request.
     * @returns Boolean indicating if the request is loading.
     */
    public isLoading(key: string): boolean {
        return !!this.loadingState[key];
    }

    /**
     * Removes the loading state for a given API request key,
     * indicating that the request has completed.
     * @param key - Unique identifier for the API request.
     */
    public finishLoading(key: string): void {
        delete this.loadingState[key];
    }
}

// Exporting the singleton instance of LoaderService
export default LoaderService.getInstance();