import { Box, Typography } from "@mui/material";
import {
    convertCentsToCurrencyString,
} from "../../../features/common_funcs";
import { CurrencyRender } from "./CurrencyRender";
import { useUser } from "../../../features/user/useUser";
import { FC } from "react";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {UserRole} from "../../../features/user/user";

interface BalanceProps {
    size?: 'small' | 'normal'
}

export const Balance: FC<BalanceProps> = ({ size = 'normal'}) => {
    const { displayCurrency, user, userRole } = useUser()
    // const { currencies } = useManageUsers()
    // const [ balance, setBalance ] = useState<number | undefined>()
    //
    // useEffect(() => {
    //     let convertedBalance: number | undefined
    //     if (user) {
    //         if (currencies !== null && displayCurrency) {
    //             const userCurrency = user.user_currency
    //             if (userCurrency) {
    //                 const foundUserCurrency = currencies.find(cur => cur.currency_name === userCurrency)
    //                 if (foundUserCurrency) {
    //                     convertedBalance = currencyConvert(user.user_balance, foundUserCurrency.exchange_rate, exchangeRate)
    //                 }
    //             }
    //         }
    //     }
    //     setBalance(convertedBalance)
    // }, [ currencies, displayCurrency, exchangeRate, user ])

    return user ? <Box display={'flex'} alignItems={'center'} gap={'15px'}>
        <Typography fontSize={size === 'small' ? '12px' : '20px'}>
            <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                {user ? (userRole !== UserRole.PLATFORM && userRole !== UserRole.OWNER) ? convertCentsToCurrencyString(user?.user_balance) : <AllInclusiveIcon/> : '--:--'}
                {displayCurrency && user && userRole !== UserRole.PLATFORM && userRole !== UserRole.OWNER &&
                <CurrencyRender currency={displayCurrency}/>}
            </Box>
        </Typography>
    </Box> : <></>
}


