export enum GeneralInfoLoaders {
    isFinancialDailyLoading,
    isDailyUsersLoading,
    isGeneralStatisticsLoading
}

export interface GeneralInfoLoadersList {
    isFinancialDailyLoading: boolean
    isDailyUsersLoading: boolean
    isGeneralStatisticsLoading: boolean
}