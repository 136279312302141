import React, { FC, useEffect, useState } from "react";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface PageSizeSwitcherProps {
    pageSize: number
}

export const PageSizeSwitcher: FC<PageSizeSwitcherProps> = ({pageSize}) => {
    const { languagePack: { pack: {common: lang}}} = useLanguage()
    const [sizes, setSizes] = useState<string[]>([
        '10', '20', '30', '40'
    ])
    const { xxSmallScreen } = useCommonMediaQueries()

    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    
    useEffect(() => {
        if (!sizes.includes(pageSize.toString())) {
            setSizes([
                ...sizes,
                pageSize.toString()
            ])
        }
    }, [pageSize, sizes])

    const handleSelect = (event: SelectChangeEvent<string>) => {
        searchParams.set('pageSize', event.target.value);

        searchParams.delete('page')

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return <Box minWidth={xxSmallScreen ? '100px' : '150px'}>
        <FormControl fullWidth size={xxSmallScreen ? 'small' : 'medium'}>
            <InputLabel>{lang.pageSizeSwitcherLabel}</InputLabel>
            <Select
                value={sizes.includes(pageSize.toString()) ? pageSize.toString() : '30'}
                label={lang.pageSizeSwitcherLabel}
                onChange={handleSelect}
            >
                {sizes.map(size => <MenuItem key={'page_size_' + size} value={size}>{size}</MenuItem>)}
            </Select>
        </FormControl>
    </Box>
};
