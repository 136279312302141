import { useLocation } from "react-router-dom";
import { useAgents } from "../agents/useAgents";
import { useEffect, useRef } from "react";
import { useNetwin } from "./useNetwin";
import { getNumberParam } from "../common_funcs";
import { NetwinRoyaltyFilter } from "./models/filter";
import { SortDirection } from "../common_models";
import { stringToNetwinRoyaltySort } from "./models/sorting";

export const useNetwinRoyaltyStatisticsWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const { selectedAgent } = useAgents()
    const prevSelectedAgent = useRef(selectedAgent)
    const { netwinRoyalty, royaltyFilter, isNetwinRoyaltyLoading, getNetwinRoyalty } = useNetwin()

    useEffect(() => {
        if (!isNetwinRoyaltyLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const qOrderBy = searchParams.get('orderBy')
            const sortDirection = searchParams.get('sortDirection')
            const qSortDirection: SortDirection | null = (sortDirection === 'DESC' || sortDirection === 'ASC') ? sortDirection : null

            const fromTimeHasChanges = qFromTime !== royaltyFilter.fromTime
            const toTimeHasChanges = qToTime !== royaltyFilter.toTime
            const usernameHasChanges = qUsername !== royaltyFilter.username
            const orderByHasChanges = qOrderBy !== royaltyFilter.orderBy
            const sortDirectionHasChanges = qSortDirection !== royaltyFilter.sortDirection
            const selectedAgentHasChanges = prevSelectedAgent.current !== selectedAgent
            const royaltyNotLoadedYet = netwinRoyalty === null

            if (fromTimeHasChanges
                || toTimeHasChanges
                || fromTimeHasChanges
                || usernameHasChanges
                || orderByHasChanges
                || sortDirectionHasChanges
                || selectedAgentHasChanges
                || royaltyNotLoadedYet) {
                // console.group('Changes useNetwinUsersWatcher')
                // console.log('fromTimeHasChanges', fromTimeHasChanges)
                // console.log('toTimeHasChanges', toTimeHasChanges)
                // console.log('selectedAgentHasChanges', selectedAgentHasChanges)
                // console.log('royaltyNotLoadedYet', royaltyNotLoadedYet)
                // console.groupEnd()

                const filterNew: NetwinRoyaltyFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    username: qUsername,
                    orderBy: qOrderBy !== null ? stringToNetwinRoyaltySort(qOrderBy) : null,
                    sortDirection: qSortDirection,
                }

                getNetwinRoyalty(selectedAgent?.user_id ?? undefined, filterNew)

                prevSelectedAgent.current = selectedAgent
            }
        }
    }, [disabled, getNetwinRoyalty, isNetwinRoyaltyLoading, location.search, netwinRoyalty, royaltyFilter, selectedAgent])
}