import React, { FC, useEffect, useState } from "react";
import { Button, Tabs} from "@mui/material";
import { usePlayersAgentsWatcher } from "../../features/manage_players_agents/usePlayersAgentsWatcher";
import { resetPlayersAgents } from "../../features/manage_players_agents/managePlayersAndAgentsSlice";
import { useAppDispatch } from "../../store";
import { usePlayersAgents } from "../../features/manage_players_agents/usePlayersAgents";
import Tab from "@mui/material/Tab";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";
import { PlayersNAgentsTable } from "../../components/players_n_agents/PlayersNAgentsTable";
import { useLanguage } from "../../features/localisation/useLanguage";
import { CreatePlayerAgentModal } from "../../components/players_n_agents/players_&_agents_creation/CreatePlayerAgent";
import { ContentPage } from "../../components/layout/content_components/ContentPage";
import { FilterPanel, FilterPanelSide } from "../../components/layout/content_components/FilterPanel";
import { PaginatorRow } from "../../components/layout/content_components/PaginatorRow";
import { MainContentZone } from "../../components/layout/content_components/MainContentZone";
import { ContentLoader } from "../../components/layout/content_components/ContentLoader";
import { SortingPlayerAgent } from "../../features/manage_players_agents/models/sorting";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { PlayersNAgentsList } from "../../components/players_n_agents/players_n_agents_list/PlayersNAgentsList";
import { UserRoleFilter } from "../../components/commons/user_role_filter/UserRoleFilter";
import {UsernameFilter, UsernameInlineFilter} from "../../components/commons/username_filter_v2/UsernameFilter";
import {TimeFilter} from "../../components-v2/commons/time_filter/TimeFilter";

export const ManagePlayersAgentsPage: FC = () => {
    const { languagePack: {pack: { playersAgents: playersAgentsLang, createUser: createUserLang, commonSortingNames: lanSorting}}} = useLanguage()
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries()
    const { updateQueryParameter } = useQuery()
    const { filter, totalPages, pageSize, currentPage, isShowUsersLoading } = usePlayersAgents()
    usePlayersAgentsWatcher()
    const dispatch = useAppDispatch()
    const [isCreateUserOpened, setIsCreateUserOpened] = useState<'agent' | 'player' | null>(null)

    useEffect(() => {
        return () => {
            dispatch(resetPlayersAgents())
        }
    }, [ dispatch ])

    const handleTabsChange = (event: React.SyntheticEvent, newValue: string | null) => {
        updateQueryParameter("userRole", newValue)
    }

    const sortingList = [
        { title: lanSorting.usernameAsc, orderBy: SortingPlayerAgent.Username, sortDirection: "ASC" },
        { title: lanSorting.usernameDesc, orderBy: SortingPlayerAgent.Username, sortDirection: "DESC" },
        { title: lanSorting.balanceAsc, orderBy: SortingPlayerAgent.Balance, sortDirection: "ASC" },
        { title: lanSorting.balanceDesc, orderBy: SortingPlayerAgent.Balance, sortDirection: "DESC" },
    ]

    return <>
        <ContentPage>
            <FilterPanel
                currentSortDirection={filter.sortDirection ?? undefined}
                currentOrderBy={filter.orderBy ?? undefined}
                sortingList={sortingList}
                mobileUnderFilterPanelZone={<UsernameInlineFilter username={filter.username} pullParamsToAddressBar/>}
            >
                <FilterPanelSide>
                    {!xxSmallScreen && <UsernameFilter username={filter.username} pullParamsToAddressBar/>}
                    {xxSmallScreen && <UserRoleFilter userRole={filter.userRole} pullParamsToAddressBar/>}
                    <TimeFilter
                        fromTime={filter.fromTime}
                        toTime={filter.toTime}
                        pullParamsToAddressBar/>

                </FilterPanelSide>

                <FilterPanelSide>
                    <Button onClick={() => setIsCreateUserOpened('agent')} variant={'contained'}
                            color={'primary'} sx={{textWrap: 'nowrap'}}>{createUserLang.createAgentBTN}</Button>
                    <Button onClick={() => setIsCreateUserOpened('player')} variant={'contained'}
                            color={'secondary'} sx={{textWrap: 'nowrap'}}>{createUserLang.createPlayerBTN}</Button>
                </FilterPanelSide>
            </FilterPanel>
            <MainContentZone withAgentsTree>
                {!xxSmallScreen && <Tabs
                    value={filter.userRole}
                    onChange={handleTabsChange}
                >
                    <Tab label={playersAgentsLang.allUsersTab} value={null}/>
                    <Tab label={playersAgentsLang.playersTab} value={"player"}/>
                    <Tab label={playersAgentsLang.agentsTab} value={"agent"}/>
                </Tabs>}
                <ContentLoader isLoading={isShowUsersLoading}/>
                {smallScreen ? <PlayersNAgentsList/> : <PlayersNAgentsTable/>}
                {!xxSmallScreen &&
                    <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
                }
            </MainContentZone>
        </ContentPage>
        {isCreateUserOpened !== null && isCreateUserOpened === 'agent' &&
            <CreatePlayerAgentModal dialogTitle={createUserLang.agentCreationTitle} role={'agent'}
                                    close={() => setIsCreateUserOpened(null)}/>}

        {isCreateUserOpened !== null && isCreateUserOpened === 'player' &&
            <CreatePlayerAgentModal dialogTitle={createUserLang.playerCreationTitle} role={'player'}
                                    close={() => setIsCreateUserOpened(null)}/>}
    </>
}