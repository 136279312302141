import React, { FC, useEffect, useState } from "react";
import { TableHeightWrapper } from "../../layout/content_components/TableHeightWrapper";
import { Alert, Box, Card, Stack, Typography } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useNetwin } from "../../../features/netwin/useNetwin";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { NetwinAgentCard } from "./NetwinAgentCard";
import { resetNetwinUsers } from "../../../features/netwin/netwinSlice";
import { useAppDispatch } from "../../../store";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";

export const NetwinAgentsList: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: {netwinUsersTable: lang}}} = useLanguage()
    const { xxSmallScreen } = useCommonMediaQueries()
    const [height, setHeight] = useState<number>(0)
    const { users, isNetwinUsersLoading, selectedAgentCommission, usersSummary } = useNetwin()

    useEffect(() => {
        return () => {
            dispatch(resetNetwinUsers())
        }
    }, [dispatch])

    return <TableHeightWrapper onHeightChanged={setHeight} currentHeight={height}>
        <Box height={height} sx={{
            backgroundColor: 'rgba(0,0,0,0.05)',
            overflowY: 'auto'
        }}>
            <Stack
                justifyContent={'space-between'}
                direction={'row'}
                gap={'5px'}
                padding={'5px'}
                flexWrap={'wrap'}
            >
                <Card sx={{ py: '10px', px: '2vw', width: '100%' }}>
                    <Stack gap={'8px'}>
                    <Stack direction={'row'} gap={'4px'} justifyContent={'space-between'}>
                        <Stack>
                            <Typography fontWeight={300} fontSize={'8px'}>
                                {lang.betsHeader}:
                            </Typography>
                            <Typography fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                                {usersSummary.userTotalBets ? convertCentsToCurrencyString(usersSummary.userTotalBets, true) : '---'}
                            </Typography>
                        </Stack>

                        <Stack alignItems={'center'}>
                            <Typography fontWeight={300} fontSize={'8px'}>
                                {lang.winsHeader}:
                            </Typography>
                            <Typography fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                                {usersSummary.userTotalWins ? convertCentsToCurrencyString(usersSummary.userTotalWins, true) : '---'}
                            </Typography>
                        </Stack>

                        <Stack alignItems={'end'}>
                            <Typography fontWeight={300} fontSize={'8px'}>
                                {lang.netwinHeader}:
                            </Typography>
                            <Typography fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                                {usersSummary.userTotalGGR ? convertCentsToCurrencyString(usersSummary.userTotalGGR, true) : '---'}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction={'row'} gap={'4px'} justifyContent={'space-between'}>
                        <Stack>
                            <Typography fontWeight={300} fontSize={'8px'}>
                                {lang.percentageHeader}:
                            </Typography>
                            <Typography fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                                {selectedAgentCommission !== null ? `${selectedAgentCommission * 100}%` : '---'}
                            </Typography>
                        </Stack>

                        <Stack alignItems={'end'}>
                            <Typography fontWeight={300} fontSize={'8px'}>
                                {lang.commissionHeader}:
                            </Typography>
                            <Typography fontWeight={700} fontSize={'12px'} lineHeight={0.8}>
                                {usersSummary.userTotalCommission ? convertCentsToCurrencyString(usersSummary.userTotalCommission, true) : '---'}
                            </Typography>
                        </Stack>
                    </Stack>
                    </Stack>
                </Card>
                {
                    users !== null && !isNetwinUsersLoading && users.map(
                        netwinUser => <Box
                            width={xxSmallScreen ? '100%' : 'calc((100% - 10px) / 2)'}
                            key={`userID-${netwinUser.agent_id}`}
                        >
                            <NetwinAgentCard agent={netwinUser} selectedAgentCommission={selectedAgentCommission}/>
                        </Box>
                    )
                }
                {  users?.length === 0 &&
                    <Alert variant="outlined" severity="warning" sx={{width: '100%'}}>
                        {lang.noUsersFoundMessage}
                    </Alert>}
            </Stack>
        </Box>
    </TableHeightWrapper>
}