import { FC, ReactNode } from "react";
import { AuthorizedZone } from "../../commons/AuthorizedZone";
import { UserRole } from "../../../features/user/user";
import { AccessZone } from "../../commons/access_zone/AccessZone";
import { Stack } from "@mui/material";

interface ContentPageProps {
    children?: ReactNode
    authorized?: boolean
    accessUserRoles?: UserRole[]
    overflow?: boolean
    padding?: string | number | undefined
    gap?: string | number | undefined
}

export const ContentPage: FC<ContentPageProps> = ({authorized = true, children, accessUserRoles, overflow, padding, gap}) => {
    return authorized ? <AuthorizedZone>
        {accessUserRoles ? <AccessZone accessUserRoles={accessUserRoles}><ContentPageLayout>{children}</ContentPageLayout></AccessZone> : <ContentPageLayout overflow={overflow} gap={gap} padding={padding}>{children}</ContentPageLayout>}
    </AuthorizedZone> : <ContentPageLayout overflow={overflow}>
        {children}
    </ContentPageLayout>
}

interface ContentPageLayoutProps {
    children?: ReactNode
    overflow?: boolean
    padding?: string | number | undefined
    gap?: string | number | undefined
}

const ContentPageLayout: FC<ContentPageLayoutProps> = ({children, overflow, padding, gap}) => {
    return <Stack
        width={'100%'}
        height={'100%'}
        overflow={overflow ? 'auto' : undefined}
        padding={padding}
        gap={gap}
        sx={{
            overflowY: overflow === undefined ? 'hidden' : undefined
        }}
    >
        {children}
    </Stack>
}