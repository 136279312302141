import { useLocation } from "react-router-dom";
import { usePlayersAgents } from "./usePlayersAgents";
import { useEffect, useRef } from "react";
import { getNumberParam } from "../common_funcs";
import { useAgents } from "../agents/useAgents";
import { ManagePlayersAgentsFilter } from "./models/filter";
import { stringToSortingPlayerAgent } from "./models/sorting";

export const usePlayersAgentsWatcher = (disabled: boolean = false) => {
    const location = useLocation()

    const { users, isShowUsersLoading, filter, loadUsers, pageSize, currentPage} = usePlayersAgents()
    const {selectedAgent} = useAgents()
    const prevSelectedAgent = useRef(selectedAgent)

    useEffect(() => {
        if  (!isShowUsersLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qUsername = searchParams.get('username')
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qOrderBy = searchParams.get('orderBy')
            const sortDirection = searchParams.get('sortDirection')

            const qSortDirection: 'DESC' | 'ASC' | null = (sortDirection === 'DESC' || sortDirection === 'ASC') ? sortDirection : null
            
            const userRoleValue = searchParams.get('userRole')
            const qUserRole: 'player' | 'agent' | null = (userRoleValue === 'player' || userRoleValue === 'agent') ? userRoleValue : null
            
            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const userRoleHasChanges = qUserRole !== filter.userRole
            const usernameHasChanges = qUsername !== filter.username
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const sortDirectionHasChanges = qSortDirection !== filter.sortDirection
            const selectedAgentHasChanges = prevSelectedAgent.current !== selectedAgent
            const usersNotLoadedYet = users === null
            
            if (
                pageHasChanges
                || pageSizeHasChanges
                || fromTimeHasChanges
                || toTimeHasChanges
                || userRoleHasChanges
                || usernameHasChanges
                || orderByHasChanges
                || sortDirectionHasChanges
                || selectedAgentHasChanges
                || usersNotLoadedYet) {
                // console.group('Changes usePlayersAgentsWatcher')
                // console.log('pageHasChanges', pageHasChanges)
                // console.log('pageSizeHasChanges', pageSizeHasChanges)
                // console.log('fromTimeHasChanges', fromTimeHasChanges)
                // console.log('toTimeHasChanges', toTimeHasChanges)
                // console.log('userRoleHasChanges', userRoleHasChanges)
                // console.log('orderByHasChanges', orderByHasChanges)
                // console.log('sortDirectionHasChanges', sortDirectionHasChanges)
                // console.log('selectedAgentHasChanges', selectedAgentHasChanges)
                // console.log('usersNotLoadedYet', usersNotLoadedYet)
                // console.groupEnd()
                
                const filterNew: ManagePlayersAgentsFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    orderBy: qOrderBy !== null ? stringToSortingPlayerAgent(qOrderBy) : null,
                    sortDirection: qSortDirection,
                    userRole: qUserRole,
                    username: qUsername,
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize

                loadUsers(pageNew, itemsPerPageNew, selectedAgent?.user_id ?? undefined, filterNew)

                prevSelectedAgent.current = selectedAgent
            }
        }
    }, [currentPage, disabled, filter, isShowUsersLoading, loadUsers, location.search, pageSize, selectedAgent, users])
}