import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useGameHistory } from "./useGameHistory";
import { getNumberParam } from "../common_funcs";
import { useAgents } from "../agents/useAgents";
import { GameHistoryFilter } from "./models/filter";
import { stringToGameHistorySort } from "./models/sorting";

export const useGameHistoryWatcher = (disabled: boolean = false) => {
    const location = useLocation()
    const { isGameHistoryLoading, filter, gameHistory, pageSize, currentPage, getGameHistory } = useGameHistory()
    const {selectedAgent} = useAgents()
    const prevSelectedAgent = useRef(selectedAgent)

    useEffect(() => {
        if (!isGameHistoryLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search)
            const qPage = getNumberParam(searchParams.get('page'))
            const qPageSize = getNumberParam(searchParams.get('pageSize'))
            const qFromTime = getNumberParam(searchParams.get('fromTime'))
            const qToTime = getNumberParam(searchParams.get('toTime'))
            const qUsername = searchParams.get('username')
            const qOrderBy = searchParams.get('orderBy')
            
            const sortDirection = searchParams.get('sortDirection')
            const qSortDirection: 'DESC' | 'ASC' | null = (sortDirection === 'DESC' || sortDirection === 'ASC') ? sortDirection : null

            const pageHasChanges = qPage !== null && qPage !== currentPage
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize
            const fromTimeHasChanges = qFromTime !== filter.fromTime
            const toTimeHasChanges = qToTime !== filter.toTime
            const usernameHasChanges = qUsername !== filter.username
            const orderByHasChanges = qOrderBy !== filter.orderBy
            const sortDirectionHasChanges = qSortDirection !== filter.sortDirection
            const selectedAgentHasChanges = prevSelectedAgent.current !== selectedAgent
            const gameHistoryNotLoadedYet = gameHistory === null

            if (pageHasChanges
                || pageSizeHasChanges
                || fromTimeHasChanges
                || toTimeHasChanges
                || usernameHasChanges
                || orderByHasChanges
                || sortDirectionHasChanges
                || selectedAgentHasChanges
                || gameHistoryNotLoadedYet) {
                // console.group('Game history changes')
                // console.log('pageHasChanges', pageHasChanges)
                // console.log('pageSizeHasChanges', pageSizeHasChanges)
                // console.log('fromTimeHasChanges', fromTimeHasChanges)
                // console.log('toTimeHasChanges', toTimeHasChanges)
                // console.log('usernameHasChanges', usernameHasChanges)
                // console.log('orderByHasChanges', orderByHasChanges)
                // console.log('selectedAgentHasChanges', selectedAgentHasChanges)
                // console.log('gameHistoryNotLoadedYet', gameHistoryNotLoadedYet)
                // console.groupEnd()

                const filterNew: GameHistoryFilter = {
                    fromTime: qFromTime,
                    toTime: qToTime,
                    username: qUsername,
                    sortDirection: qSortDirection,
                    orderBy: qOrderBy !== null ? stringToGameHistorySort(qOrderBy) : null
                }

                const pageNew = qPage !== null ? qPage : 1
                const itemsPerPageNew = qPageSize !== null ? qPageSize : pageSize
                const childID = selectedAgent !== null ? selectedAgent.user_id : null
                getGameHistory(pageNew, itemsPerPageNew, filterNew, childID)

                prevSelectedAgent.current = selectedAgent
            }
        }
    }, [currentPage, disabled, filter, gameHistory, getGameHistory, isGameHistoryLoading, location.search, pageSize, selectedAgent])
}