import Box from "@mui/material/Box";
import {AccountAvatarViewProps} from "../../../../../components-v2/commons/account_title/AccountTitle.interface";
import {FC, ReactNode} from "react";
import {Skeleton, Typography, useTheme} from "@mui/material";
import {upperCase} from "lodash";

export const AccountAvatarView: FC<AccountAvatarViewProps> = ({letter, color}) => {
    const theme = useTheme()
    const textColor = theme.palette.getContrastText(color ?? theme.palette.background.default);

    return <AvatarLoader size={'40px'} backgroundColor={color ?? theme.palette.background.default} loadMode={color === undefined}>
        <Typography fontSize={24} fontWeight={600} color={textColor}>
            {letter !== undefined ? upperCase(letter) : '?'}
        </Typography>
    </AvatarLoader>
}

interface AvatarLoaderProps {
    children: ReactNode
    loadMode: boolean
    backgroundColor: string
    size: string
}

const AvatarLoader: FC<AvatarLoaderProps> = ({children, loadMode, backgroundColor, size}) => loadMode ?
    <Skeleton
        height={size}
        width={size}
        variant="circular">
        {children}
    </Skeleton> :
    <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={size}
        width={size}
        borderRadius={size}
        sx={{backgroundColor}}>
        {children}
    </Box>