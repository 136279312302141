import React, { FC, ReactElement } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface BalanceTooltipProps {
    children: ReactElement<any, any>
    balance_before: number
    balance_after: number
}

export const BalanceTooltip: FC<BalanceTooltipProps> = ({children, balance_before, balance_after}) => {
    const {languagePack: {pack: {balanceTooltip: lang}}} = useLanguage()

    return <Tooltip
        title={
            <Stack alignItems={'end'}>
                <Stack direction={'row'}><Typography fontWeight={600}>{lang.before}:&nbsp;</Typography><Typography
                    fontWeight={300}>{convertCentsToCurrencyString(balance_before, true)}</Typography></Stack>
                <Stack direction={'row'}><Typography fontWeight={600}>{lang.after}:&nbsp;</Typography><Typography
                    fontWeight={300}>{convertCentsToCurrencyString(balance_after, true)}</Typography></Stack>
            </Stack>
        }
        placement={'top'}
        arrow
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [ 0, -34 ],
                        },
                    },
                ],
            },
        }}
    >
        {children}
    </Tooltip>
}