import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
} from 'date-fns';
import {getWeekStartDayFromTimeZone} from "../../common_funcs";


export const definedsDateRanges = {
    startOfWeek: startOfWeek(new Date(), { weekStartsOn: getWeekStartDayFromTimeZone() }),
    endOfWeek: endOfWeek(new Date(), { weekStartsOn: getWeekStartDayFromTimeZone() }),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: getWeekStartDayFromTimeZone() }),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: getWeekStartDayFromTimeZone() }),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};