import { ApiResponse } from "../apiResponseModels";

export interface Agent {
    user_id: number;
    user_name: string;
    banned: boolean;
    has_agents: boolean;
}

export interface AgentNode {
    rootNode: boolean
    childIds?: number[] // children's ids of the owner
    agent_user_id: number // node owner
    hasChildren: boolean
    initialExpanded: boolean
}

export interface AgentsState {
    agents: Agent[] | null; // this is a flat array of agents
    agentsNodes: AgentNode[] | null
    agentsLoadingIds: number[] // this array contains ids of agents which are loading now. Just check by id in this array and if true then this agent is loading now (when user expands agent node)
    selectedAgent: Agent | null
    agentFilter: string | null
    isAgentSearchLoading: boolean
}

export interface iUseAgents extends AgentsState {
    getAgentChildren: (agentId?: number, onSuccess?: () => void) => void
    findAgent: (agentName: string) => void
}

export interface ShowAgentsResponse extends ApiResponse {
    agents?: Agent[]
}

export interface FindAgentResponse extends ApiResponse {
    parents?: Agent[]
}

export enum AgentLoaders {
    isAgentSearchLoading
}