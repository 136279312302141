import { FC } from "react";
import { useUser } from "../../../features/user/useUser";
import { LoginForm } from "../../commons/LoginForm";
import { Box, Typography } from "@mui/material";
import { chooseName } from "../../../features/user/userFuncs";
import { Link } from "react-router-dom";
import { AccountAvatar } from "../../commons/account_avatar/AccountAvatar";
import LanguageSelector from "../../commons/lang/LanguageSelector";
import { Balance } from "../../commons/balance/Balance";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const Header: FC = () => {
    const { token, user } = useUser()
    const { xxSmallScreen } = useCommonMediaQueries()

    return <Box
        component={'header'}
        minHeight={xxSmallScreen ? '36px' : '64px'}
        maxHeight={xxSmallScreen ? '36px' : undefined}
        display={'flex'}
        alignItems={'center'}
        columnGap={'22px'}
        justifyContent={'flex-end'}
        width={'100%'}
        padding={'10px'}
        sx={{
            // backgroundColor: '#f13b3b',
            overflowY: 'hidden'
        }}
    >
        {token === null ? <LoginForm/> :
            <>
                {/*{user && <>*/}
                {/*    <GlobalCurrencySwitcher/>*/}
                <Balance size={xxSmallScreen ? 'small' : undefined}/>
                {/*</>}*/}
                <Typography fontFamily={'Poppins'} fontSize={xxSmallScreen ? '14px' : '20px'} fontWeight={xxSmallScreen ? '500' : '600'}>
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/profile/'}>
                        <Box display={'flex'} columnGap={'10px'} alignItems={'center'}>
                            {chooseName(user)}
                            {user && <AccountAvatar user={user} size={xxSmallScreen ? 24 : undefined}/>}
                        </Box>
                    </Link>
                </Typography>
            </>
        }
        {!xxSmallScreen && <LanguageSelector/>}
    </Box>
}