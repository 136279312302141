import { iUseLanguage, Lang } from "./useLanguageInterface";
import { useCallback } from "react";
import { ApiError } from "../apiResponseModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { changeLang } from "./LanguageSlice";
import { getLangPackByName } from "./languageFuncs";
import { addNewError } from "../apiErrors/errorSlice";
import langStorage from "../../localStorages/langStorage";
import axios, { AxiosError } from "axios";
import { useSnackbar, VariantType } from "notistack";

export const useLanguage = (): iUseLanguage => {
    const dispatch = useAppDispatch()
    const languagePack = useAppSelector(state => state.lang.languagePack)
    const { enqueueSnackbar } = useSnackbar();

    const sendSnackBar = useCallback((variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar])

    const switchLanguageFromStore = useCallback(() => {
        const lang = langStorage.getLang()
        if (lang) {
            const langPack = getLangPackByName(lang)
            dispatch(changeLang(langPack))
            document.documentElement.lang = langPack.langShort
        }
    }, [dispatch])

    const switchLanguage = useCallback((lang: Lang) => {
        const langPack = getLangPackByName(lang)
        dispatch(changeLang(langPack))
        langStorage.setLang(lang)
        document.documentElement.lang = langPack.langShort
    }, [dispatch])

    const setLocalizedError = useCallback((error: ApiError) => {
        const errorMessages = JSON.parse(JSON.stringify(languagePack.pack.errorMessages))
        const errorCode = error.code.toString();

        if (errorMessages.hasOwnProperty(errorCode)) {
            dispatch(addNewError({
                ...error,
                message: errorMessages[errorCode]
            } as ApiError))
        } else {
            dispatch(addNewError(error))
        }
    }, [dispatch, languagePack.pack.errorMessages])

    const handleNetworkErrors = useCallback((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
            if (error.code === "ERR_NETWORK") {
                sendSnackBar('error', languagePack.pack.internalErrorMessages.networkError)
            } else {
                console.error('axios error', error)
            }
        } else {
            console.error('not axios error', error)
        }
    }, [languagePack, sendSnackBar])

    const getLocalizedRoleName = useCallback((role: string) => {
        const rolePack = languagePack.pack.userRoles
        switch (role) {
            case 'platform':
                return rolePack.platform
            case 'owner':
                return  rolePack.owner
            case 'superadmin':
                return rolePack.superadmin
            case 'admin':
                return rolePack.admin
            case 'agent':
                return rolePack.agent
            case 'cashier':
                return rolePack.cashier
            case 'player':
                return rolePack.player
            default:
                return role
        }
    }, [languagePack.pack.userRoles])

    return { switchLanguageFromStore, languagePack, switchLanguage, setLocalizedError, handleNetworkErrors, getLocalizedRoleName }
}