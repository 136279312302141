import { FC } from "react";
import { useQuery } from "../../../features/common_funcs/query_hook/useQuery";
import { MenuItem, Select, FormControl } from '@mui/material';
import { alpha } from '@mui/system';
import { useLanguage } from "../../../features/localisation/useLanguage";

interface TransactionTypeFilterProps {
    typeTransaction: 'deposit' | 'withdraw' | null
    onTypeTransactionChanged?: (typeTransaction: 'deposit' | 'withdraw' | null) => void
    pullParamsToAddressBar?: boolean
}

export const TransactionTypeFilter: FC<TransactionTypeFilterProps> = ({ typeTransaction, onTypeTransactionChanged = () => {}, pullParamsToAddressBar = false }) => {
    const { batchUpdateQueryParameters } = useQuery()
    const { languagePack: {pack: {agentTransfersTable: lang}}} = useLanguage()

    const handleSetTypeTransaction = (typeTransaction: 'deposit' | 'withdraw' | null) => {
        onTypeTransactionChanged(typeTransaction)
        if (pullParamsToAddressBar) {
            batchUpdateQueryParameters([
                { paramName: 'typeTransaction', value: typeTransaction },
                { paramName: 'page', value: '1' }
            ])
        }
    }

    return <FormControl sx={theme => ({
        minWidth: '130px',
        justifyContent: 'center',
        '& .MuiInput-root': {
            color: 'primary.main'
        },
        '& .MuiInput-root::before, .MuiInput-root:hover:not(.Mui-disabled, .Mui-error)::before': {
            borderBottomColor: 'primary.main',
        },
        '& .MuiSvgIcon-root': {
            color: 'primary.main'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: alpha(theme.palette.primary.main, 0.03),
        },
    })} size="small" color="primary" variant="standard">
        <Select
            value={typeTransaction || 'all'}
            onChange={(event) => {
                const value = event.target.value;
                const typeTransaction: 'deposit' | 'withdraw' | null = value === 'deposit' || value === 'withdraw' ? value : null;
                handleSetTypeTransaction(typeTransaction);
            }}
        >
            <MenuItem value="all">{lang.allTypeTab}</MenuItem>
            <MenuItem value="deposit">{lang.depositsTab}</MenuItem>
            <MenuItem value="withdraw">{lang.withdrawalsTab}</MenuItem>
        </Select>
    </FormControl>
}