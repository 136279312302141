import React, {FC, useEffect, useState} from "react";
import {Widget} from "../../commons/widget_layout/Widget";
import {Box, CircularProgress, useTheme} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {useGeneralInfo} from "../../../features/general_info/useGeneralInfo";
import {useUser} from "../../../features/user/useUser";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {mergeUserStatistics} from "../../../features/general_info/funcs";
import {CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {UsernameFilter} from "../../../components/commons/username_filter_v2/UsernameFilter";
import {TimeFilter} from "../../commons/time_filter/TimeFilter";
import {
    MiniButton,
    MoneySurfaceView,
    PlayerIcon,
    UserAddIcon,
} from "../../../themes/theme_connector";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface UsersDailyWidgetProps {
    height?: string
}

export const UsersDailyWidget: FC<UsersDailyWidgetProps> = ({height}) => {
    const theme = useTheme()
    const [widgetWidth, setWidgetWidth] = useState<number>(0);
    const { dailyUsersInfo, getUsersDaily, dailyUsersFilter, dailyUsers, dailyActiveUsers, loaders: {isDailyUsersLoading}} = useGeneralInfo()
    const [justMounted, setJustMounted] = useState(true)
    const { user } = useUser()
    const { languagePack: {pack: {generalInfo: lang}}} = useLanguage()
    const { xxSmallScreen: xs } = useCommonMediaQueries()

    const count = 2
    const itemWidth = `calc((100% - (${count - 1} * ${theme.spacing(1)})) / ${count})`

    useEffect(() => {
        if (user !== null && justMounted && !isDailyUsersLoading && dailyUsers === null) {
            getUsersDaily(dailyUsersFilter)
            setJustMounted(false)
        }
    }, [dailyUsers, dailyUsersFilter, getUsersDaily, isDailyUsersLoading, justMounted, user]);

    const groupedData = dailyUsers !== null && dailyActiveUsers !== null ? mergeUserStatistics(dailyUsers, dailyActiveUsers) : []

    const handleUsernameChange = (username: string | null) => {
        const newFilter = {...dailyUsersFilter, username}
        getUsersDaily(newFilter)
    }

    const handleTimeChange = (fromTime: number | null, toTime: number | null) => {
        getUsersDaily({ ...dailyUsersFilter, fromTime, toTime })
    }

    return <Widget
        setWidgetWidth={setWidgetWidth}
        height={height}
        headerTitle={lang.usersDailyWidgetLabel}
        headerActions={<>
            <MiniButton
                onClick={() => getUsersDaily(dailyUsersFilter)}
                icon={isDailyUsersLoading ? <CircularProgress size={'18px'}/> : <AutorenewIcon/>}
                buttonVariant={'outlined'}
            />
        </>}
    >
        <Box display={'flex'} flexDirection={xs ? 'column' : 'row'} gap={1}>
            <Box flex={1} display={'flex'}>
                <UsernameFilter username={dailyUsersFilter.username} onUsernameChanged={handleUsernameChange}/>
            </Box>
            <Box flex={1} display={'flex'}>
                <TimeFilter
                    fromTime={dailyUsersFilter.fromTime}
                    toTime={dailyUsersFilter.toTime}
                    onTimeChanged={handleTimeChange}
                />
            </Box>
        </Box>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={groupedData}
                    margin={{
                        top: 20,
                        right: 2,
                        bottom: 20,
                        left: 2,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 2"/>
                    <XAxis dataKey="calendar_day" stroke={theme.palette.text.primary}/>
                    <YAxis yAxisId="left" width={widgetWidth >= 700 ? 80 : 50} stroke={theme.palette.text.primary} tick={{ fontSize: widgetWidth >= 700 ? 14 :10 }}/>
                    <Tooltip />
                    <Legend />
                    <Line connectNulls name={lang.activeUsersLabel} yAxisId="left" type="monotone" dataKey="active_players" stroke={theme.palette.customColors.usersDailyChart.activePlayersColor} strokeWidth={3}/>
                    <Line connectNulls name={lang.newPlayersLabel} yAxisId="left" type="monotone" dataKey="new_players" stroke={theme.palette.customColors.usersDailyChart.newPlayersColor} strokeWidth={3}/>
                </ComposedChart>
            </ResponsiveContainer>
            <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                <Box width={itemWidth}>
                    <MoneySurfaceView
                        size={'small'}
                        variant={'outlined'}
                        title={lang.activeUsersLabel}
                        icon={PlayerIcon}
                        value={dailyUsersInfo.totalActiveUsers}
                    />
                </Box>
                <Box width={itemWidth}>
                    <MoneySurfaceView
                        size={'small'}
                        variant={'outlined'}
                        title={lang.newPlayersLabel}
                        icon={UserAddIcon}
                        value={dailyUsersInfo.totalNewUsers}
                    />
                </Box>
            </Box>
    </Widget>
}