import React, { FC } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { GameHistory } from "../../../features/game_history/models/models";

interface GameHistoryCardProps {
    game: GameHistory
}

export const GameHistoryCard: FC<GameHistoryCardProps> = ({game}) => {
    const { languagePack: {pack: {gamesHistory: lang}}} = useLanguage()

    return <Card sx={{py: '10px', px: '2vw'}}>
        <Stack gap={'5px'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.userNameTableHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                        {game.user_name}
                    </Typography>
                </Stack>

                <Stack alignItems={'end'}>
                    <Typography fontWeight={400} fontSize={'10px'}>
                        {game.time.split(' ')[0]}
                    </Typography>
                    <Typography fontWeight={600} fontSize={'16px'} lineHeight={0.8}>
                        {game.time.split(' ')[1].split(':').splice(0,2).join(':')}
                    </Typography>
                </Stack>
            </Stack>

            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} py={'10px'}>
                <Stack>
                    <Typography fontWeight={300} fontSize={'18px'} lineHeight={0.8} textAlign={'center'}>
                        {game.game_name}
                    </Typography>
                    <Typography fontWeight={500} fontSize={'15px'} lineHeight={0.8} textAlign={'right'}>
                        {game.provider_name}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack>
                <Typography fontWeight={300} fontSize={'10px'}>
                    {lang.betAmountTableHeader}:
                </Typography>
                <Typography color={'secondary'} fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                    {game.withdraw_sum ? convertCentsToCurrencyString(game.withdraw_sum) : '--'}
                </Typography>
            </Stack>

            <Stack>
                <Typography fontWeight={300} fontSize={'10px'}>
                    {lang.winAmountTableHeader}:
                </Typography>
                <Typography color={'primary'} fontWeight={700} fontSize={'15px'} lineHeight={0.8}>
                    {game.deposit_sum ? convertCentsToCurrencyString(game.deposit_sum) : '--'}
                </Typography>
            </Stack>
        </Stack>
    </Card>
}