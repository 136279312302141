import {GeneralInfoState} from "./models/state";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoaderAction} from "../common_models";
import {GeneralInfoLoaders} from "./models/loaders";
import {DailyUsersFilter, DailyWagersFilter} from "./models/filter";
import {ActiveUserStatistic, DailyWagerReport, NewUserStatistic, UserCounter} from "./models/model";
import _ from 'lodash';

const initialState: GeneralInfoState = {
    dailyWagers: null,
    dailyWagersInfo: {
        totalBets: 0,
        totalGGR: 0
    },
    dailyWagersFilter: {
        fromTime: null,
        toTime: null,
        username: null
    },
    dailyUsersFilter: {
        fromTime: null,
        toTime: null,
        username: null
    },
    dailyUsers: null,
    dailyUsersInfo: {
        totalActiveUsers: 0,
        totalNewUsers: 0
    },
    dailyActiveUsers: null,
    generalStatistics: null,
    revshare: null,
    activeUsersTotal: 0,
    loaders: {
        isFinancialDailyLoading: false,
        isDailyUsersLoading: false,
        isGeneralStatisticsLoading: false
    }
}

export const GeneralInfoSlice = createSlice({
    name: 'generalInfo',
    initialState: _.cloneDeep(initialState),
    reducers: {
        resetGeneralInfo: (state) => {
            state.dailyWagers = null
            state.dailyWagersInfo = {
                totalBets: 0,
                totalGGR: 0
            }
            state.dailyWagersFilter = {
                fromTime: null,
                toTime: null,
                username: null
            }
            state.dailyUsersFilter = {
                fromTime: null,
                toTime: null,
                username: null
            }
            state.dailyUsers = null
            state.dailyUsersInfo = {
                totalActiveUsers: 0,
                totalNewUsers: 0
            }
            state.dailyActiveUsers = null
            state.generalStatistics = null
            state.revshare = null
            state.activeUsersTotal = 0
            state.loaders = {
                isFinancialDailyLoading: false,
                isDailyUsersLoading: false,
                isGeneralStatisticsLoading: false
            }
        },
        setLoader: (state, action: PayloadAction<LoaderAction<GeneralInfoLoaders>>) => {
            switch (action.payload.loader) {
                case GeneralInfoLoaders.isFinancialDailyLoading:
                    state.loaders.isFinancialDailyLoading = action.payload.isLoading
                    break
                case GeneralInfoLoaders.isDailyUsersLoading:
                    state.loaders.isDailyUsersLoading = action.payload.isLoading
                    break
                case GeneralInfoLoaders.isGeneralStatisticsLoading:
                    state.loaders.isGeneralStatisticsLoading = action.payload.isLoading
                    break
            }
        },
        setDailyWagers: (state, action: PayloadAction<DailyWagerReport[]>) => {
            state.dailyWagers = action.payload
        },
        setDailyWagersFilter: (state, action: PayloadAction<DailyWagersFilter>) => {
            state.dailyWagersFilter = action.payload
        },
        setDailyUsers: (state, action: PayloadAction<NewUserStatistic[]>) => {
            state.dailyUsers = action.payload
        },
        setDailyActiveUsers: (state, action: PayloadAction<ActiveUserStatistic[]>) => {
            state.dailyActiveUsers = action.payload
        },
        setDailyUsersFilter: (state, action: PayloadAction<DailyUsersFilter>) => {
            state.dailyUsersFilter = action.payload
        },
        setActiveUsers: (state, action: PayloadAction<number>) => {
            state.activeUsersTotal = action.payload
        },
        setGeneralStatistics: (state, action: PayloadAction<UserCounter[]>) => {
            state.generalStatistics = action.payload
        },
        setRevshare: (state, action: PayloadAction<number | null>) => {
            state.revshare = action.payload
        },
        setDailyWagersInfo: (state, action: PayloadAction<{ totalBets: number, totalGGR: number }>) => {
            state.dailyWagersInfo = action.payload
        },
        setDailyUsersInfo: (state, action: PayloadAction<{ totalActiveUsers: number, totalNewUsers: number }>) => {
            state.dailyUsersInfo = action.payload
        }
    }
})

export const {
    setLoader,
    setDailyWagers,
    setDailyWagersFilter,
    setDailyUsers,
    setDailyActiveUsers,
    setDailyUsersFilter,
    setActiveUsers,
    setGeneralStatistics,
    setRevshare,
    setDailyWagersInfo,
    setDailyUsersInfo,
    resetGeneralInfo
} = GeneralInfoSlice.actions