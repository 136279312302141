import { FC } from "react";
import { useQuery } from "../../../features/common_funcs/query_hook/useQuery";
import { FormControl, MenuItem, Select } from "@mui/material";
import { alpha } from "@mui/system";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface UserRoleFilterProps {
    userRole: 'player' | 'agent' | null
    onUserRoleChanged?: (typeTransaction: 'player' | 'agent' | null) => void
    pullParamsToAddressBar?: boolean
}

export const UserRoleFilter: FC<UserRoleFilterProps> = ({userRole, onUserRoleChanged= () => {}, pullParamsToAddressBar = false}) => {
    const { languagePack: {pack: {playersAgents: lang}}} = useLanguage()
    const { batchUpdateQueryParameters } = useQuery()

    const handleSetUserRole = (userRole: 'player' | 'agent' | null) => {
        onUserRoleChanged(userRole)
        if (pullParamsToAddressBar) {
            batchUpdateQueryParameters([
                { paramName: 'userRole', value: userRole },
                { paramName: 'page', value: '1' }
            ])
        }
    }

    return <FormControl sx={theme => ({
        minWidth: '130px',
        justifyContent: 'center',
        '& .MuiInput-root': {
            color: 'secondary.main'
        },
        '& .MuiInput-root::before, .MuiInput-root:hover:not(.Mui-disabled, .Mui-error)::before': {
            borderBottomColor: 'secondary.main',
        },
        '& .MuiSvgIcon-root': {
            color: 'secondary.main'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.03),
        },
    })} size="small" color="secondary" variant="standard">
        <Select
            value={userRole || 'all'}
            onChange={(event) => {
                const value = event.target.value;
                const userRole: 'player' | 'agent' | null = value === 'player' || value === 'agent' ? value : null;
                handleSetUserRole(userRole);
            }}
        >
            <MenuItem value="all">{lang.allUsersTab}</MenuItem>
            <MenuItem value="player">{lang.playersTab}</MenuItem>
            <MenuItem value="agent">{lang.agentsTab}</MenuItem>
        </Select>
    </FormControl>
}