import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertUnixToCompactLocaleDate } from "../common_funcs";
import { GameHistoryFilter } from "./models/filter";
import { GameHistoryState } from "./models/state";
import { GameHistory } from "./models/models";
import { v4 as uuidv4 } from "uuid";

const initialState: GameHistoryState = {
    gameHistory: null,
    pageSize: 30,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    isGameHistoryLoading: false,
    filter: {
        fromTime: null,
        toTime: null,
        username: null,
        orderBy: null,
        sortDirection: null
    }
}

export const GameHistorySlice = createSlice({
    name: 'gameHistory',
    initialState,
    reducers: {
        // Resetting current slice (e.g., when the table component is closed).
        clearGameHistory: (state) => {
            state.gameHistory = null
            state.pageSize = 30
            state.currentPage = 1
            state.totalRecords = 0
            state.totalPages = 0
            state.filter = {
                fromTime: null,
                toTime: null,
                username: null,
                orderBy: null,
                sortDirection: null
            }
        },
        setGameHistory: (state, action: PayloadAction<GameHistory[]>) => {
            state.gameHistory = action.payload.map((game) => ({
                ...game,
                localUUID: uuidv4(),
                time: convertUnixToCompactLocaleDate(game.time_unix),
            }))
        },
        setCurrentGameHistoryPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setTotalGameHistoryRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setGameGamePageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setGameHistoryLoading: (state, action: PayloadAction<boolean>) => {
            state.isGameHistoryLoading = action.payload
        },
        // Setting the filter for searching game history records.
        setGameHistoryFilter: (state, action: PayloadAction<GameHistoryFilter>) => {
            state.filter = action.payload
        },
    }
})

export default GameHistorySlice.reducer

export const {
    setGameHistory,
    setCurrentGameHistoryPage,
    setGameGamePageSize,
    setTotalGameHistoryRecords,
    setGameHistoryLoading,
    clearGameHistory,
    setGameHistoryFilter
} = GameHistorySlice.actions