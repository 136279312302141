import {FC} from "react";
import {EditTimeButtonViewProps} from "../../../../../components-v2/commons/time_filter/TimeFilter.interface";
import {Box, Button} from "@mui/material";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const EditTimeButtonView:FC<EditTimeButtonViewProps> = ({label, onClick, isActive = false}) => {
    return <Box
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
    >
        <Button
            sx={{
                padding: 1,
                justifyContent: 'space-between',
                '& .MuiButton-startIcon': {
                    mx: 0,
                },
                '& .MuiButton-startIcon > svg': {
                    fontSize: '24px'
                }
            }}
            fullWidth
            onClick={onClick}
            variant={'outlined'}
            startIcon={<CalendarMonthRoundedIcon color={'primary'}/>}
            endIcon={<KeyboardArrowDownIcon sx={{transform: `scaleY(${isActive ? '-1' : '1'})`}}/>}
        >
            {label}
        </Button>
    </Box>
}