import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif'
    },
    palette: {
        mode: 'dark',
        primary: {
            light: '#F7E8D3',
            main: '#E1BF7D',
            dark: '#BD8545',
            contrastText: '#18171F'
        },
        secondary: {
            light: '#ee9e58',
            main: '#d0813a', // Cyan
            dark: '#b96a23',
            contrastText: '#fff'
        },
    },
});