import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
    Alert,
    Box, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { useNetwin } from "../../features/netwin/useNetwin";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useAppDispatch } from "../../store";
import { resetNetwinUsers } from "../../features/netwin/netwinSlice";
import { HeaderWithSubtitle } from "../commons/table/header_with_subtitle/HeaderWithSubtitle";
import { SortedCellsNetwinUsers } from "./SortedCellsNetwinUsers";
import { NetwinUserSort } from "../../features/netwin/models/sorting";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";
import { SortDirection } from "../../features/common_models";

export const NetwinUsersTable: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: {netwinUsersTable: lang}}} = useLanguage()
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0)
    const { users, isNetwinUsersLoading, selectedAgentCommission, usersSummary, usersFilter: {orderBy, sortDirection }} = useNetwin()
    const { batchUpdateQueryParameters } = useQuery()

    const updateHeight = useCallback(() => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight
            if (height !== newHeight) {
                setHeight(newHeight)
            }
        }
    }, [height])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);
    
    useEffect(() => {
        return () => {
            dispatch(resetNetwinUsers())
        }
    }, [dispatch])

    const handleClickSorting = (sort: NetwinUserSort, sortDirection: SortDirection = 'DESC') => {
        batchUpdateQueryParameters([
            {paramName: 'orderBy', value: sort},
            {paramName: 'sortDirection', value: sortDirection}
        ])
    }

    return <Box
        height={'100%'}
        ref={wrapperRef}
        flexGrow={1}
    >
        <TableContainer sx={{
            maxHeight: `${height}px`,
            '& .MuiTableCell-head': {
                verticalAlign: 'top'
            },
        }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {/*<TableCell align="left">ID</TableCell>*/}
                        <TableCell align="left">
                            <SortedCellsNetwinUsers
                                orderName={NetwinUserSort.agent_name}
                                currentOrderBy={orderBy}
                                sortDirection={sortDirection ?? 'DESC'}
                                onClick={handleClickSorting}
                            >
                            {lang.nameHeader}
                            </SortedCellsNetwinUsers>
                        </TableCell>
                        <TableCell align="left">

                                <HeaderWithSubtitle
                                    title={
                                    <SortedCellsNetwinUsers
                                        orderName={NetwinUserSort.user_losses}
                                        currentOrderBy={orderBy}
                                        sortDirection={sortDirection ?? 'DESC'}
                                        onClick={handleClickSorting}
                                    >
                                        {lang.betsHeader}
                                    </SortedCellsNetwinUsers>
                                }
                                    subtitle={usersSummary.userTotalBets ? `${convertCentsToCurrencyString(usersSummary.userTotalBets, true)} ARS` : '---'}
                                />

                        </TableCell>
                        <TableCell align="left">
                            <HeaderWithSubtitle
                                title={
                                    <SortedCellsNetwinUsers
                                        orderName={NetwinUserSort.user_wins}
                                        currentOrderBy={orderBy}
                                        sortDirection={sortDirection ?? 'DESC'}
                                        onClick={handleClickSorting}
                                    >
                                        {lang.winsHeader}
                                    </SortedCellsNetwinUsers>}
                                subtitle={usersSummary.userTotalWins ? `${convertCentsToCurrencyString(usersSummary.userTotalWins, true)} ARS` : '---'}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <HeaderWithSubtitle
                                title={
                                    <SortedCellsNetwinUsers
                                        orderName={NetwinUserSort.ggr}
                                        currentOrderBy={orderBy}
                                        sortDirection={sortDirection ?? 'DESC'}
                                        onClick={handleClickSorting}
                                    >
                                        { lang.netwinHeader }
                                    </SortedCellsNetwinUsers>}
                                subtitle={usersSummary.userTotalGGR ? `${convertCentsToCurrencyString(usersSummary.userTotalGGR, true)} ARS` : '---'}
                            />
                        </TableCell>
                        <TableCell align="center">
                            <HeaderWithSubtitle
                                title={
                                <SortedCellsNetwinUsers
                                    orderName={NetwinUserSort.agent_commission}
                                    currentOrderBy={orderBy}
                                    sortDirection={sortDirection ?? 'DESC'}
                                    onClick={handleClickSorting}
                                >
                                    { lang.percentageHeader }
                                </SortedCellsNetwinUsers>
                            }
                                subtitle={selectedAgentCommission !== null ? `${selectedAgentCommission * 100}%` : '---'}
                            />
                        </TableCell>
                        <TableCell align="left">
                            <HeaderWithSubtitle
                                title={lang.commissionHeader}
                                subtitle={usersSummary.userTotalCommission ? `${convertCentsToCurrencyString(usersSummary.userTotalCommission, true)} ARS` : '---'}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users !== null && users.map(user => <TableRow key={user.agent_id}>
                        {/*<TableCell>{user.agent_id}</TableCell>*/}
                        <TableCell>{user.agent_name}</TableCell>
                        <TableCell align={'center'}>{convertCentsToCurrencyString(user.user_losses, true)}</TableCell>
                        <TableCell align={'center'}>{convertCentsToCurrencyString(user.user_wins, true)}</TableCell>
                        <TableCell align={'center'}>{convertCentsToCurrencyString(user.ggr, true)}</TableCell>
                        <TableCell align={'center'}>{user.agent_commission !== undefined ? `${user.agent_commission * 100}%` : '---'}</TableCell>
                        <TableCell align={'center'}>{selectedAgentCommission !== null ? convertCentsToCurrencyString((user.ggr * user.agent_commission), true) : '---'}</TableCell>
                    </TableRow>)}
                    { users === null && isNetwinUsersLoading &&
                        <TableRow>
                        {/*<TableCell><Skeleton animation="wave"/></TableCell>*/}
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
        { users !== null && users.length === 0 &&
            <Alert variant="outlined" severity="warning">
                {lang.noUsersFoundMessage}
            </Alert>}
    </Box>
}