import { Agent, AgentLoaders, AgentNode, AgentsState } from "./agentsModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderAction } from "../common_models";
import { User } from "../user/user";

const initialState: AgentsState = {
    agents: null,
    agentsNodes: null,
    agentsLoadingIds: [],
    selectedAgent: null,
    agentFilter: null,
    isAgentSearchLoading: false
}

export const AgentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        resetAgents: (state) => {
            state.agents = null
            state.agentsNodes = null
            state.selectedAgent = null
            state.agentsLoadingIds = []
            state.agentFilter = null
            state.isAgentSearchLoading = false
        },
        setAgentLoader: (state, action: PayloadAction<LoaderAction<AgentLoaders>>) => {
            switch (action.payload.loader) {
                case AgentLoaders.isAgentSearchLoading:
                    state.isAgentSearchLoading = action.payload.isLoading
            }
        },
        pushAgents: (state, action: PayloadAction<{ agents: Agent[], parentID: number | null }>) => {
            if (state.agents && state.agentsNodes) {
                const parentAgent = state.agents.find(agent => agent.user_id === action.payload.parentID)
                if (parentAgent) {
                    state.agents = [ ...state.agents, ...action.payload.agents ]

                    const parentAgentNode = state.agentsNodes?.find(node => node.agent_user_id === parentAgent.user_id)
                    if (parentAgentNode) {
                        parentAgentNode.childIds = action.payload.agents.map(agent => agent.user_id)
                    }
                    state.agentsNodes = [ ...state.agentsNodes, ...action.payload.agents.map(agent => ({
                        rootNode: false,
                        agent_user_id: agent.user_id,
                        hasChildren: agent.has_agents,
                        initialExpanded: false
                    })) ]
                }
            }
        },
        addAgentLoadingId: (state, action: PayloadAction<number>) => {
            state.agentsLoadingIds.push(action.payload)
        },
        removeAgentLoadingId: (state, action: PayloadAction<number>) => {
            state.agentsLoadingIds = state.agentsLoadingIds.filter(id => id !== action.payload)
        },
        setSelectedAgent: (state, action: PayloadAction<Agent | null>) => {
            state.selectedAgent = action.payload
        },
        addRootAgent: (state, action: PayloadAction<Agent>) => {
            // Поскольку корневого агента мы добавляем только один раз, так как это тот пользователь из-под которого мы залогинились,
            // то логично, что если state.agents и state.agentsNodes на этом этапе будут null
            if (state.agents === null && state.agentsNodes === null) {
                state.agents = [ action.payload ]
                state.agentsNodes = [ {
                    rootNode: true,
                    agent_user_id: action.payload.user_id,
                    hasChildren: true,
                    initialExpanded: true
                } ]
            }
        },
        addFoundAgents: (state, action: PayloadAction<Agent[]>) => {
            if (state.agents && state.agentsNodes) {
                const rootAgentNode = state.agentsNodes.find(node => node.rootNode)
                if (rootAgentNode) {
                    const rootAgent = state.agents.find(agent => agent.user_id === rootAgentNode.agent_user_id)
                    if (rootAgent) {
                        state.agents = [ rootAgent, ...action.payload ]
                        const foundAgentNodes: AgentNode[] = [ rootAgentNode ]
                        action.payload.reverse().forEach(agent => {
                            foundAgentNodes[foundAgentNodes.length - 1].childIds = [ agent.user_id ]
                            foundAgentNodes.push({
                                rootNode: false,
                                agent_user_id: agent.user_id,
                                hasChildren: agent.has_agents,
                                initialExpanded: true
                            })
                        })
                        state.agentsNodes = [ ...foundAgentNodes ]
                    }
                }
            }
        },
        setAgentFilter: (state, action: PayloadAction<string | null>) => {
            state.agentFilter = action.payload
        },
        clearAgentsTree: (state) => {
            if (state.agents && state.agentsNodes) {
                const rootAgentNode = state.agentsNodes.find(node => node.rootNode)
                if (rootAgentNode) {
                    const rootAgent = state.agents.find(agent => agent.user_id === rootAgentNode.agent_user_id)
                    if (rootAgent) {
                        state.agents = [ rootAgent ]
                        state.agentsNodes = [ rootAgentNode ]
                        state.selectedAgent = null
                        state.agentsLoadingIds = []
                        state.agentFilter = null
                    }
                }
            }
        },
        addAgentAfterCreating: (state, action: PayloadAction<User>) => {
            state.agents = [ ...state.agents ?? [], {
                user_id: action.payload.user_id,
                user_name: action.payload.user_name,
                banned: action.payload.banned,
                has_agents: false
            } ]

            if (state.agentsNodes !== null) {
                const rootNode = state.agentsNodes?.find(node => node.rootNode)
                const rootNodeIndex = state.agentsNodes?.findIndex(node => node.rootNode)
                if (rootNode && rootNodeIndex !== -1) {
                    state.agentsNodes = [
                        ...state.agentsNodes.slice(0, rootNodeIndex),
                        {
                            ...rootNode,
                            hasChildren: true,
                            childIds: [
                                ...rootNode.childIds ?? [],
                                action.payload.user_id
                            ]
                        },
                        ...state.agentsNodes.slice(rootNodeIndex + 1)
                    ]
                }
            }
        }
    }
})

export const {
    setAgentLoader,
    pushAgents,
    addRootAgent,
    setSelectedAgent,
    addAgentLoadingId,
    removeAgentLoadingId,
    addFoundAgents,
    setAgentFilter,
    clearAgentsTree,
    addAgentAfterCreating,
    resetAgents
} = AgentsSlice.actions