import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const AgentIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                 y="0px"
                 viewBox="0 0 24 24" xmlSpace="preserve">
                <path d="M14.9,5.5l-1.4,0c-1.1,0-1.4,0.1-1.4-1.2l0-0.9c0-0.7-0.2-1.7,0.7-1.7c0,0,0,0,0,0l5.1,0c0.9,0,1.4,0,1.4,1.1l0,1.4
	c0,1.2,0,1.4-1.4,1.4l-1.4,0v1.8l1.4,0c0.9,0,1.6-0.1,1.9,0.9c0.1,0.4,0.2,0.8,0.2,1.2l1,5.2c0.1,0.7,0.6,2.2,0.6,2.9l0,2.7
	c0,0.6,0.2,2.1-0.7,2.2c-0.2,0-0.4,0-0.6,0l-15.6,0l-1.3,0c-1.3,0-1.4-0.1-1.4-1.5l0-2.8c0-0.6,0-0.7,0.1-1.3L3,13.5
	c0.2-1,0.4-1.9,0.6-2.9c0.1-0.7,0.3-1.5,0.5-2.2c0.2-0.8,0.5-1,1.3-1.1l0-3c0-0.4-0.1-1.5,0.3-1.7C6,2.6,6.1,3,6.4,3.1
	c0.3,0.1,0.5-0.3,0.7-0.4C7.4,2.6,7.7,3.1,8,3.2l0,0c0.2-0.1,0.5-0.5,0.8-0.5C9,2.6,9.3,3,9.5,3.2c0.2-0.1,0.7-0.6,0.9-0.6
	c0.5,0,0.5,0.6,0.5,0.9l0,3.8h4V5.5z M6.8,4.6c-0.5,0.1-0.5,0.7,0,0.7c0.3,0,0.5,0,0.8,0l1.8,0c0.3-0.1,0.5-0.5,0.2-0.7
	c-0.2-0.1-1.3,0-1.6,0l-0.8,0C7.1,4.6,7,4.5,6.8,4.6z M6.8,6.5C6.3,6.6,6.3,7,6.6,7.2c0.2,0.1,1,0,1.2,0l1.7,0
	c0.3-0.1,0.3-0.6,0-0.7C9.4,6.4,7.2,6.5,6.8,6.5z M5.2,8.4v1.1h5.9V8.4H5.2z M12.6,10.5c-0.1,0-0.3,0-0.3,0.1
	c-0.1,0.1-0.1,1.1,0,1.2c0.1,0.1,1,0.1,1.1,0.1c0.2,0,0.3-0.1,0.4-0.2c0-0.2,0.1-0.9-0.1-1.1C13.6,10.5,12.8,10.5,12.6,10.5z
	 M17.5,10.5c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,1,0,1.2c0.1,0.1,0.8,0.1,1,0.1c0.1,0,0.4,0,0.4-0.1c0.1-0.1,0.1-1-0.1-1.2
	C18.2,10.5,17.7,10.5,17.5,10.5z M14.8,10.5c-0.4,0.1-0.3,0.9-0.2,1.1c0.1,0.3,1.1,0.2,1.3,0.2c0.4-0.2,0.3-1,0.2-1.2
	C16,10.4,15,10.5,14.8,10.5z M5.5,10.8L5.5,10.8c-0.1,0.1-0.1,0.6-0.1,0.8h4.8l0,0c0-0.2,0-0.5,0-0.8H5.5z M12.5,12.7
	c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,1,0,1.1c0.2,0.1,0.9,0.1,1.2,0.1c0.2-0.1,0.2-0.1,0.3-0.2c0-0.9,0.2-1-0.8-1.1
	C12.8,12.7,12.7,12.7,12.5,12.7z M15.1,12.7c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,1,0,1.2c0.1,0.1,0.8,0.1,1,0.1c0.1,0,0.2,0,0.3,0
	c0.2-0.2,0.2-0.9,0.1-1.1C15.9,12.6,15.3,12.7,15.1,12.7z M17.2,12.7c-0.3,0.1-0.3,1.2-0.1,1.3c0.2,0.1,1,0,1.2,0
	c0.4-0.1,0.3-0.9,0.2-1.1C18.4,12.6,17.5,12.7,17.2,12.7z M9.4,13l-2.6,0c-0.4,0-0.9,0-1.3,0c0,0.2,0,0.5,0,0.8h0.9h3.9
	c0-0.3,0-0.5,0-0.8C10,12.9,9.7,13,9.4,13z M12.5,14.8c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,1,0.1,1.1c0.2,0.1,0.9,0,1.1,0
	c0.1,0,0.2,0,0.3-0.1c0.1-0.2,0.2-0.9,0-1.1C13.5,14.8,12.7,14.8,12.5,14.8z M15.1,14.8c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,1,0,1.1
	c0.1,0.1,0.8,0.1,1,0.1c0.6,0,0.5-0.5,0.5-1.1C15.9,14.7,15.4,14.8,15.1,14.8z M17.5,14.8c-0.1,0-0.3,0-0.4,0.1
	c-0.1,0.2-0.2,1,0,1.1c0.1,0.1,0.8,0,1,0c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.9,0-1.1C18.3,14.8,17.7,14.8,17.5,14.8z M7.4,15.1H5.5
	v0.8h2.9h1.9v-0.8C9.3,15.1,8.3,15.1,7.4,15.1z M11.8,18.3c-1.4,0.2-1.2,2.3,0.3,2.1C13.6,20,13,18.1,11.8,18.3z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default AgentIcon;