import React, { FC } from "react";
import { Paginator } from "../../commons/paginator/Paginator";
import { Stack } from "@mui/material";
import { PageSizeSwitcher } from "../../commons/pageSizeSwitcher/PageSizeSwitcher";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface PaginatorRowProps {
    totalPages: number
    currentPage: number
    pageSize: number
}

export const PaginatorRow: FC<PaginatorRowProps> = ({totalPages, currentPage, pageSize}) => {
    const { xxSmallScreen } = useCommonMediaQueries()

    return <Stack
        direction={'row'}
        columnGap={'10px'}
        py={'10px'}
        px={xxSmallScreen ? 0 : '10px'}
        width={'100%'}
    >
        <Stack
            minHeight={'100%'}
            alignItems={'center'}
            direction={'row'}
            flexGrow={1}
            sx={{overflowX: 'auto'}}
        >
            <Paginator totalPages={totalPages} currentPage={currentPage}/>
        </Stack>
        {/*<Box flexGrow={1}/>*/}
        <PageSizeSwitcher pageSize={pageSize}/>
    </Stack>
}