import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const AgentIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 viewBox="0 0 24 24" xmlSpace="preserve">
<path d="M11.7,1.2c2.5-0.1,4.8,1.4,5.4,3.9c0.1,0.4,0.1,0.7,0.1,1.1c0.9,0.1,1.1,0.4,1.1,1.2c0,0.5,0,1,0,1.4c0,1,0.1,1.7-1.2,1.7
	c0,0.4,0.1,1.1-0.4,1.3c-0.4,0.1-1.4,0-1.8,0.1c-0.4,0.4-0.7,0.3-1.3,0.3c-0.6,0-1.4,0.2-1.7-0.5c-0.2-0.4,0-0.9,0.4-1.1
	c0.3-0.1,0.7-0.1,1-0.1c0.4,0,1.3-0.1,1.5,0.3c0.4,0,0.8,0,1.3,0c0-0.4,0-0.2-0.3-0.4c-0.2-0.2-0.2-0.7-0.2-1c0-0.6,0-1.1-0.1-1.7
	c-0.2-0.8-0.5-1.6-1.1-2.2c-1.2,0.9-2.6,1.1-4,0.5c-0.3-0.1-0.6-0.3-0.9-0.4l0,0C8.3,6.7,8.4,8.3,8.4,9.7c0,0.4,0.1,0.9-0.5,1
	c-0.2,0-1.2,0-1.4,0c-0.8-0.2-0.8-0.9-0.8-1.5v-1c0-1-0.2-1.8,1.1-2C6.8,3.5,9,1.4,11.7,1.2z M11.8,2.2c-2.1,0.1-4,1.8-4,4
	c0.1,0,0.2,0,0.3,0c0.3-1,0.6-1.6,1.5-2.1c0.7-0.3,0.5-0.3,1-0.8c0.5-0.5,1.1-0.7,1.8-0.7c0.8,0,1.1,0.6,1.7,0.6
	c0.9,0,1.3,0.5,0.9,1.4c0.4,0.4,0.8,0.9,0.9,1.5l0.3,0C16.1,3.7,14.2,2.1,11.8,2.2z" fill="currentColor"/>
                <path d="M9.7,13.2c0.3,0,1.9,0.9,2.3,1.1c0.3-0.2,2.2-1,2.4-1c0.4,0,0.6,0.4,0.7,0.7c0.7,1.2,2.4,2,3.6,2.7c2.1,1.2,2.6,2,3,4.5
	c0.1,0.6,0.4,1.7-0.4,1.8c-0.2,0-0.4,0-0.6,0l-5.3,0l-12.1,0c-1.3,0-1.3-0.3-1.1-1.5l0.1-0.8c0.3-1.9,1-2.9,2.6-3.8L6.2,16
	c0.9-0.5,2-1.1,2.6-2C9,13.6,9.1,13.2,9.7,13.2z M12,14.6c-0.3,0.2-0.6,0.5-0.9,0.8c0.4,1.1,0.2,1.1,0,2.2c-0.1,0.8-0.3,1.5-0.4,2.3
	c0.1,0.2,1,1.1,1.1,1.2c0.4-0.1,1-0.9,1.2-1.2c0-0.8-0.3-1.9-0.4-2.7c-0.2-0.9-0.3-0.9,0.1-1.8C12.7,15.3,12.2,14.7,12,14.6z" fill="currentColor"/>
</svg>
        </SvgIcon>
    );
}

export default AgentIcon;