import React, { FC } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { TransferMyself } from "../../features/user_transfers/models/models";
import {useLanguage} from "../../features/localisation/useLanguage";
import {convertCentsToCurrencyString} from "../../features/common_funcs";

export const MyselfTransfersCard: FC<{transfer: TransferMyself}> = ({transfer}) => {
    const { languagePack: {pack: {agentTransfersTable: lang}}} = useLanguage()

    return <Card sx={{ py: '18px', px: '2vw' }}>
        <Stack gap={'8px'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flex={1}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.typeHeader}:
                    </Typography>
                    <Typography fontWeight={500} textTransform={'uppercase'} color={transfer.transfer_type === 'deposit' ? 'primary' : 'secondary'} textAlign={'left'}  lineHeight={0.8}>
                        {transfer.transfer_type}
                    </Typography>

                </Stack>

                <Stack flex={1} alignItems={'center'}>
                </Stack>

                <Stack flex={1} alignItems={'end'}>
                    <Typography fontWeight={400} fontSize={'10px'}>
                        {transfer.request_time.split(' ')[0]}
                    </Typography>
                    <Typography fontWeight={600} fontSize={'16px'} lineHeight={0.8}>
                        {transfer.request_time.split(' ')[1].split(':').splice(0,2).join(':')}
                    </Typography>
                </Stack>
            </Stack>


            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Stack flex={1} alignItems={'start'}>
                    <Stack>
                        <Typography fontWeight={300} fontSize={'10px'} textAlign={'center'}>
                            {lang.amountHeader}:
                        </Typography>
                        <Typography fontWeight={700} fontSize={'15px'} lineHeight={0.8} textAlign={'center'}>
                            <Box display={'flex'} alignItems={'center'}>
                                <PlayArrowIcon fontSize={'small'} sx={{transform: `scaleX(${transfer.transfer_type === 'deposit' ? '1' : '-1'})`, color: transfer.transfer_type === 'deposit' ?  '#a9d933' : '#e86708'}}/>
                                {convertCentsToCurrencyString(transfer.transfer_amount, true)}
                            </Box>

                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </Card>
}