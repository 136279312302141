import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useAgents } from "../../../features/agents/useAgents";
import { useUser } from "../../../features/user/useUser";
import { useAppDispatch } from "../../../store";
import { addRootAgent } from "../../../features/agents/AgentsSlice";
import { AgentNodeComponent } from "./AgentNode";
import { Fab, Paper } from "@mui/material";
import { AgentsSearchBox } from "./AgentsSearchBox";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import Drawer from "@mui/material/Drawer";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { TableHeightWrapper } from "../../layout/content_components/TableHeightWrapper";

export const AgentsTree: FC = () => {
    const [height, setHeight] = useState<number>(0)
    const { smallScreen } = useCommonMediaQueries()
    const {user} = useUser()
    const dispatch = useAppDispatch()
    const {getAgentChildren, agentsNodes} = useAgents()
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const [drawerIsOpened, setDrawerIsOpened] = useState<boolean>(false)

    const rootAgentNode = agentsNodes ? agentsNodes.find(node => node.rootNode) : null

    useEffect(() => {
        if (initLoad && !Boolean(rootAgentNode) && user) {
            dispatch(addRootAgent({
                user_id: user.user_id,
                user_name: user.user_name,
                banned: user.banned,
                has_agents: true
            }))
            getAgentChildren()
            setInitLoad(false)
        }
    }, [ dispatch, getAgentChildren, initLoad, rootAgentNode, user ])

    return !smallScreen ? <TableHeightWrapper withFlexGrow={false} currentHeight={height} onHeightChanged={setHeight}>
        <Box width={'16vw'} maxHeight={`${height}px}`} height={'100%'} padding={'10px'} justifySelf={'end'}>
        <Paper
            sx={{
                my: '10px',
                maxHeight: `${height - 20}px`,
                height: `${height - 20}px`,
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px'
        }}
        >
                <AgentsSearchBox/>
                <Box
                    flexGrow={1}
                    overflow={'auto'}
                    height={'100%'}
                    sx={{
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'rgba(175, 175, 175, 0.08) rgba(256, 256, 256, 0)',
                    }}
                >
                        {rootAgentNode && <AgentNodeComponent agentNode={rootAgentNode}/> }
                </Box>
        </Paper>
    </Box>
    </TableHeightWrapper> : <><Box
        position={'fixed'}
        right={'20px'}
        bottom={'20px'}
        zIndex={999}
        sx={{opacity: '0.9'}}
    >
        {!drawerIsOpened && <Fab color="secondary" onClick={() => setDrawerIsOpened(true)}>
            <AccountTreeIcon/>
        </Fab>}
    </Box>
        <Drawer
            anchor="bottom"
            open={drawerIsOpened}
            onClose={() => setDrawerIsOpened(false)}
        >
            <Box
                padding={'10px'}
                height={'100%'}
                maxHeight={'70vh'}
                display={'flex'}
                flexDirection={'column'}
                rowGap={'8px'}
            >
                <AgentsSearchBox/>
                <Box
                    overflow={'auto'}
                    height={'100%'}
                >
                    {rootAgentNode && <AgentNodeComponent agentNode={rootAgentNode}/> }
                </Box>
            </Box>
        </Drawer>
    </>
}