import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const enLang: LangPack = {
    lang: Lang.EN,
    langName: "English",
    langShort: "en",
    pack: {
        common: {
            noBTN: "No",
            yesBTN: "Yes",
            closeBTN: "Close",
            cancelBTN: "Cancel",
            applyBTN: 'Apply',
            resetBTN: 'Reset',
            addBTN: "Add",
            editBTN: "Edit",
            saveBTN: "Save",
            errorCodeTitle: "Error code",
            optionsButton: 'Options',
            pageSizeSwitcherLabel: "Rows on page",
            logOutConfirmationMessage: "Are you sure you want to log out?",
            youMustBeLoggedMessage: 'You must be logged in to view this section!',
            notEnoughRightsMessage: 'Not enough rights to access this page'
        },
        login: {
            logInBTN: 'Login',
            usernameLabel: 'Username',
            passwordLabel: 'Password',
        },
        internalErrorMessages: {
            networkError: "A network error occurred while executing the request.",
            error502: "A 502 server error occurred while executing the request."
        },
        errorMessages: {
            2: "No entries.",
            3: "User email address already existing.",
            4: "User phone number already existing.",
            5: "User already logged in.",
            6: "Wrong password.",
            7: "Empty games result.",
            8: "Result ID does not match the request id.",
            9: "Outcome request error.",
            10: "Outcome no session Id in the response.",
            11: "User already existing.",
            12: "Your token is not valid. Login again.",
            13: "You have not enough rights to perform this action.",
            14: "Userid for for this action missing.",
            15: "Money amount missing.",
            16: "Action does not exist.",
            17: "Token missing.",
            18: "Not enough money.",
            19: "You must be the parent of this user to perform this action.",
            20: "User does not exist.",
            21: "The value type for this field is wrong.",
            22: "Password missing.",
            23: "User name missing.",
            24: "Gameid missing.",
            25: "New password missing.",
            26: "Token could not be set up.",
            27: "Token could not be deleted.",
            28: "Userid missing.",
            29: "The value type for this field should be datetime in ISO.",
            30: "Could not update user ban.",
            31: "This user must be in your tree to perform this action.",
            32: "This user is banned and cannot perform any actions.",
            33: "Currency missing.",
            34: "Maximum value missing.",
            35: "Limit type must be once, daily, weekly or monthly.",
            36: "Limit type missing.",
            37: "Provider does not exist.",
            38: "Tier condition amount missing.",
            39: "Tier condition type missing.",
            40: "Tier title missing.",
            41: "Cashback for this tier is already existing.",
            42: "Cashback for this tier not existing.",
            43: "Bonus for this promocode is already existing.",
            44: "Bonus does not exist.",
            45: "Promocode missing.",
            46: "Start time missing.",
            47: "Deposit amount condition missing.",
            48: "Wager missing.",
            49: "Amount validity days missing.",
            50: "Id missing.",
            51: "Cashback type missing, must be be \"wager\" or \"deposit\".",
            52: "Cashback rate missing.",
            53: "This currency is not available.",
            54: "Ban parameter missing - true or false.",
            55: "The bank group for this currency is not valid.",
            56: "Payment method name missing.",
            57: "This user already added an account for this payment system.",
            58: "This payment method does not exist.",
            59: "This payment method does not support the currency of this user.",
            60: "This user does not have an existing account for this payment system.",
            61: "The parent of this user does not have an existing account for this payment system.",
            62: "Transfer id missing.",
            63: "Transfer with this id does not exist.",
            64: "This transfer is requested by a different parent.",
            65: "You still have a bonus which is not expired.",
            66: "This bonus promo action has not started yet.",
            67: "This bonus promo action is already over.",
            68: "This deposit does not fit the bonus requirements.",
            69: "This transfer is not pending and cannot be approved or declined.",
            70: "Approve flag (true/false) missing.",
            71: "Wrong week day for this bonus.",
            72: "The number of your deposits does not fit the bonus requirements.",
            73: "Mandatory parameters for this payment method missing or do not match the proof format.",
            74: "Deposit rate missing.",
            75: "User role missing.",
            76: "You cannot create a user with this user role.",
            77: "Fun active parameter missing.",
            78: "This function is not available for this website.",
            79: "Coins amount missing.",
            80: "Price for coins pack missing.",
            81: "Provider type is missing.",
            82: "Outcome request error.",
            83: "Provider name missing.",
            84: "Contractor name missing.",
            85: "Contractor does not exist.",
            86: "Goodies pack missing.",
            87: "Proof params missing.",
            88: "Goodies pack does not exist.",
            89: "This user cannot create agents.",
            90: "This user is disabled for depositing.",
            91: "This user is disabled for withdrawals.",
            92: "Transaction type(s) missing.",
            93: "No users found.",
            94: "Rangs missing.",
            95: "No provider with this rang.",
            96: "Cannot apply this action to providers with different types.",
            97: "No game with this rang.",
            98: "Cannot apply this action to games with different providers.",
            99: "Cannot apply this action to games with different contractors.",
            100: "User name can contain only latin letters, numbers, \".\" and \"_\" and have a length between 5 and 18 characters.",
            101: "User password can contain only latin letters, numbers, the following special charachters \"_\", \".\", \"?\", \"!\" and have a length between 5 and 18 characters.",
            102: "Wager and/or deposit condition must be greater than 0.",
            103: "This game does not exist or is switched off.",
            104: "Gitslotpark request error.",
            105: "Fun balance could not be switched.",
            106: "Money transfer entry could not be saved in the database.",
            107: "You can create users only for yourself or your agents.",
            108: "Error creating Evolution token.",
            109: "Parent and child userroles do not allow depositing.",
            110: "Parent and child userroles do not allow withdrawing.",
            111: "Could not create Ezugi token.",
            112: "Could not create Relum token.",
            113: "Cool down time for the wheel has not run out.",
            114: "Freepack not available (yet).",
            115: "Could not create Amigo token.",
            116: "Could not create Tada token error",
            117: "Could not create DreamPlay token error"
        },
        sideMenu: {
            logOutBtn: "Log Out",
            dashboard: 'Dashboard',
            users: 'Users',
            gameHistory: 'Game history',
            moneyTransfers: 'Money transfers',
            limitsDivider: 'Limits',
            limitsGeneral: 'General',
            limitsOutcome: 'Outcome',
            bonus: 'Bonus',
            paymentSystems: 'My payments',
            buyBalance: 'Buy balance',
            affiliateSystem: 'Affiliate system',
            crmSettings: 'CRM settings',
            triggerEvents: 'Trigger events',
            websiteSettings: 'Website settings',
            securityLog: 'Security log',
            royaltyStatistics: 'Royalty statistics',
            finances: 'Finances',
            providers: 'Providers',
            netwinDivider: 'Netwin',
            netwinUser: 'Agents',
            netwinGeneralInfo: 'General Info',
            agentTransfers: 'Agent Transfers',
            userTransfers: 'User transfers',
            depositsAndWithdrawals: 'Deposits and withdrawals',
            myFinances: 'My finances'
        },
        commonSortingNames: {
            sortLabel: 'Sorting',
            usernameAsc: '↑ Username',
            usernameDesc: '↓ Username',
            balanceAsc: '↑ Balance',
            balanceDesc: '↓ Balance',
            providerAsc: '↑ Provider',
            providerDesc: '↓ Provider',
            gameAsc: '↑ Game',
            gameDesc: '↓ Game',
            betAsc: '↑ Bet',
            betDesc: '↓ Bet',
            winAsc: '↑ Win',
            winDesc: '↓ Win',
            dateAsc: '↑ Date',
            dateDesc: '↓ Date',
            netwinAsc: '↑ Netwin',
            netwinDesc: '↓ Netwin',
            percentAsc: '↑ Percent',
            percentDesc: '↓ Percent'
        },
        userProfileForm: {
            userProfileTabLabel: 'User info',
            passwordTabLabel: 'Password',
            emailIsRequiredMess: 'Email is required',
            notValidEmail: 'Enter a valid email address',
            currentPassFieldLabel: "Current password",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "This field must not be empty",
            firstnameFieldLabel: "Firstname",
            lastnameFieldLabel: "Lastname",
            newPassFieldLabel: "New password",
            passNotMatchWarningMessage: "New password and retype password do not match",
            phoneFieldLabel: "Phone",
            retypePassFieldLabel: "Retype new password",
            saveInfoBTN: "Save info",
            updatePassBTN: "Update password",
            onlyRequiredFieldsLabel: "Only required fields",
        },
        userInfo: {
            registeredLabel: 'Registered',
            roleLabel: 'Role',
            balanceLabel: 'Balance',
            firstNameLabel: 'Firstname',
            lastnameLabel: 'Lastname',
            phoneLabel: 'Phone',
            emailLabel: 'Email',
            depositMoneyBtn: 'Deposit money to user',
            withdrawMoneyBtn: 'Withdraw money from user',
            moneyTransfersBtn: 'Money transfers',
            gamesHistoryBtn: 'Games history',
            banUserLabel: 'Ban user',
            banUserStateBanned: 'Banned',
            banUserStateActive: 'Active'
        },
        createUser: {
            title: "Create new user",
            usernameLabel: "Username",
            createBtn: "Create",
            agentCreationTitle: "Create new agent",
            playerCreationTitle: "Create new player",
            agentEditingTitle: "Edit agent",
            playerEditingTitle: "Edit player",
            agentSuccessfullyCreatedMessage: "You have successfully created an agent.",
            playerSuccessfullyCreatedMessage: "You have successfully created a player.",
            createAgentBTN: "Create agent",
            createPlayerBTN: "Create player",
            editAgentBTN: "Save agent",
            editPlayerBTN: "Save player",
            depositEnabledSwitcherLabel: 'Deposit enabled',
            withdrawEnabledSwitcherLabel: 'Withdraw enabled',
            onlyPlayersSwitcherLabel: 'Only players',
        },
        moneyInput: {
            maxAmountMessage: "Maximum value",
            exceedAmountError: "Maximum value exceeded.",
            moreThanOneDotError: "You made an extra point.",
            moreThanTwoDecimals: "You entered more than two decimal places."
        },
        depositMoneyToUser: {
            depositLabel: "Deposit",
            confirmSendingMessage: "Are you sure you want to send money to the user %username%?",
            moneySuccessfulSentMessage: "You have successfully sent %username% %money%.",
            moneySuccessfulSentMessageShort: "You have successfully sent money.",
            sendBtn: "Send",
            senderLabel: "Sender",
            recipientLabel: "Recipient",
        },
        withdrawMoneyFromUser: {
            withdrawLabel: "Withdraw from user",
            confirmWithdrawMessage: "Are you sure you want to withdraw money from user %username%?",
            moneySuccessfulWithdrawedMessage: "You have successfully withdraw %money% from %username%.",
            moneySuccessfulWithdrawedMessageShort: "You have successfully withdrawn money.",
            withdrawBtn: "Withdraw",
            withdrawFromLabel: "Withdraw from",
            toReceiverLabel: "To receiver",
            withdrawAllBtn: "Withdraw all",
        },
        moneyTransfersTable: {
            usernameHeader: "Username",
            amountTableHeader: "Amount",
            methodTableHeader: "Method",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Deposit/Withdraw",
            dateTimeTableHeader: "Date/Time",
            moneyTransfersNotFoundMessage: "No money transfers were found"
        },
        gamesHistory: {
            userNameTableHeader: 'Username',
            providerTableHeader: 'Provider',
            gameTableHeader: 'Game',
            betAmountTableHeader: 'Bet amount',
            winAmountTableHeader: 'Win amount',
            dateTimeTableHeader: 'Date/Time',
            gamesHistoryNotFoundMessage: 'No game history records were found'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "There are no more payment methods available",
            paymentMethodsDropdownLabel: "Payment methods",
            yourPaymentAccountsTitle: "Your payment accounts",
            addPaymentMethodBTN: "Add payment method",
            paymentAccountSuccessfullyUpdated: "You have successfully updated your payment account.",
            depositRateLabel: 'Deposit rate',
            depositRateSavedMessage: 'Deposit rate successfully saved.'
        },
        approveTransaction: {
            userRequestPayoutTitle: "User %username% requested a withdrawal %money% to %payment_system%",
            userRequestPaymentTitle: "User %username% transfer payment %money% to %payment_system% for top up his/her balance.",
            confirmPayoutMessage: "Do you approve the transaction?",
            declineBTN: "Decline",
            approveBTN: "Approve"
        },
        requestPayment: {
            choosePaymentAccountTitle: "Choose payment account\n to buy balance",
            paymentAmountLabel: "Payment amount",
            youllReceiveOnBalanceLabel: "You'll receive on balance",
            depositRateHelper: "Deposit rate %rate%",
            backBtn: "Back",
            nextBtn: "Next",
            iTransferMoneyBtn: "I transfer money",
            transferMoneyToThisAccountMessage: "1. Transfer money to this account",
            enterYourProofMessage: "2. Enter your proof for checking your payment"
        },
        securityLog: {
            logID: "ID",
            userName: "User name",
            userIP: "User IP",
            logAction: "Log action",
            requestJSON: "Request JSON",
            responseJSON: "Response JSON",
            timestamp: "Date/Time",
            securityLogNotFoundMessage: "No security log records were found"
        },
        royaltyStatistics:  {
            royaltyStatisticsTitle: 'Royalty statistics',
            providerTableHeader: "Provider",
            sectionTableHeader: "Section",
            currencyTableHeader: "Currency",
            userLossesTableHeader: "User Losses",
            userWinsTableHeader: "User Wins",
            ggrTableHeader: "GGR",
            royaltyStatisticsNotFoundMessage: "No royalty statistics records were found"
        },
        manageUsers: {
            usersTreeSwitcher: "Users tree",
            createUserBtn: "Create user",
            activeTableHeader: "Active",
            usernameHeader: "Username",
            firstNameTableHeader: "Firstname",
            lastnameTableHeader: "Lastname",
            balanceTableHeader: "Balance",
            currencyTableHeader: "Currency",
            roleTableHeader: "Role",
            emailTableHeader: "Email",
            phoneTableHeader: "Phone",
            operationsTableHeader: "Operations",
            dateTableHeader: "Date",
            commissionTableHeader: "Commission",
            actionsTableHeader: "Actions",
            usersNotFoundMessage: "No users were found"
        },
        timeFilter: {
            fromTimeDefaultLabel: "From time",
            toTimeDefaultLabel: "To time",
            fromTimeLabel: "From %time%",
            toTimeLabel: "To %time%",
            todayBTN: 'Today',
            yesterdayBTN: 'Yesterday',
            weekBTN: 'Week',
            monthBTN: 'Month',

            date: 'Date',
            quickDates: {
                today: 'Today',
                yesterday: 'Yesterday',
                lastWeek: 'Last week',
                thisWeek: 'This week',
                lastMonth: 'Last month',
                thisMonth: 'This month',
            }
        },
        usernameFilter: {
            usernameFilterLabel: "Username"
        },
        userRoles: {
            platform: "platform",
            owner:  "owner",
            superadmin:  "superadmin",
            admin:  "admin",
            cashier:  "cashier",
            player:  "player",
            agent: "agent"
        },
        currencySwitcher: {
            currencyLabel: 'Currency'
        },
        bonusConstructor: {
            createBonusBTN: 'Create bonus',
            bonusConstructorTitle: 'Bonus constructor',
            promoCodeLabel: 'Promocode name',
            promoCodeHelper: 'name of the promotional code that will be entered when requesting a bonus',
            conditionDepositLabel: 'Condition deposit',
            conditionDepositHelper: 'minimum deposit required to receive the bonus',
            wagerLabel: 'Wager',
            wagerHelper: 'wagering factor',
            daysValidLabel: 'Days valid',
            daysValidHelper: 'how long the bonus is valid after request in days',
            bonusAmountLabel: 'Bonus amount',
            bonusAmountHelper: 'cash bonus prize',
            startPromoDateLabel: 'Start promo date',
            endPromoDateLabel: 'End promo date',
            additionalConditionLabel: "Additional condition",
            additionalConditionHelper: 'additional condition for receiving a bonus',
            bonusPercentageLabel: 'Bonus percentage, %',
            bonusPercentageHelper: 'bonus prize as a percentage of the deposit',
            freeSpinsLabel: 'Free spins',
            freeSpinsHelper: 'bonus free spins',
            bonusMaxLabel: 'Bonus max',
            bonusMaxHelper: 'upper limit of the bonus',
            saveBonusBTN: "Save bonus",
            bonusCreateSuccessMessage: 'Bonus successfully saved!'
        },
        bonusList: {
            promoLabel: 'Promo code',
            fromTimeLabel: 'From time',
            toTimeLabel: 'To time',
            conditionDepositLabel: 'Condition deposit',
            additionConditionLabel: 'Additional condition',
            wagerLabel: 'Wager',
            bonusAmountLabel: 'Bonus amount',
            bonusPercentageLabel: 'Bonus percentage',
            bonusFreeSpinsLabel: 'Bonus free spins',
            bonusMaxLabel: 'Bonus max',
            daysValidLabel: 'Days valid',
            deleteBonusConfirmationMessage: 'Are you sure you want to delete this bonus?'
        },
        coinsPackCreator: {
            coinsPackCreatorTitle: 'Coins pack creator',
            savePackBTN: 'Save pack',
            coinsLabel: 'Coins',
            coinsHelper: 'coins in the pack',
            freeCoinsLabel: 'Free coins',
            freeCoinsHelper: 'free coins in the pack',
            priceLabel: 'Price',
            priceHelper: 'price of the pack',
            coinsPackCreateSuccessMessage: 'Coins pack successfully saved!'
        },
        coinsList: {
            idHeader: 'ID',
            coinsHeader: 'Coins',
            priceHeader: 'Price',
            freeCoinsHeader: 'Free coins',
            deleteCoinsPackConfirmationMessage: 'Are you sure you want to delete this coins pack?',
            createCoinPackBTN: 'Create coins pack',
        },
        playersAgents: {
            allUsersTab: "All users",
            playersTab: "Players",
            agentsTab: "Agents",
            depositBtn: "Deposit",
            withdrawBtn: "Withdraw",
            gameHistoryBTN: "Game history",
            editUserBTN: "Edit user",
            editDepositRate: 'Edit deposit rate',
            noUsersFoundMessage: "No users were found",
            userCardBalanceLabel: "Balance",
            totalDeposits: "Total deposits",
            totalWithdrawals: "Total withdrawals",
            netDeposit: "Net deposit",
            directSwitchLabel: "Direct only",
        },
        netwinUsersTable: {
            nameHeader: 'Name',
            betsHeader: 'Bets',
            winsHeader: 'Wins',
            netwinHeader: 'Netwin',
            commissionHeader: 'Commission',
            percentageHeader: 'Percentage',
            noUsersFoundMessage: 'No users were found'
        },
        netwinRoyaltyStatisticsTable: {
            providerHeader: 'Provider',
            betsHeader: 'Bets',
            winsHeader: 'Wins',
            netwinHeader: 'Netwin',
            royaltyStatisticsNotFoundMessage: 'No royalty statistics were found'
        },
        agentTransfersTable: {
            typeHeader: "Type",
            amountHeader: "Amount",
            timeHeader: "Time",
            initiatorUserID: "Initiator ID",
            userIdHeader: "User ID",
            balanceHeader: "Balance",
            userBalanceBeforeHeader: "User balance before",
            userBalanceAfterHeader: "User balance after",
            originUserIdHeader: "Origin user ID",
            originUserBalanceBeforeHeader: "Origin user balance before",
            originUserBalanceAfterHeader: "Origin user balance after",
            initiatorNameHeader: "Initiator",
            parentNameHeader: "Parent",
            userNameHeader: "User",
            transfersNotFoundMessage: "No transfers were found",
            allTypeTab: "All types",
            depositsTab: "Deposits",
            withdrawalsTab: "Withdrawals",
        },
        balanceTooltip: {
            before: 'Before',
            after: 'After'
        },
        generalInfo: {
            myBalanceLabel: 'My balance',
            financialDailyWidgetLabel: 'Finances',
            betsLabel: 'Bets',
            userBetsLabel: 'User bets',
            userLossesLabel: 'User losses',
            userWinsLabel: 'User wins',
            totalBetsLabel: 'Bets',
            totalGGRLabel: 'GGR',
            totalNewUsersLabel: 'New users',
            totalActiveUsersLabel: 'Active users',
            usersDailyWidgetLabel: 'Activities',
            activeUsersLabel: 'Active users',
            newPlayersLabel: 'New players',
            usersWidgetLabel: 'Users',
            counterWidget: {
                revshare: 'Revshare',
                agents: 'Agents',
                betshops: 'Betshops',
                cashiers: 'Cashiers',
                players: 'Players',
            }
        }
    }
}