import React, {FC, useEffect} from "react";
import {ContentPage} from "../components/layout/content_components/ContentPage";
import {resetUserTransfers} from "../features/user_transfers/UserTransfersSlice";
import {useAppDispatch} from "../store";
import {useUserTransfers} from "../features/user_transfers/useUserTransfers";
import {useUsersTransfersToMyselfWatcher} from "../features/user_transfers/useUsersTransfersToMyselfWatcher";
import {FilterPanel, FilterPanelSide} from "../components/layout/content_components/FilterPanel";
import {TimeFilter} from "../components-v2/commons/time_filter/TimeFilter";
import {useCommonMediaQueries} from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {MainContentZone} from "../components/layout/content_components/MainContentZone";
import {ContentLoader} from "../components/layout/content_components/ContentLoader";
import {Box, Stack} from "@mui/material";
import {GeneralInfoCard} from "../components/commons/GeneralInfoCard";
import {convertCentsToCurrencyString} from "../features/common_funcs";
import {useLanguage} from "../features/localisation/useLanguage";
import {TransfersToMyselfTable} from "../components/user_transfers/TransfersToMyselfTable";
import {useTransfersToMyselfInfo} from "../components/user_transfers/useTransfersToMyselfInfo";
import {TransfersToMyselfList} from "../components/user_transfers/user_transfers_list/TransfersToMyselfList";

export const FinancesPage: FC = () => {
    const { languagePack: {pack: { playersAgents: playersAgentsLang}}} = useLanguage()
    const dispatch = useAppDispatch()
    const { filter, isMoneyTransfersToMyselfLoading } = useUserTransfers()
    useUsersTransfersToMyselfWatcher()
    const { smallScreen: sm } = useCommonMediaQueries()

    useEffect(() => {
        return () => {
            dispatch(resetUserTransfers())
        }
    }, [dispatch]);

    const info = useTransfersToMyselfInfo()

    return <ContentPage>
        <FilterPanel>
            <FilterPanelSide>
                <TimeFilter
                    fromTime={filter.fromTime}
                    toTime={filter.toTime}
                    pullParamsToAddressBar/>
            </FilterPanelSide>
        </FilterPanel>
        <MainContentZone>
            <ContentLoader isLoading={isMoneyTransfersToMyselfLoading}/>
            {!sm && <Stack direction={'row'} padding={'8px'} gap={'8px'}>
                <Box flex={1}>
                    <GeneralInfoCard title={playersAgentsLang.totalDeposits}
                                     value={info.totalDeposits !== undefined ? convertCentsToCurrencyString(info.totalDeposits, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={playersAgentsLang.totalWithdrawals}
                                     value={info.totalWithdraws !== undefined ? convertCentsToCurrencyString(info.totalWithdraws, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={playersAgentsLang.netDeposit}
                                     value={info.netDeposit !== undefined ? convertCentsToCurrencyString(info.netDeposit, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
            </Stack>}
            {sm ? <TransfersToMyselfList info={info}/> : <TransfersToMyselfTable/>}
        </MainContentZone>
    </ContentPage>
}